import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import { Button } from '@nordictrustee/nt-ui-library/build/components/Button';
import { useAuth } from 'context/AuthProvider';
import ConfirmDialog from 'components/ConfirmDialog';
import StatusChip from 'modules/BondholdersMeeting/screens/Meeting/StatusChip';
import { ChipVariant } from 'modules/BondholdersMeeting/screens/Meeting/StatusChip/types';
import { handleException } from 'utils/handleException';
import { useToggle } from 'utils/hooks/useToggle';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { MeetingType } from 'utils/types/MeetingType';
import { RouteParam } from 'utils/types/RouteParam';
import { MeetingDetails, VoteIssue } from '../types';
import * as api from './api';
import {
  checkIfSomeVoteIssuesHaveNotApprovedResult,
  generateCloseButtonTooltipMessage,
  getStatusChipText,
} from './utils';
import classes from './ScreenHeader.module.css';

interface Props {
  votingDeadline?: string;
  status?: MeetingStatus;
  getMeetingDetails: AxiosPromiseGeneric<MeetingDetails>;
  title?: string;
  statusChangedAt?: string;
  statusChangedBy?: string;
  voteIssues: VoteIssue[];
  meetingType?: MeetingType;
}

const ScreenHeader = ({
  votingDeadline,
  status,
  getMeetingDetails,
  title,
  statusChangedAt,
  statusChangedBy,
  voteIssues,
  meetingType,
}: Props) => {
  const { bondholdersMeetingId } = useParams<RouteParam>();
  const { isInternalEmployeeRole } = useAuth();

  const { patchMeetingStatus } =
    api.usePatchMeetingStatus(bondholdersMeetingId);

  const [
    isConfirmDialogOpen,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
  ] = useToggle();

  const closeButtonTooltipMessage = useMemo(
    () =>
      generateCloseButtonTooltipMessage(
        status,
        votingDeadline,
        voteIssues,
        meetingType,
      ),
    [status, votingDeadline, voteIssues, meetingType],
  );

  const handleCloseMeeting = useCallback(async () => {
    try {
      await patchMeetingStatus({ data: { status: MeetingStatus.Closed } });
      await getMeetingDetails();
      toast.success('Meeting has been closed');
      handleCloseConfirmDialog();
    } catch (e) {
      handleException(e);
    }
  }, [getMeetingDetails, handleCloseConfirmDialog, patchMeetingStatus]);

  const statusChipText = getStatusChipText(
    status,
    votingDeadline,
    statusChangedAt,
    statusChangedBy,
    isInternalEmployeeRole,
  );

  const closeMeetingConfirmDialogMessage = useMemo(() => {
    const haveSomeVoteIssuesWithNotApprovedResult =
      checkIfSomeVoteIssuesHaveNotApprovedResult(voteIssues);
    return haveSomeVoteIssuesWithNotApprovedResult
      ? 'You are about to close a meeting that has some votes that are not approved. Do you want to close the meeting anyway?'
      : 'Are you sure you want to close that meeting?';
  }, [voteIssues]);

  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="start"
        alignItems="start"
        sx={{ pb: '24px' }}
        gridColumn="span 12"
      >
        <Typography variant="h2">{title ?? ''}</Typography>
        <Box mx={3}>
          <StatusChip
            label={statusChipText}
            status={status}
            variant={ChipVariant.default}
          />
        </Box>
        {isInternalEmployeeRole && (
          <Button
            data-testid={'button-close'}
            className={classes.button}
            variant="outlined"
            onClick={handleOpenConfirmDialog}
            disabled={
              !!closeButtonTooltipMessage || status === MeetingStatus.Closed
            }
            tooltipText={closeButtonTooltipMessage}
          >
            Close
          </Button>
        )}
      </Box>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          title="Close Confirmation"
          submitText="Close"
          onSubmit={handleCloseMeeting}
          onCancel={handleCloseConfirmDialog}
          message={closeMeetingConfirmDialogMessage}
        />
      )}
    </>
  );
};

export default ScreenHeader;
