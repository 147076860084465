import BigNumber from 'bignumber.js';
import { currencyFormat } from 'utils/constants';
import { numberFormatter } from 'utils/formatters';

export const calculateAmountWithConversion = (
  amount: number,
  bondCurrency: string,
  conversionCurrency: string,
  conversion: number,
) => {
  const bigNumberAmount = new BigNumber(amount).toFormat(currencyFormat);
  const bigNumberConvertedAmount = new BigNumber(amount)
    .multipliedBy(conversion)
    .toFormat(currencyFormat);

  return `${bigNumberAmount} ${bondCurrency} / ${bigNumberConvertedAmount} ${
    conversionCurrency != null && conversionCurrency !== 'Unknown'
      ? conversionCurrency
      : ''
  }`;
};

export const getFormattedAmountWithCurrency = (
  amount: number,
  currency: string,
) =>
  `${numberFormatter(amount)} ${
    currency != null && currency !== 'Unknown' ? currency : ''
  }`;

export const calculateVotingBonds = (
  outstandingAmount: number,
  issuerBondAmount: number,
  bondCurrency: string,
  conversion: number,
  currency: string,
) => {
  const bigNumberOutstandingAmount = new BigNumber(outstandingAmount);
  const bigNumberIssuerBondAmount = new BigNumber(issuerBondAmount);

  const votingBonds = bigNumberOutstandingAmount.minus(
    bigNumberIssuerBondAmount,
  );

  const convertedBigNumberOutstandingAmount = bigNumberOutstandingAmount.multipliedBy(
    conversion,
  );
  const convertedBigNumberIssuerBondAmount = bigNumberIssuerBondAmount.multipliedBy(
    conversion,
  );

  const convertedVotingBonds = convertedBigNumberOutstandingAmount.minus(
    convertedBigNumberIssuerBondAmount,
  );
  return `${votingBonds.toFormat(
    currencyFormat,
  )} ${bondCurrency} / ${convertedVotingBonds.toFormat(currencyFormat)} ${
    currency != null && currency !== 'Unknown' ? currency : ''
  }`;
};
