import { useMemo } from 'react';
import { Chip } from '@mui/material';
import { Environment } from 'utils/types/Environment.types';

interface Props {
  environment: Environment;
}

const environmentChipColors = {
  test: 'rgb(77,163,9)',
  uat: 'rgb(31,114,211)',
};
const EnvironmentChip = ({ environment }: Props) => {
  const chipBackgroundColor = useMemo(() => {
    switch (environment) {
      case Environment.test: {
        return environmentChipColors.test;
      }
      case Environment.uat: {
        return environmentChipColors.uat;
      }
    }
  }, [environment]);

  return (
    <Chip
      style={{
        backgroundColor: chipBackgroundColor,
        textTransform: 'uppercase',
        color: 'white',
        margin: '0 0.5rem',
        fontWeight: 500,
      }}
      label={environment}
    />
  );
};

export default EnvironmentChip;
