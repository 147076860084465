import { Tabs } from '@mui/material';
import styled from 'styled-components';
import defaultTheme from 'theme';

export const TabsMenu = styled(Tabs)<{
  $fitToContent: boolean;
  $maxContent: boolean;
  $areScrollButtonsDisplayed: boolean;
}>`
  .MuiTabs-flexContainer {
    border-bottom: 5px solid ${defaultTheme.theme.palette.divider};
    width: ${({ $fitToContent, $maxContent, $areScrollButtonsDisplayed }) => {
      if ($fitToContent) {
        return 'fit-content';
      } else if ($maxContent || $areScrollButtonsDisplayed) {
        return 'max-content';
      }
      return 'unset';
    }};
  }
`;
