import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AuthMethod, Role, User } from './types';

type Props = {
  children: ReactNode;
  setIsLoadingUser: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  setIsInternalEmployeeRole: Dispatch<SetStateAction<boolean>>;
};
export const MSALContext = ({
  setIsLoadingUser,
  setIsInternalEmployeeRole,
  setUser,
  children,
}: Props) => {
  const { accounts, inProgress: msalLoadingStatus } = useMsal();
  const isLoading = msalLoadingStatus === InteractionStatus.None;
  useEffect(() => {
    if (!isLoading && accounts.length) {
      setIsInternalEmployeeRole(true);
      setUser((user) => ({
        ...user,
        authMethod: AuthMethod.Microsoft,
        authorized: true,
        roles: [Role.NTEmployee],
      }));
    }
    setIsLoadingUser(isLoading);
  }, [
    accounts,
    isLoading,
    setIsInternalEmployeeRole,
    setIsLoadingUser,
    setUser,
  ]);

  return <>{children}</>;
};
