import { Moment } from 'moment';
import { getInitDate, getInitDateTime } from 'utils/formatters';

export const dateValidator = (value?: Moment | null) => {
  // undefined means success in hook-form validation
  if (!value) {
    return undefined;
  }

  const dateWithCorrectFormat = getInitDate(value);

  if (
    dateWithCorrectFormat?.parsingFlags()?.charsLeftOver ||
    !dateWithCorrectFormat?.isValid()
  ) {
    return 'Invalid Date Format';
  } else if (dateWithCorrectFormat?.isBefore('01.01.1900')) {
    return 'Date should not be before 01.01.1900';
  } else if (dateWithCorrectFormat?.isAfter('01.01.2100')) {
    return 'Date should not be after 01.01.2100';
  }
  return undefined;
};

export const dateTimeValidator = (value: Moment | null) => {
  // undefined means success in hook-form validation
  if (!value) {
    return undefined;
  }

  const dateWithCorrectFormat = getInitDateTime(value);

  if (
    dateWithCorrectFormat?.parsingFlags()?.charsLeftOver ||
    !dateWithCorrectFormat?.isValid()
  ) {
    return 'Invalid Date Format';
  } else if (dateWithCorrectFormat?.isBefore('01.01.1900 00:00')) {
    return 'Date should not be before 01.01.1900';
  } else if (dateWithCorrectFormat?.isAfter('01.01.2100 00:00')) {
    return 'Date should not be after 01.01.2100';
  }
  return undefined;
};
