import theme, {
  meetingResultColor,
  meetingStatusColor,
  statisticsVotesColor,
  statisticsVotesReceivedColor,
  uploadedFileResultColor,
} from './theme';

const defaultTheme = {
  statisticsVotesReceivedColor,
  statisticsVotesColor,
  theme,
  meetingStatusColor,
  meetingResultColor,
  uploadedFileResultColor,
};

export default defaultTheme;
