import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import * as api from '../../api';
import { User } from '../../types';
import UserFormInfoStep from './components/UserFormInfoStep';
import UserFormUsernameStep from './components/UserFormUsernameStep';
import * as S from './styles';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleRefreshUsers: () => void;
  groupID: number;
};

const stepsLabels = ['Add Username', 'Add User'];

const UserStepper = ({
  open,
  handleRefreshUsers,
  handleClose,
  groupID,
}: Props) => {
  const methods = useForm({ mode: 'onBlur' });
  const {
    watch,
    formState: { errors, dirtyFields },
    handleSubmit,
    setError,
  } = methods;

  const [activeStep, setActiveStep] = useState(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [compiledForm, setCompiledForm] = useState<User>({
    id: 0,
    username: '',
    firstName: '',
    lastName: '',
    groupId: groupID,
  });

  const form = watch();

  const { postUser } = api.usePostUser();

  const { validateUser } = api.useValidateUser(form.username);

  const getStepContent = useMemo(() => {
    switch (activeStep) {
      case 0:
        return <UserFormUsernameStep formContent={compiledForm} />;
      case 1:
        return <UserFormInfoStep formContent={compiledForm} />;
      default:
        return 'Unknown step';
    }
  }, [activeStep, compiledForm]);

  const isNewUser = useCallback(async () => {
    setIsLoadingNext(true);
    let result = await validateUser();

    if (!result.data || result.data?.userExistInApp) {
      setError('username', {
        type: 'manual',
        message: 'A user with this username already exists',
      });
      setIsLoadingNext(false);
      return false;
    } else {
      let userInfo = result.data?.userInfo;
      setCompiledForm({
        ...compiledForm,
        username: form.username,
        firstName: userInfo ? userInfo.firstName : '',
        lastName: userInfo ? userInfo.lastName : '',
      });
      setIsLoadingNext(false);
      return true;
    }
  }, [compiledForm, form.username, setError, validateUser]);

  const handleNext = handleSubmit(async () => {
    let canContinue = true;
    switch (activeStep) {
      case 0:
        setCompiledForm({
          ...compiledForm,
          username: form.username,
        });
        canContinue = await isNewUser();
        break;
      case 1:
        setCompiledForm({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
        });
        canContinue = false;
        onSave({
          ...compiledForm,
          firstName: form.firstName,
          lastName: form.lastName,
        });
        break;
      default:
        return 'not a valid step';
    }
    if (canContinue) {
      setActiveStep((prevActiveStep) => ++prevActiveStep);
    }
  });

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => --prevActiveStep);
      setCompiledForm(form as User);
    }
  };

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setCompiledForm({
        id: 0,
        username: '',
        firstName: '',
        lastName: '',
        groupId: groupID,
      });
    }
  }, [groupID, open]);

  const onSave = async (data: User) => {
    try {
      const formData: User = {
        id: 0,
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        groupId: groupID,
      };
      await postUser({ data: formData });
      handleRefreshUsers();
      toast.success('User has been saved');
      handleClose();
    } catch (e) {
      handleException(e as AxiosError);
    }
  };

  const continueDisabled = !!Object.values(errors).length;

  const handleConfirmClose = () => {
    confirmClose(!!Object.keys(dirtyFields).length, handleClose);
  };

  return (
    <Dialog
      onClose={handleConfirmClose}
      maxWidth="sm"
      title="Add User"
      dialogActions={
        <>
          <Button
            onClick={handleNext}
            data-testid="continue-button"
            disabled={continueDisabled}
            isLoading={isLoadingNext}
          >
            {activeStep === stepsLabels.length - 1 ? 'save' : 'continue'}
          </Button>
          <Button
            onClick={handleConfirmClose}
            data-testid="cancel-button"
            variant="outlined"
          >
            cancel
          </Button>
          <Button
            onClick={handleBack}
            data-testid="back-button"
            disabled={activeStep === 0}
            variant="outlined"
          >
            back
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <S.Container>
          <Stepper activeStep={activeStep}>
            {stepsLabels.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box mt={2}>{getStepContent}</Box>
        </S.Container>
      </FormProvider>
    </Dialog>
  );
};

export default UserStepper;
