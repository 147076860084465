import { useCallback, useContext, useMemo, useState } from 'react';
import { useAuth } from 'context/AuthProvider';
import Dropzone from 'components/Dropzone';
import { MeetingDetailsContext } from 'modules/BondholdersMeeting/screens/Meeting/MeetingProvider/MeetingDetailsProvider';
import { useQuery } from 'utils/hooks/useQuery';
import { useToggle } from 'utils/hooks/useToggle';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { VoteDetails } from '../types';
import VotesCard from './VoteCard';
import VotesDetailsForm from './VotesDetailsForm';
import VotesDocumentForm from './VotesDocumentForm';

// TODO - commented code for the future development
interface Props {
  status?: MeetingStatus;
  matterToBeDecided: string;
  meetingTitle: string;
}

const VotesDetails = ({ status, matterToBeDecided, meetingTitle }: Props) => {
  const { isInternalEmployeeRole } = useAuth();

  const { isLoadingVotesDetails } = useContext(MeetingDetailsContext);

  const query = useQuery();

  const voteIssueIdFromQuery = useMemo(
    () => query.get('voteIssueId') || '',
    [query],
  );

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedItem, setEditedItem] = useState<VoteDetails>();
  const [votesFile, setVotesFile] = useState<File | undefined>();

  const disabled = useMemo(
    () =>
      status === MeetingStatus.Closed ||
      status === MeetingStatus.Canceled ||
      isLoadingVotesDetails,
    [isLoadingVotesDetails, status],
  );

  const [
    isVotesDocumentFormOpen,
    handleOpenVotesDocumentForm,
    handleCloseVotesDocumentForm,
  ] = useToggle();
  const [isFormOpen, handleOpenForm, handleCloseForm] = useToggle();

  const onEditClick = useCallback(
    (rowData: VoteDetails) => {
      setIsEditMode(true);
      setEditedItem(rowData);
      handleOpenForm();
    },
    [handleOpenForm],
  );

  const dropAction = (droppedFile: File) => {
    handleOpenVotesDocumentForm();
    setVotesFile(droppedFile);
  };

  const handleAddClick = useCallback(() => {
    setIsEditMode(false);
    setEditedItem(undefined);
    handleOpenForm();
  }, [handleOpenForm]);

  return voteIssueIdFromQuery ? (
    <>
      {isInternalEmployeeRole ? (
        <>
          <Dropzone
            disabled={disabled}
            acceptFormats=".xlsx, .xls"
            onDropCallback={dropAction}
            // TODO - verify max size here
            maxSize={25} // Megabytes
          >
            <VotesCard
              handleAddClick={handleAddClick}
              onEditClick={onEditClick}
              disabledButtons={disabled}
              handleOpenVotesDocumentForm={handleOpenVotesDocumentForm}
            />
          </Dropzone>
          {isFormOpen && (
            <VotesDetailsForm
              handleCloseForm={handleCloseForm}
              editMode={isEditMode}
              editedItem={editedItem}
              status={status}
              matterToBeDecided={matterToBeDecided}
            />
          )}
          {isVotesDocumentFormOpen && (
            <VotesDocumentForm
              votesFile={votesFile}
              setVotesFile={setVotesFile}
              onClose={handleCloseVotesDocumentForm}
              meetingTitle={meetingTitle}
            />
          )}
        </>
      ) : (
        <VotesCard
          handleAddClick={handleAddClick}
          onEditClick={onEditClick}
          disabledButtons={disabled}
          handleOpenVotesDocumentForm={handleOpenVotesDocumentForm}
        />
      )}
    </>
  ) : null;
};

export default VotesDetails;
