export const CENTER_COLUMN = {
  cellStyle: {
    textAlign: 'center',
  },
  headerStyle: {
    textAlign: 'center',
  },
};

export const FIRST_COLUMN_NO_PADDING = {
  cellStyle: {
    paddingLeft: 0,
  },
  headerStyle: {
    paddingLeft: 0,
  },
};

export const TOP_BOTTOM_OF_CELL_NO_PADDING = {
  cellStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};
