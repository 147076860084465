import styled from 'styled-components';

export const CardContent = styled.div`
  overflow-y: auto;
  height: 240px;
`;

export const tableCellText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
