import { DialogActions as MDialogActions, DialogContent } from '@mui/material';
import styled from 'styled-components';

export const DialogContentWrapper = styled(DialogContent)`
  && {
    padding: 1rem;
  }
`;

export const DialogActions = styled(MDialogActions)`
  margin: 0 0.5rem 0.5rem;

  && {
    display: flex;
    flex: auto;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  && :not(:first-child) {
    margin-right: 0.5rem;
    margin-left: 0;
  }
`;
