import ReactDOM from 'react-dom';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import App from 'App';
import { AuthMethod } from 'context/AuthProvider/types';
import { msalInstance } from './authConfig';

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    const state = {
      user: {
        authMethod: AuthMethod.Microsoft,
        authorized: true,
      },
    };
    localStorage.setItem('state', JSON.stringify(state));
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(<App pca={msalInstance} />, document.getElementById('root'));
