import { Box as BoxMui } from '@mui/material';
import styled from 'styled-components';

export const Box = styled(BoxMui)`
  && {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0;
  }
`;
