import { ReactNode } from 'react';
import { default as MEditIcon } from '@mui/icons-material/Edit';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import * as S from './styles';

interface Props {
  title?: string;
  canEdit?: boolean;
  children?: ReactNode;
  withoutPadding?: boolean;
  onEditClick?: () => void;
  customHeader?: JSX.Element;
  isLoading?: boolean;
  customAction?: JSX.Element;
  'data-testid': string;
  isHeader?: boolean;
}

const Card = ({
  title,
  canEdit,
  children,
  withoutPadding,
  onEditClick,
  customHeader,
  isLoading,
  customAction,
  'data-testid': dataTestId,
  isHeader = true,
}: Props) => {
  return (
    <S.CardWrapper
      withoutpadding={withoutPadding?.toString()}
      data-testid={dataTestId}
    >
      {isLoading && (
        <S.PreloaderWrapper>
          <CircularProgress />
        </S.PreloaderWrapper>
      )}
      {isHeader && (
        <S.Header>
          {customHeader && customHeader}
          {title && <Typography variant="h3">{title}</Typography>}
          {canEdit && (
            <IconButton
              onClick={onEditClick}
              color="primary"
              data-testid={`${dataTestId}-edit-button`}
            >
              <MEditIcon />
            </IconButton>
          )}
          {customAction && customAction}
        </S.Header>
      )}
      <S.CardContent>{children}</S.CardContent>
    </S.CardWrapper>
  );
};

export default Card;
