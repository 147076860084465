import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { ISContext } from './ISContext';
import { MSALContext } from './MSALContext';
import { AuthMethod, User } from './types';

interface AuthContextType {
  isAuthenticated: boolean;
  user?: User;
  setUser: (user: User | undefined) => void;
  isInternalEmployeeRole?: boolean;
  isAdvisorRole?: boolean;
  isLoadingUser: boolean;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const storageUser: User = JSON.parse(localStorage.getItem('state') || '{}')
    ?.user;
  const isMSAuthenticated = useIsAuthenticated();
  const isISAuthenticated =
    storageUser?.authMethod === AuthMethod.IdentityService &&
    storageUser?.authorized;
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isInternalEmployeeRole, setIsInternalEmployeeRole] = useState(false);
  const [isAdvisorRole, setIsAdvisorRole] = useState(false);
  const [user, setUser] = useState<User | undefined>(storageUser);

  const memoValue = useMemo(
    () => ({
      isAuthenticated: isISAuthenticated || isMSAuthenticated,
      user,
      setUser,
      isInternalEmployeeRole,
      isAdvisorRole,
      isLoadingUser,
    }),
    [
      isAdvisorRole,
      isISAuthenticated,
      isInternalEmployeeRole,
      isLoadingUser,
      isMSAuthenticated,
      user,
    ],
  );

  if (isMSAuthenticated || !isISAuthenticated)
    return (
      <MSALContext
        setIsLoadingUser={setIsLoadingUser}
        setIsInternalEmployeeRole={setIsInternalEmployeeRole}
        setUser={setUser}
      >
        <AuthContext.Provider value={memoValue}>
          {children}
        </AuthContext.Provider>
      </MSALContext>
    );

  return (
    <ISContext
      setIsLoadingUser={setIsLoadingUser}
      setIsAdvisorRole={setIsAdvisorRole}
      setUser={setUser}
    >
      <AuthContext.Provider value={memoValue}>{children}</AuthContext.Provider>
    </ISContext>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
