import { KeyboardEvent, useState } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { Option } from 'react-select/src/filters';
import { MultipleSelect, SelectOption } from 'react-select-material-ui';
import { checkIfErrors } from 'utils/reactHookFormUtils';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';
import { getLabelColor, getSelectStyles } from '../utils';

interface Props {
  control: Control<any>;
  name: string;
  options?: string[] | (SelectOption & Partial<{ hidden?: boolean }>)[];
  label?: string;
  isClearable?: boolean;
  required?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  errors: DeepMap<object, FieldError>;
  disabled?: boolean;
  isLoading?: boolean;
}

const MultiSelect = ({
  control,
  name,
  options,
  isClearable,
  error,
  autoFocus,
  errors,
  disabled,
  isLoading,
  ...props
}: Props) => {
  const isErrorTooltipMessage = error;
  const [active, setActive] = useState(false);
  const hasError = checkIfErrors(name, errors);
  const label = props.label && props.required ? `${props.label}*` : props.label;
  return (
    <div>
      <Controller
        rules={registerRequiredRule(props.required)}
        control={control}
        name={name}
        defaultValue={null}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <MultipleSelect
            InputLabelProps={{
              style: {
                color: getLabelColor(active),
              },
            }}
            disabled={(disabled || isLoading) ?? false}
            style={{ textTransform: 'capitalize' }}
            size="small"
            margin="dense"
            label={label}
            name={name}
            onChange={(value) => {
              onChange(value);
              onBlur();
            }}
            onBlur={(_) => {
              onBlur();
            }}
            options={options ?? []}
            values={value}
            onFocusCapture={() => setActive(true)}
            onBlurCapture={() => setActive(false)}
            SelectProps={{
              //filter only by visible label for user (prevent filtering by value)
              filterOption: (option: Option, rawInput: string): boolean =>
                option.label?.toLowerCase().includes(rawInput.toLowerCase()),
              onKeyDown: (event: KeyboardEvent<HTMLElement>) => {
                //stopPropagation fixes handling ot ESC in select inside modal
                //ESC should close select dropdown menu and not the whole modal
                event.stopPropagation();
              },
              name: name,
              isValidNewOption: (inputValue: string) => inputValue !== '',
              isClearable: isClearable,
              menuPlacement: 'bottom',
              menuPortalTarget: document.body,
              autoFocus: autoFocus,
              styles: getSelectStyles(isErrorTooltipMessage, isClearable),
              isLoading: isLoading,
            }}
          />
        )}
      />
      {/* handles required validation only */}
      <ErrorLabel data-testid="error-label">
        {registerRequiredRule(hasError).required}
      </ErrorLabel>
    </div>
  );
};

export default MultiSelect;
