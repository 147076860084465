import styled from 'styled-components';

export const AddVoteIssuesSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    :nth-of-type(1) {
      flex: 1;
    }
    :nth-of-type(2) {
      flex: 2;
    }
    padding-right: 2rem;
  }
`;
