import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import Button from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialog';
import Dialog from 'components/Dialog';
import DateInput from 'components/Inputs/DateInput';
import DateTimeInput from 'components/Inputs/DateTimeInput';
import MultilineInput from 'components/Inputs/MultilineInput';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import { MeetingDetails } from 'modules/BondholdersMeeting/screens/Meeting/types';
import { getInitDateTime } from 'utils/formatters/dateFormatter';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import useGetCaseHandlers from 'utils/hooks/useGetCaseHandlers';
import useGetCompanyFunction from 'utils/hooks/useGetCompanyFunction';
import useGetMeetingTypeOptions from 'utils/hooks/useGetMeetingTypeOptions';
import { useGetTrusteeTypeOptions } from 'utils/hooks/useGetTrusteeOptions/api';
import { useToggle } from 'utils/hooks/useToggle';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { CompanyFunctionType } from 'utils/types/CompanyFunctionType';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { TrusteeType } from 'utils/types/TrusteeType';
import * as api from './api';
import * as S from './styles';
import { MeetingDetailsFormFields } from './types';

interface Props {
  onClose: () => void;
  meetingDetails: MeetingDetails;
  getMeetingDetails: AxiosPromiseGeneric<MeetingDetails>;
  hasIsins: boolean;
}

const DetailsForm = ({
  onClose,
  meetingDetails,
  getMeetingDetails,
  hasIsins,
}: Props) => {
  const disableField = meetingDetails.status !== 'Active';
  const { putMeetingDetails } = api.usePutMeetingDetails(meetingDetails?.id);

  const {
    companyFunctionOptions: issuerCompanyFunctionOptions = [],
    isLoadingCompanyFunctions: isLoadingIssuerCompanyFunctions,
    getCompanyFunctions: getIssuerCompanyFunctions,
  } = useGetCompanyFunction(CompanyFunctionType.Issuer);

  const {
    meetingTypeOptions,
    isLoadingMeetingTypeOptions,
    getMeetingTypeOptions,
  } = useGetMeetingTypeOptions();

  const {
    trusteeTypeOptions,
    isLoadingTrusteeTypeOptions,
    getTrusteeTypeOptions,
  } = useGetTrusteeTypeOptions();

  const { caseHandlerOptions, isLoadingCaseHandlers, getCaseHandlers } =
    useGetCaseHandlers();

  const [
    isConfirmDialogOpen,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
  ] = useToggle();

  const { patchMeetingStatus } = api.usePatchMeetingStatus(meetingDetails?.id);

  useEffect(() => {
    getIssuerCompanyFunctions();
    getMeetingTypeOptions();
    getCaseHandlers();
    getTrusteeTypeOptions();
  }, [
    getCaseHandlers,
    getIssuerCompanyFunctions,
    getMeetingTypeOptions,
    getTrusteeTypeOptions,
  ]);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<MeetingDetailsFormFields>({ mode: 'onBlur' });

  useEffect(() => {
    reset({
      ...meetingDetails,
      caseHandlerID: meetingDetails?.caseHandler?.id,
      administratorID: meetingDetails?.administrator?.id,
      issuerID: meetingDetails?.meetingIssuer?.id,
      votingDeadline: getInitDateTime(meetingDetails?.votingDeadline),
      recordDate: getInitDateTime(meetingDetails?.recordDate),
    });
  }, [meetingDetails, reset]);

  const issuerOptions = useMemo(
    () =>
      issuerCompanyFunctionOptions.filter(
        (issuer) => issuer.value === meetingDetails?.meetingIssuer?.id
      ).length > 0
        ? issuerCompanyFunctionOptions
        : [
            ...issuerCompanyFunctionOptions,
            {
              label: meetingDetails?.meetingIssuer?.companyFunction.name,
              value: meetingDetails?.meetingIssuer?.id,
            },
          ],
    [
      issuerCompanyFunctionOptions,
      meetingDetails?.meetingIssuer?.companyFunction.name,
      meetingDetails?.meetingIssuer?.id,
    ]
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      await putMeetingDetails({ data });
      toast.success('Meeting details has been saved');
      onClose();
      getMeetingDetails();
    } catch (e) {
      handleException(e);
    }
  });

  const handleCancelMeeting = useCallback(async () => {
    try {
      handleCloseConfirmDialog();
      await patchMeetingStatus({ data: { status: MeetingStatus.Canceled } });
      toast.success('Meeting has been canceled');
      onClose();
      getMeetingDetails();
    } catch (e) {
      handleException(e);
    }
  }, [
    getMeetingDetails,
    handleCloseConfirmDialog,
    onClose,
    patchMeetingStatus,
  ]);

  const isLoadingData =
    isLoadingIssuerCompanyFunctions ||
    isLoadingMeetingTypeOptions ||
    isLoadingCaseHandlers ||
    isLoadingTrusteeTypeOptions;

  const isMeetingInactive = meetingDetails.status !== MeetingStatus.Active;

  const handleConfirmClose = () => {
    confirmClose(isDirty, onClose);
  };

  const trustee = watch().trustee;

  return (
    <Dialog
      title="Edit meeting details"
      data-testid="meeting-details-dialog"
      onClose={handleConfirmClose}
      loading={isLoadingData}
      dialogActions={
        <>
          <Button data-testid="details-form-save-button" onClick={onSubmit}>
            Save
          </Button>
          <Button
            data-testid="details-form-cancel-button"
            variant="outlined"
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
          <S.ActionLeft>
            <Tooltip
              title={isMeetingInactive ? 'Can only cancel active meetings' : ''}
            >
              <span>
                <Button
                  data-testid="details-form-cancel-meeting-button"
                  onClick={handleOpenConfirmDialog}
                  color="secondary"
                  variant="outlined"
                  disabled={isMeetingInactive}
                >
                  Cancel meeting
                </Button>
              </span>
            </Tooltip>
          </S.ActionLeft>
        </>
      }
    >
      <S.DialogContent>
        <TextInput
          name="title"
          label="Title"
          required
          control={control}
          errors={errors}
          maxCharactersLength={200}
          disabled={disableField}
          autoFocus
        />
        <Tooltip
          title={hasIsins ? 'Remove all the ISINs to change issuer' : ''}
        >
          <div>
            <Select
              name="issuerID"
              label="Issuer"
              options={issuerOptions}
              control={control}
              errors={errors}
              required
              disabled={hasIsins || disableField}
            />
          </div>
        </Tooltip>
        <Select
          name="type"
          label="Type"
          options={meetingTypeOptions}
          control={control}
          errors={errors}
          required
          disabled={disableField}
        />
        <DateTimeInput
          label="Voting Deadline"
          name="votingDeadline"
          required
          control={control}
          errors={errors}
          disabled={disableField}
        />
        <Select
          name="caseHandlerID"
          label="Case Handler"
          options={caseHandlerOptions}
          control={control}
          errors={errors}
          required
          disabled={disableField}
        />
        <Select
          name="administratorID"
          label="Administrator"
          options={caseHandlerOptions}
          control={control}
          errors={errors}
          disabled={disableField}
        />
        <Select
          name="trustee"
          label="Trustee"
          options={trusteeTypeOptions}
          control={control}
          errors={errors}
          disabled={disableField}
          required
        />
        {trustee === TrusteeType.NordicTrusteeSE && (
          <DateInput
            label="Record Date"
            name="recordDate"
            control={control}
            errors={errors}
            disabled={disableField}
          />
        )}
        <MultilineInput
          name="remark"
          label="Remark"
          control={control}
          errors={errors}
          maxCharactersLength={1000}
        />
        {isConfirmDialogOpen && (
          <ConfirmDialog
            title="Cancel Meeting Confirmation"
            submitText="Yes"
            cancelText="No"
            onSubmit={handleCancelMeeting}
            onCancel={handleCloseConfirmDialog}
            message="Are you sure you want to cancel this meeting?"
          />
        )}
      </S.DialogContent>
    </Dialog>
  );
};

export default DetailsForm;
