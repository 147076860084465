import { memo } from 'react';
import { Chip } from '@mui/material';
import { addSpaceBeforeCapitalLetters } from 'utils/formatters/textFormatter';
import { VoteStatus } from '../../../types';

interface Props {
  status: VoteStatus;
}

const voteStatusColor = {
  // Uppercase to handle VoteStatus[Value] notation
  Approved: '#4CAF50',
  NotApproved: '#FF0000',
  Deleted: '#979797',
};

const VoteDetailsStatusChip = ({ status }: Props) => (
  <Chip
    variant="outlined"
    label={addSpaceBeforeCapitalLetters(status)}
    style={{
      color: voteStatusColor[status],
      borderColor: voteStatusColor[status],
      marginRight: 'unset',
    }}
  />
);

export default memo(VoteDetailsStatusChip);
