import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table } from '@nordictrustee/nt-ui-library';
import Button from 'components/Button';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import Preloader from 'components/Preloader';
import { FIRST_COLUMN_NO_PADDING } from 'components/Table/const';
import { getChipList } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import useGetCompanyAdvisors from 'utils/hooks/useGetCompanyAdvisors';
import useGetCompanyFunctions from 'utils/hooks/useGetCompanyFunction';
import { CompanyFunctionType } from 'utils/types/CompanyFunctionType';
import { AdvisorAddFormFields } from '../types';
import * as S from './styles';

interface Props {
  tableData: AdvisorAddFormFields[];
  setTableData: Dispatch<SetStateAction<AdvisorAddFormFields[]>>;
}

const AdvisorsStep = ({ tableData, setTableData }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  const {
    companyFunctionOptions: advisorCompanyFunctionOptions,
    isLoadingCompanyFunctions: isLoadingAdvisorCompanyFunctionOptions,
    getCompanyFunctions: getAdvisorCompanyFunctions,
  } = useGetCompanyFunctions(CompanyFunctionType.Advisor);

  const {
    companyAdvisorsOptions,
    getCompanyAdvisors,
    isLoadingCompanyAdvisors,
  } = useGetCompanyAdvisors();

  useEffect(() => {
    getAdvisorCompanyFunctions();
  }, [getAdvisorCompanyFunctions]);

  const [disabledUserSelect, setDisabledUserSelect] = useState<boolean>(true);

  const setSelectedCompanyId = useCallback(
    (companyId: number | null) => {
      setValue('advisorCompanyID', companyId);
      setValue('users', []);

      const disable =
        !companyId ||
        isLoadingAdvisorCompanyFunctionOptions ||
        isLoadingCompanyAdvisors;
      setDisabledUserSelect(disable);
    },
    [isLoadingAdvisorCompanyFunctionOptions, isLoadingCompanyAdvisors, setValue]
  );

  const tableColumns: Table.Column<AdvisorAddFormFields>[] = useMemo(
    () => [
      {
        title: 'Advisor Company',
        field: 'advisorCompanyName',
        type: 'string',
        width: '25%',
        ...FIRST_COLUMN_NO_PADDING,
      },
      {
        title: 'Users',
        field: 'users',
        render: ({ users }) =>
          getChipList(
            users.map(({ label }) => label),
            5,
            15
          ),
      },
    ],
    []
  );

  const tableOptions: Table.Options<AdvisorAddFormFields> = useMemo(
    () => ({
      paging: false,
      minBodyHeight: '20vh',
      maxBodyHeight: '20vh',
      toolbar: false,
    }),
    []
  );

  const onDeleteClick = useCallback(
    ({ id }: AdvisorAddFormFields) => {
      setTableData((prev) => prev.filter((row) => row.id !== id));
    },
    [setTableData]
  );

  const handleSelectCompany = async (companyId: number) => {
    setSelectedCompanyId(companyId);
    if (companyId) {
      await getCompanyAdvisors(companyId);
    }
  };

  const tableActions = useMemo(
    () => [
      (rowData: AdvisorAddFormFields) => ({
        icon: () => <DeleteIcon color="action" />,
        tooltip: 'Delete',
        onClick: () => onDeleteClick(rowData),
      }),
    ],
    [onDeleteClick]
  );

  const onAddClick = useCallback(async () => {
    const valid = await trigger();
    if (valid) {
      const { advisorCompanyID, users } = getValues();

      setTableData((prev) => [
        ...prev,
        {
          id: Math.random(),
          advisorCompanyID,
          advisorCompanyName:
            advisorCompanyFunctionOptions?.find(
              ({ value }) => value === advisorCompanyID
            )?.label ?? '',
          users: companyAdvisorsOptions
            ?.filter(({ value }) => users.includes(value))
            .map(({ value, label }) => {
              return {
                id: value,
                label: label,
              };
            }),
        },
      ]);
      // reset the form
      // using setValue() instead of reset() because of the issue:
      // https://github.com/react-hook-form/react-hook-form/discussions/5224#discussioncomment-1134559
      setSelectedCompanyId(null);
    }
  }, [
    advisorCompanyFunctionOptions,
    companyAdvisorsOptions,
    getValues,
    setSelectedCompanyId,
    setTableData,
    trigger,
  ]);

  const filteredCompanyOptions = useMemo(() => {
    return advisorCompanyFunctionOptions.filter(
      (el) => !tableData.map((el) => el.advisorCompanyID).includes(el.value)
    );
  }, [advisorCompanyFunctionOptions, tableData]);

  return isLoadingAdvisorCompanyFunctionOptions ? (
    <Preloader />
  ) : (
    <div data-testid="advisor-fields">
      <S.AddAdvisorsSectionWrapper>
        <Select
          name="advisorCompanyID"
          label="Advisor Company"
          options={filteredCompanyOptions}
          errors={errors}
          control={control}
          isClearable
          disabled={isLoadingAdvisorCompanyFunctionOptions}
          required
          autoFocus
          onChange={handleSelectCompany}
        />
        <MultiSelect
          name="users"
          label="Advisor Users"
          disabled={disabledUserSelect}
          options={companyAdvisorsOptions}
          control={control}
          errors={errors}
          required
          isClearable
        />
        <Button onClick={onAddClick} variant="outlined">
          Add
        </Button>
      </S.AddAdvisorsSectionWrapper>
      <Table.Root
        columns={tableColumns}
        data={tableData}
        options={tableOptions}
        actions={tableActions}
      />
    </div>
  );
};

export default AdvisorsStep;
