import { useAxios } from 'utils/hooks/useAxios';
import { MeetingIsin } from './types';

export const useGetMeetingIsins = (bondholdersMeetingId: string) => {
  const [
    { data: meetingIsins = [], loading: isLoadingMeetingIsins },
    getMeetingIsins,
  ] = useAxios<MeetingIsin[]>(
    {
      url: `/meetings/${bondholdersMeetingId}/bonds`,
    },
    { manual: true }
  );

  return {
    meetingIsins,
    isLoadingMeetingIsins,
    getMeetingIsins,
  };
};
