import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo } from 'react';
import * as api from './api';
import { Role, User } from './types';

type Props = {
  setIsLoadingUser: Dispatch<SetStateAction<boolean>>;
  setIsAdvisorRole: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  children: ReactNode;
};
export const ISContext = ({
  setIsAdvisorRole,
  setIsLoadingUser,
  setUser,
  children,
}: Props) => {
  const { me, isLoadingMe } = api.useGetMe();
  useEffect(() => {
    if (!isLoadingMe && me) {
      setIsAdvisorRole(me.roles?.filter((r) => r === Role.Advisor)?.length > 0);
      setUser((prev) => ({
        ...prev,
        roles: me.roles,
        termsAndConditionsAcceptedDate: me.termsAndConditionsAcceptedDate,
      }));
    }
    setIsLoadingUser(isLoadingMe);
  }, [isLoadingMe, me, setIsAdvisorRole, setIsLoadingUser, setUser]);

  return <>{children}</>;
};
