import { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

interface Props {
  tooltipTitle?: string | null;
  children: ReactNode;
}

const HeaderText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default ({ tooltipTitle, children }: Props) => {
  return (
    <Tooltip title={tooltipTitle || ''}>
      <HeaderText>{children}</HeaderText>
    </Tooltip>
  );
};
