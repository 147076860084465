import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import Button from 'components/Button';
import CheckboxInput from 'components/Inputs/CheckboxInput';
import { handleException } from '../../utils/handleException';
import * as api from './api';
import * as S from './styles';
import { Terms } from './Terms';

export const TermsAndConditions = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ isTermsAccepted: boolean }>({ mode: 'onBlur' });

  const isTermsAccepted: boolean = useWatch({
    control,
    name: 'isTermsAccepted',
  });

  const { patchMeetingStatus } = api.usePatchUserAcceptTerms();
  const onSubmit = handleSubmit(async () => {
    try {
      if (isTermsAccepted) {
        await patchMeetingStatus();
        window.location.reload();
      }
    } catch (e) {
      handleException(e);
    }
  });

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <S.Header>
        <Typography variant="h1">Accept Terms and Conditions</Typography>
        <Typography variant="body1">
          In order to use the bondholders’ meetings portal, you need to read and
          accept the general terms and conditions stated below.
        </Typography>
      </S.Header>
      <Terms />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={4}
        mb={8}
      >
        <S.Form onSubmit={onSubmit}>
          <CheckboxInput
            name="isTermsAccepted"
            label="I have read and understood the Terms and Conditions"
            color="primary"
            control={control}
            errors={errors}
            required
          />
          <Button onClick={onSubmit} disabled={!isTermsAccepted}>
            Accept Terms and Conditions
          </Button>
        </S.Form>
      </Box>
    </Box>
  );
};
