import { useMemo } from 'react';
import { Table } from '@nordictrustee/nt-ui-library';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { FIRST_COLUMN_NO_PADDING } from 'components/Table/const';

interface Props {
  reason: string;
  handleClose: () => void;
}

export const ReasonPreviewDialog = ({ reason, handleClose }: Props) => {
  const dialogTitle = `Reason`;

  const reasonData = useMemo(
    () =>
      reason
        .split('\n')
        .filter((r) => r !== '')
        .map((reason, index) => ({
          id: index + 1,
          reason: `${index + 1}. ${reason}`,
        })),
    [reason]
  );

  const columns: Table.Column<{ id: number; reason: string }>[] = [
    {
      field: 'reason',
      ...FIRST_COLUMN_NO_PADDING,
    },
  ];

  return (
    <Dialog
      data-testid="uploaded-files-reason-preview-dialog"
      onClose={handleClose}
      maxWidth="md"
      title={dialogTitle}
      dialogActions={
        <Button
          data-testid="close-button"
          variant="outlined"
          onClick={handleClose}
        >
          Close
        </Button>
      }
    >
      <Table.Root<{ id: number; reason: string }>
        columns={columns}
        data={reasonData}
        options={{
          search: false,
          toolbar: false,
          paging: false,
        }}
        onRowClick={() => {}}
      />
    </Dialog>
  );
};
/*
 * TODO: disableHeader attribute in table
 * */
export default ReasonPreviewDialog;
