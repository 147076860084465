import { FC } from 'react';
import AboutLayout from './AboutLayout';

const About: FC = () => {
  return <AboutLayout />;
};

export default About;
export { AboutLayout };
export type { AboutLayoutProps } from './AboutLayout';
