import { DictionaryOption } from 'utils/types/Dictionary.types';
import { useAxios } from '../useAxios';

export const useGetCurrencyTypeOptions = () => {
  const [
    { data: currencyTypeOptions = [], loading: isLoadingCurrencyTypeOptions },
    getCurrencyTypeOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: '/dictionaries/currency-type-options',
    },
    { manual: true },
  );
  return {
    currencyTypeOptions,
    isLoadingCurrencyTypeOptions,
    getCurrencyTypeOptions,
  };
};
