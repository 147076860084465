import BigNumber from 'bignumber.js';
import { IsinItem } from './IsinsAndAdvisors/Isins/types';

export const getConvertedNumber = (amount: number, conversion: number) =>
  new BigNumber(amount).multipliedBy(conversion).toNumber();

export const getConvertedOutstandingAmountSum = (data: IsinItem[]) => {
  return data?.reduce(
    (acc, { outstandingAmount, conversionRate }) =>
      acc + getConvertedNumber(outstandingAmount, conversionRate),
    0
  );
};

export const getConvertedIssuerBondAmountSum = (data: IsinItem[]) => {
  return data?.reduce(
    (acc, { issuerBondAmount, conversionRate }) =>
      acc + getConvertedNumber(issuerBondAmount, conversionRate),
    0
  );
};

export const getConvertedVotingBondsSum = (data: IsinItem[]) => {
  const bigNumberConvertedOutstandingAmountSum = new BigNumber(
    getConvertedOutstandingAmountSum(data)
  );
  const bigNumberConvertedIssuerBondAmountSum = new BigNumber(
    getConvertedIssuerBondAmountSum(data)
  );

  return bigNumberConvertedOutstandingAmountSum
    .minus(bigNumberConvertedIssuerBondAmountSum)
    .toNumber();
};
