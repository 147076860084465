import { VoteIssueStatisticsData } from 'modules/BondholdersMeeting/screens/Meeting/Statistics/types';
import { VoteDetails } from 'modules/BondholdersMeeting/screens/Meeting/types';
import { getInitLocalDateTime } from 'utils/formatters/dateFormatter';
import { useAxios } from 'utils/hooks/useAxios';
import { DictionaryOption } from 'utils/types/Dictionary.types';
import { VoteIssueBond } from 'utils/types/VoteIssueIsin';

export const useGetVotesDetails = (voteIssueID: string) => {
  const [{ data = [], loading: isLoadingVotesDetails }, getVotesDetails] =
    useAxios<VoteDetails[]>(
      {
        url: `vote-issues/${voteIssueID}/votes?sorting=LastUpdated&sortingDirection=desc`,
      },
      { manual: true }
    );
  if (!voteIssueID) {
    return { votesDetails: [], isLoadingVotesDetails: true };
  }
  const votesDetails = data.map((detail) => {
    return {
      ...detail,
      lastUpdatedAt: getInitLocalDateTime(detail.lastUpdatedAt) as string,
    };
  });
  return {
    votesDetails,
    isLoadingVotesDetails,
    getVotesDetails,
  };
};

export const useGetStatisticsData = (voteIssueID: string) => {
  const [
    { data: statisticsData, loading: isLoadingStatisticsData },
    getStatisticsData,
  ] = useAxios<VoteIssueStatisticsData>(
    {
      url: `/vote-issues/${voteIssueID}/statistics`,
    },
    { manual: true }
  );
  if (!voteIssueID) {
    return { statisticsData: {}, isLoadingStatisticsData: true };
  }
  return {
    statisticsData,
    isLoadingStatisticsData,
    getStatisticsData,
  };
};

export const useGetVoteStatusOptions = () => {
  const [
    { data: voteStatusOptions = [], loading: isLoadingVoteStatusOptions },
    getVoteStatusOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: `/dictionaries/vote-status-options`,
    },
    { manual: true }
  );
  return {
    voteStatusOptions,
    isLoadingVoteStatusOptions,
    getVoteStatusOptions,
  };
};

export const useGetVoteResultOptions = () => {
  const [
    { data: voteResultOptions = [], loading: isLoadingVoteResultOptions },
    getVoteResultOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: `/dictionaries/vote-result-options`,
    },
    { manual: true }
  );
  return {
    voteResultOptions,
    isLoadingVoteResultOptions,
    getVoteResultOptions,
  };
};

export const useGetVoteIssueIsins = (voteIssueID: string) => {
  const [
    { data: voteIssueIsins = [], loading: isLoadingVoteIssueIsins },
    getVoteIssueIsins,
  ] = useAxios<VoteIssueBond[]>(
    {
      url: `/vote-issues/${voteIssueID}/meeting-bonds`,
    },
    { manual: true }
  );
  if (!voteIssueID) {
    return {
      voteIssueIsins: [],
      isLoadingVoteIssueIsins: true,
    };
  }
  return {
    voteIssueIsins,
    isLoadingVoteIssueIsins,
    getVoteIssueIsins,
  };
};
