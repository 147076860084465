import { useCallback, useMemo } from 'react';
import * as api from './api';

const useGetCompanyAdvisors = () => {
  const { companyAdvisors, isLoadingCompanyAdvisors, getAdvisors } =
    api.useGetCompanyAdvisors();

  const companyAdvisorsOptions = useMemo(
    () =>
      companyAdvisors?.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })) ?? [],
    [companyAdvisors],
  );

  const getCompanyAdvisors = useCallback(
    async (companyId: number) => {
      const url = `/companies/${companyId}/advisors`;
      return await getAdvisors({ url });
    },
    [getAdvisors],
  );

  const value = useMemo(
    () => ({
      companyAdvisorsOptions,
      isLoadingCompanyAdvisors,
      getCompanyAdvisors,
    }),
    [companyAdvisorsOptions, getCompanyAdvisors, isLoadingCompanyAdvisors],
  );

  return value;
};

export default useGetCompanyAdvisors;
