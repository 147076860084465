import { memo, MouseEvent, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Link, Tooltip } from '@mui/material';
import { VoteDocument } from 'modules/BondholdersMeeting/screens/Meeting/types';
import * as S from './styles';

interface Props {
  documents: VoteDocument[];
  handleOpenMenu: (event: MouseEvent<HTMLElement>) => void;
  handleDownload: (
    event: MouseEvent<HTMLElement>,
    { id, name }: VoteDocument
  ) => void;
}

const DocumentsDisplayData = ({
  documents,
  handleOpenMenu,
  handleDownload,
}: Props) => {
  const documentsNames = useMemo(
    () => documents.map(({ name }) => name).join(', \n'),
    [documents]
  );

  switch (documents.length) {
    case 0: {
      return <></>;
    }
    case 1: {
      return (
        <Link onClick={(e) => handleDownload(e, documents[0])}>
          <Tooltip title={documents[0].name}>
            <S.SingleDocument>{documents[0].name}</S.SingleDocument>
          </Tooltip>
        </Link>
      );
    }
    default: {
      // 2 or more documents:
      return (
        <Box display="flex" alignItems="center">
          <Tooltip title={documentsNames}>
            <div>{documents.length} documents</div>
          </Tooltip>
          <Box marginLeft="0.25rem">
            <S.MoreButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </S.MoreButton>
          </Box>
        </Box>
      );
    }
  }
};

export default memo(DocumentsDisplayData);
