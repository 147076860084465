import { CircularProgress } from '@mui/material';
import * as S from './styles';

const Preloader = () => (
  <S.Container>
    <CircularProgress />
  </S.Container>
);

export default Preloader;
