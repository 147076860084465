export const ROOT = '/';
export const ABOUT = '/about';
export const LOGIN = '/login';
export const VERIFY_ACCOUNT = '/verify-account';
export const TERMS = '/terms';

export const BONDHOLDERS_MEETINGS = '/bondholders-meetings';
export const BONDHOLDERS_MEETINGS_DATA =
  '/bondholders-meetings/:bondholdersMeetingId';

export const USER_MANAGEMENT = '/user-management';
export const USER_MANAGEMENT_ADVISORS = '/user-management/advisors';

export const USERS = '/users';
export const GROUPS = '/groups';

export const UPLOADED_FILES = '/uploaded-files';

export const FORGOT_PASSWORD = '/forgot-password';

export const SENT_EMAIL_CONFIRMATION = '/sent-email-confirmation';

export const RESET_PASSWORD = '/reset-account';

export const MEETINGS = '/meetings';

export const DOCUMENTS = '/documents';
