import { useCallback } from 'react';
import { useAxios } from 'utils/hooks/useAxios';

export const usePostVotesFileValidation = (voteIssueId: string) => {
  const [{ loading: isLoadingPostValidation }, postVoteValidation] = useAxios(
    {
      url: `/vote-issues/${voteIssueId}/votes-validation`,
      method: 'POST',
    },
    { manual: true },
  );
  const validateVotesFile = useCallback(
    async (file: File) => {
      let formData = new FormData();
      formData.append('file', file as Blob);
      return await postVoteValidation({ data: formData });
    },
    [postVoteValidation],
  );
  return {
    isLoadingPostValidation,
    validateVotesFile,
  };
};

export const usePostVotesAndAttachments = (voteIssueId: string) => {
  const [
    { loading: isLoadingPostVotesAndAttachments },
    postVotesAndAttachments,
  ] = useAxios(
    {
      url: `/vote-issues/${voteIssueId}/votes-upload-job`,
      method: 'POST',
    },
    { manual: true },
  );
  const uploadVotesAndAttachments = useCallback(
    async (votesFile: File, attachments: File[]) => {
      let formData = new FormData();
      formData.append('vote', votesFile as Blob);
      if (attachments.length) {
        attachments.map((attachment) =>
          formData.append('attachments', attachment as Blob),
        );
      }
      return await postVotesAndAttachments({ data: formData });
    },
    [postVotesAndAttachments],
  );
  return {
    isLoadingPostVotesAndAttachments,
    uploadVotesAndAttachments,
  };
};
