import { ReactNode, useEffect, useState } from 'react';
import { Tab } from 'components/Tabs';
import * as S from './styles';

interface Item {
  readonly [key: string]: {
    value: string;
    label: string;
    disabled?: boolean;
    description?: string;
    hidden?: boolean;
  };
}

interface TabMenuProps {
  id?: string;
  items?: Item;
  tabsValue: string;
  handleChange: (event: React.ChangeEvent<{}>, tab: string) => void;
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined;
  children?: ReactNode | ReactNode[];
  fitToContent?: boolean;
  maxContent?: boolean;
}

const TabMenu = ({
  id,
  items = {},
  // items are not necessary if you use 'children' prop
  tabsValue,
  handleChange,
  variant = 'standard',
  children,
  fitToContent = false,
  maxContent = false,
}: TabMenuProps) => {
  const [areScrollButtonsDisplayed, setAreScrollButtonsDisplayed] =
    useState(false);

  const childrenCount = !!id
    ? document.getElementById(id)?.childElementCount
    : 0;

  //if scroll buttons are displayed then childrenCount is greater than 2
  //then we want to use max-content as width value
  useEffect(() => {
    if (childrenCount && childrenCount > 2) {
      setAreScrollButtonsDisplayed(true);
    } else if (areScrollButtonsDisplayed) {
      setAreScrollButtonsDisplayed(false);
    }
  }, [areScrollButtonsDisplayed, childrenCount]);

  return (
    <>
      <S.TabsMenu
        id={id}
        value={tabsValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        scrollButtons="auto"
        variant={variant}
        $fitToContent={fitToContent}
        $maxContent={maxContent}
        $areScrollButtonsDisplayed={areScrollButtonsDisplayed}
      >
        {children
          ? children
          : Object.values(items)
              .filter(({ hidden }) => !hidden)
              .map((item, idx) => (
                <Tab
                  data-testid={`${item.label}`}
                  key={`tab-${idx}-${item.label}`}
                  value={item.value}
                  label={item.label}
                  disabled={!!item.disabled}
                  description={item.description}
                />
              ))}
      </S.TabsMenu>
    </>
  );
};

export default TabMenu;
