import {
  SearchableMultiSelectFilter,
  Table,
} from '@nordictrustee/nt-ui-library';
import HeaderTextWithTooltip from 'components/VirtualizedTable/HeaderTextWithTooltip';
import { VoteDetails } from 'modules/BondholdersMeeting/screens/Meeting/types';
import {
  centerColumnText,
  columnRightText,
  columnWithUnsetTextOverflow,
} from 'utils/constants';
import { numberFormatter } from 'utils/formatters';
import {
  addSpaceBeforeCapitalLetters,
  getAcronym,
} from 'utils/formatters/textFormatter';
import { getLookup } from 'utils/getLookUpFromOptions';
import { getCompareDates } from 'utils/sortCompares';
import { DictionaryOption } from 'utils/types/Dictionary.types';
import { VoteStatus } from '../../../types';
import { sortResult } from '../../utils';
import DocumentsMenu from '../DocumentsMenu';
import VoteDetailsStatusChip from './VoteDetailsStatusChip';
import { TableFilterInput } from './TableFilterInput';
import { VotesIcons } from './VotesIcons';

const isinColumn: Table.Column<VoteDetails> = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="ISIN">ISIN</HeaderTextWithTooltip>
  ),
  field: 'isin',
  width: '124px',
  sorting: true,
  filterComponent: TableFilterInput as any,
};

const bondholderColumn: Table.Column<VoteDetails> = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="Bondholder">
      Bondholder
    </HeaderTextWithTooltip>
  ),
  field: 'bondholder',
  sorting: true,
  filterComponent: TableFilterInput as any,
};

const fundManagerCompanyColumn = (width: string): Table.Column<VoteDetails> => {
  return {
    title: (
      <HeaderTextWithTooltip tooltipTitle="Fund Manager Company">
        Fund Manager Company
      </HeaderTextWithTooltip>
    ),
    field: 'fundManagerCompany',
    ...(width !== '' ? { width: width } : {}),
    sorting: true,
    filterComponent: TableFilterInput as any,
  };
};

const custodianColumn = (width: string): Table.Column<VoteDetails> => {
  return {
    title: (
      <HeaderTextWithTooltip tooltipTitle="Custodian">
        Custodian
      </HeaderTextWithTooltip>
    ),
    field: 'custodian',
    ...(width !== '' ? { width: width } : {}),
    sorting: true,
    filterComponent: TableFilterInput as any,
  };
};

const amountColumn: Table.Column<VoteDetails> = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="Amount">Amount</HeaderTextWithTooltip>
  ),
  field: 'amount',
  width: '124px',
  sorting: true,
  filterComponent: TableFilterInput as any,
  render: ({ amount, currency }: VoteDetails) =>
    `${numberFormatter(amount)} ${currency}`,
  ...columnRightText,
};

const weightedAmountColumn = (width: string): Table.Column<VoteDetails> => {
  return {
    title: (
      <HeaderTextWithTooltip tooltipTitle=" Weighted Amount">
        Weight.Amount
      </HeaderTextWithTooltip>
    ),
    field: 'weightedAmount',
    width: width,
    sorting: true,
    filterComponent: TableFilterInput as any,
    render: ({ weightedAmount, weightedAmountCurrency }: VoteDetails) =>
      `${numberFormatter(weightedAmount)} ${weightedAmountCurrency}`,
    ...columnRightText,
  };
};

const voteResultColumn = (
  voteResultOptions: DictionaryOption[]
): Table.Column<VoteDetails> => {
  return {
    title: (
      <HeaderTextWithTooltip tooltipTitle="Vote">Vote</HeaderTextWithTooltip>
    ),
    field: 'result',
    type: 'string',
    width: '70px',
    sorting: true,
    filterComponent: SearchableMultiSelectFilter,
    lookup: getLookup(voteResultOptions),
    render: ({ result }: VoteDetails) =>
      addSpaceBeforeCapitalLetters(result.trim()),
  };
};

const documentsColumn: Table.Column<VoteDetails> = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="Documents">
      Documents
    </HeaderTextWithTooltip>
  ),
  field: 'documents',
  width: '124px',
  sorting: true,
  filterComponent: TableFilterInput as any,
  customFilterAndSearch: (filter: any, { documents }: VoteDetails) => {
    return !!documents
      .map(({ name }) => name)
      .filter((document) => document.startsWith(filter)).length;
  },
  render: ({ id, documents }: VoteDetails) => (
    <DocumentsMenu voteId={id} documents={documents} />
  ),
};

const canShowThatVotedColumn = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="Publish Bondholder/Vote">
      Pub.
    </HeaderTextWithTooltip>
  ),
  field: 'canShowThatVoted',
  width: '93px',
  sorting: true,
  filterComponent: SearchableMultiSelectFilter,
  lookup: getLookup([
    { value: 'isVisible', label: 'Can show that voted' },
    { value: 'isResultVisible', label: 'Can show what voted' },
  ]),
  customFilterAndSearch: (
    filter: string[],
    { isVisible, isResultVisible }: VoteDetails
  ) => {
    return (
      !filter.length ||
      (filter.length === 1 && filter.includes('isVisible') && isVisible) ||
      (filter.length === 1 &&
        filter.includes('isResultVisible') &&
        isResultVisible) ||
      (isResultVisible && isVisible)
    );
  },
  customSort: (a: VoteDetails, b: VoteDetails) => {
    return sortResult(
      a.isVisible,
      b.isVisible,
      a.isResultVisible,
      b.isResultVisible
    );
  },
  render: ({ isVisible, isResultVisible }: VoteDetails) => (
    <VotesIcons isVisible={isVisible} isResultVisible={isResultVisible} />
  ),
};

const lastUpdatedAtColumn: Table.Column<VoteDetails> = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="Last Updated">
      Last Updated
    </HeaderTextWithTooltip>
  ),
  field: 'lastUpdatedAt',
  type: 'string',
  width: '140px',
  sorting: true,
  filterComponent: TableFilterInput as any,
  customSort: (a: VoteDetails, b: VoteDetails) =>
    getCompareDates(a.lastUpdatedAt, b.lastUpdatedAt),
  ...centerColumnText,
};

const statusColumn = (
  voteStatusOptions: DictionaryOption[]
): Table.Column<VoteDetails> => {
  return {
    title: (
      <HeaderTextWithTooltip tooltipTitle="Status">
        Status
      </HeaderTextWithTooltip>
    ),
    field: 'status',
    width: '98px',
    sorting: true,
    filterComponent: SearchableMultiSelectFilter,
    lookup: getLookup(voteStatusOptions),
    defaultFilter: [VoteStatus.Approved, VoteStatus.NotApproved],
    customFilterAndSearch: (filter: VoteStatus[], { status }: VoteDetails) => {
      return (
        !filter.length ||
        (filter.includes(VoteStatus.Approved) &&
          status === VoteStatus.Approved) ||
        (filter.includes(VoteStatus.NotApproved) &&
          status === VoteStatus.NotApproved) ||
        (filter.includes(VoteStatus.Deleted) && status === VoteStatus.Deleted)
      );
    },
    render: ({ status }: VoteDetails) => (
      <VoteDetailsStatusChip status={status} />
    ),
    ...columnWithUnsetTextOverflow,
  };
};

const approvedByColumn: Table.Column<VoteDetails> = {
  title: (
    <HeaderTextWithTooltip tooltipTitle="Approved By">
      Approved By
    </HeaderTextWithTooltip>
  ),
  field: 'approvedBy',
  type: 'string',
  width: '93px',
  sorting: true,
  filterComponent: TableFilterInput as any,
  customFilterAndSearch: (filter: string, { approvedBy }: VoteDetails) => {
    let fullName = approvedBy != null ? approvedBy.toUpperCase() : '';
    let initials = approvedBy != null ? getAcronym(approvedBy) : '';
    return (
      initials.startsWith(filter.toUpperCase()) ||
      fullName.startsWith(filter.toUpperCase())
    );
  },
  render: ({ approvedBy }: VoteDetails) =>
    approvedBy != null ? getAcronym(approvedBy) : '',
};

export const getInternalColumns = (
  voteResultOptions: DictionaryOption[],
  voteStatusOptions: DictionaryOption[]
): Table.Column<VoteDetails>[] => {
  return [
    isinColumn,
    bondholderColumn,
    fundManagerCompanyColumn('160px'),
    custodianColumn('124px'),
    amountColumn,
    weightedAmountColumn('124px'),
    voteResultColumn(
      voteResultOptions.filter((v) => v.value !== 'NotDisclosed')
    ),
    documentsColumn,
    canShowThatVotedColumn,
    lastUpdatedAtColumn,
    statusColumn(voteStatusOptions),
    approvedByColumn,
  ];
};

export const getExternalColumns = (
  voteResultOptions: DictionaryOption[],
  voteStatusOptions: DictionaryOption[]
): Table.Column<VoteDetails>[] => {
  return [
    isinColumn,
    bondholderColumn,
    fundManagerCompanyColumn(''),
    custodianColumn(''),
    amountColumn,
    weightedAmountColumn('155px'),
    voteResultColumn(voteResultOptions),
    lastUpdatedAtColumn,
    statusColumn(voteStatusOptions),
  ];
};
