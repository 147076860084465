import { useCallback } from 'react';
import { DOCUMENTS, MEETINGS } from 'router/url';
import { useAxios } from 'utils/hooks/useAxios';
import MeetingDocument, { DocumentType } from './types';

export const useGetMeetingDocuments = (bondholdersMeetingId: string) => {
  const [
    { data: documents = [], loading: isLoadingMeetingDocuments },
    getMeetingDocuments,
  ] = useAxios<MeetingDocument[]>(
    {
      method: 'GET',
      url: `${MEETINGS}/${bondholdersMeetingId}${DOCUMENTS}`,
    },
    {
      manual: true,
    },
  );

  return { documents, isLoadingMeetingDocuments, getMeetingDocuments };
};

export const useGetMeetingDocument = (meetingId: string) => {
  const [, getMeetingDocument] = useAxios<Blob>(
    {
      method: 'GET',
      responseType: 'blob',
    },
    {
      manual: true,
    },
  );

  const getDocument = useCallback(
    async (id: number, name: string) => {
      const url = `${MEETINGS}/${meetingId}${DOCUMENTS}/${id}`;

      const result = await getMeetingDocument({ url });
      return new File([result.data as BlobPart], name);
    },
    [getMeetingDocument, meetingId],
  );
  return { getDocument };
};

export const usePutMeetingDocuments = (bondholdersMeetingId: string) => {
  const [{ loading: isLoadingPutMeetingDocuments }, putMeetingDocuments] =
    useAxios(
      {
        method: 'PUT',
      },
      {
        manual: true,
      },
    );

  const uploadVoteDocuments = useCallback(
    async (data: File[]) => {
      let formData = new FormData();
      data.map((file) => formData.append('files', file as Blob));
      const url = `${MEETINGS}/${bondholdersMeetingId}${DOCUMENTS}`;
      return await putMeetingDocuments({ url, data: formData });
    },
    [bondholdersMeetingId, putMeetingDocuments],
  );
  return { isLoadingPutMeetingDocuments, uploadVoteDocuments };
};

export const useDeleteMeetingDocument = (bondholdersMeetingId: string) => {
  const [{ loading: isLoadingDeleteDocument }, deleteDocument] = useAxios(
    {
      method: 'DELETE',
    },
    {
      manual: true,
    },
  );

  const deleteMeetingDocument = useCallback(
    async (document: MeetingDocument) => {
      const url = `${MEETINGS}/${bondholdersMeetingId}${DOCUMENTS}/${document.id}`;

      return await deleteDocument({ url });
    },
    [bondholdersMeetingId, deleteDocument],
  );

  return { deleteMeetingDocument, isLoadingDeleteDocument };
};

export const useGetGeneratedMeetingDocument = (meetingId: string) => {
  const [, getGeneratedMeetingDocument] = useAxios<Blob>(
    {
      method: 'GET',
      responseType: 'blob',
    },
    {
      manual: true,
    },
  );

  const getGeneratedDocument = useCallback(
    async (documentType: DocumentType) => {
      let documentUrlPart = '';
      switch (documentType) {
        case DocumentType.MinutesBondholdersMeeting: {
          documentUrlPart = "minutes-bondholder-meeting-docx";
          break;
        }
        case DocumentType.NoticeBondholdersMeeting: {
          documentUrlPart = "notice-bondholder-meeting-docx";
          break;
        }
        case DocumentType.NoticeWrittenResolution: {
          documentUrlPart = "notice-written-resolution-docx";
          break;
        }
        case DocumentType.Proxy: {
          documentUrlPart = "proxy-docx";
          break;
        }
        case DocumentType.VotingFormWrittenResolution: {
          documentUrlPart = "voting-form-written-resolution-docx";
          break;
        }        
        case DocumentType.CertificateOfHoldings: {
          documentUrlPart = "certificate-of-holdings-docx";
          break;
        }
        case DocumentType.NoticeOfResultsAmendmentsAndWaivers: {
          documentUrlPart = "notice-of-results-amendments-and-waivers-docx";
          break;
        }
        case DocumentType.NoticeOfResultsQuorumRequirementNotFulfilled: {
          documentUrlPart = "notice-of-results-quorum-requirement-not-fulfilled-docx";
          break;
        }
        case DocumentType.MinutesFromWrittenProcedure: {
          documentUrlPart = "minutes-from-written-procedure-docx";
          break;
        }
        default: {
          throw Error(
            'This document type is not supported',
          );
        }
      }

      const url = `${MEETINGS}/${meetingId}/${documentUrlPart}`;

      return await getGeneratedMeetingDocument({ url });
    },
    [getGeneratedMeetingDocument, meetingId],
  );
  return { getGeneratedDocument };
};
