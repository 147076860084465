import { KeyboardEvent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { DataLink } from '@nordictrustee/nt-ui-library';
import { useAuth } from 'context/AuthProvider';
import { AuthMethod } from 'context/AuthProvider/types';
import * as URL from 'router/url';
import { FormPageCard } from 'components/FormPage';
import PasswordInput from 'components/Inputs/PasswordInput';
import UsernameInput from 'components/Inputs/UsernameInput';
import { authenticate } from 'modules/Auth/api';
import { handleException } from 'utils/handleException';
import { useQuery } from 'utils/hooks/useQuery';
import * as S from './styles';

const Login = () => {
  const { setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm({ mode: 'onBlur' });

  const query = useQuery();
  const redirectTo = useMemo(() => query.get('redirectTo') || '', [query]);

  const { push } = useHistory();

  const handleISLogin = handleSubmit(async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsLoading(true);
      const { username, password } = getValues();
      try {
        const response = await authenticate(username, password);
        setIsLoading(false);
        if (response && response.status === 200) {
          const user = {
            authMethod: AuthMethod.IdentityService,
            accessToken: response.accessToken,
            authorized: true,
          };
          setUser(user);
          push(redirectTo);
        }
      } catch (e) {
        setIsLoading(false);
        handleException(e);
      }
    }
  });

  const handleEnterClick = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      const { username, password } = getValues();
      if (username && password) {
        handleISLogin();
      }
    }
  };

  return (
    <FormPageCard
      cardTitle="Login"
      buttonText="Log in"
      onClick={handleISLogin}
      data-testid="login-page"
      isLoadingButton={isLoading}
    >
      <UsernameInput
        control={control}
        errors={errors}
        isRequired
        onKeyUp={handleEnterClick}
      />
      <PasswordInput
        control={control}
        errors={errors}
        required
        onKeyUp={handleEnterClick}
      />
      <S.ForgotPassword>
        <DataLink text="Forgot password?" to={`${URL.FORGOT_PASSWORD}`} />
      </S.ForgotPassword>
    </FormPageCard>
  );
};

export default Login;
