import { DictionaryOption } from 'utils/types/Dictionary.types';
import { useAxios } from '../useAxios';

export const useGetMeetingTypeOptions = () => {
  const [
    { data: meetingTypeOptions = [], loading: isLoadingMeetingTypeOptions },
    getMeetingTypeOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: `/dictionaries/meeting-type-options`,
    },
    { manual: true },
  );
  return {
    meetingTypeOptions,
    isLoadingMeetingTypeOptions,
    getMeetingTypeOptions,
  };
};
