import { useCallback, useState } from 'react';

export interface PaginatedTable {
  page: number;
  rowsPerPage: number;
  totalCount?: number;
  resetPage?: () => void;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (value: number) => void;
}

export const usePaginatedTable = (
  initialRowsPerPage: number,
  initialPage?: number,
): PaginatedTable => {
  const [page, setPage] = useState(initialPage || 0);

  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = useCallback((newPage: number): void => {
    setPage(newPage);
  }, []);

  const resetPage = useCallback((): void => {
    setPage(0);
  }, []);

  const handleChangeRowsPerPage = useCallback((value: number): void => {
    setRowsPerPage(value);
    setPage(0);
  }, []);

  return {
    page,
    rowsPerPage,
    resetPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
