import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormTrigger,
} from 'react-hook-form';
import { Box } from '@mui/material';
import Dropzone from 'components/Dropzone';
import FileInput from 'components/Inputs/FileInput';
import { helperText } from 'utils/reactHookFormUtils';
import FilesList from '../FilesList';

interface Props {
  control: Control;
  errors: DeepMap<FieldValues, FieldError>;
  setVoteDocuments: Dispatch<SetStateAction<File[]>>;
  voteDocuments: File[];
  trigger: UseFormTrigger<any>; // TODO - type
}

const AddDocumentsStep = ({
  control,
  errors,
  voteDocuments,
  setVoteDocuments,
  trigger,
}: Props) => {
  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    var files = (event?.currentTarget as HTMLInputElement)?.files;
    if (files) {
      const filesAsArray = Array.from(files);
      setVoteDocuments((prevState) =>
        prevState ? [...prevState, ...filesAsArray] : filesAsArray
      );
      trigger();
    }
  };

  const dropAction = async (droppedFile: File[]) => {
    setVoteDocuments((prevState) =>
      prevState ? [...prevState, ...droppedFile] : droppedFile
    );
    trigger();
  };

  const deleteVotesDocument = async (file: File) => {
    setVoteDocuments((prevState) => prevState.filter((el) => el !== file));
  };

  return (
    <Dropzone multiple onDropCallback={dropAction} acceptFormats="">
      <Box height="200px">
        <FileInput
          control={control}
          acceptFormat=""
          name="voteDocuments"
          onChangeCallback={handleFileInputChange}
          data-testid="votes-attachments"
          text="Choose file"
          multiple
          error={helperText('votes', errors)}
          label="Documents"
        />
        <FilesList files={voteDocuments} onDelete={deleteVotesDocument} />
      </Box>
    </Dropzone>
  );
};

export default AddDocumentsStep;
