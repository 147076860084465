import { FC, ReactNode } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import Footer from './Footer';
import Header from './Header';

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <ErrorBoundary>{children}</ErrorBoundary>
      <Footer />
    </>
  );
};

export default Layout;
