import { useMemo } from 'react';
import * as api from './api';

const useGetMeetingResultOptions = () => {
  const {
    meetingResultOptions,
    isLoadingMeetingResultOptions,
    getMeetingResultOptions,
  } = api.useGetMeetingResultOptions();

  const value = useMemo(
    () => ({
      meetingResultOptions,
      isLoadingMeetingResultOptions,
      getMeetingResultOptions,
    }),
    [
      getMeetingResultOptions,
      isLoadingMeetingResultOptions,
      meetingResultOptions,
    ],
  );

  return value;
};

export default useGetMeetingResultOptions;
