import { useContext, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { VirtualizedTable } from '@nordictrustee/nt-ui-library';
import { useAuth } from 'context/AuthProvider';
import { MeetingDetailsContext } from 'modules/BondholdersMeeting/screens/Meeting/MeetingProvider/MeetingDetailsProvider';
import { VoteDetails } from '../../types';
import {
  getExternalColumns,
  getInternalColumns,
} from './components/VoteDetailsColumns';

interface Props {
  onEditClick: (rowData: VoteDetails) => void;
}

const VotesDetailsTable = ({ onEditClick }: Props) => {
  const { isInternalEmployeeRole } = useAuth();

  const {
    voteStatusOptions,
    isLoadingVoteStatusOptions,
    isLoadingVoteResultOptions,
    voteResultOptions,
    votesDetails,
    isLoadingVotesDetails,
  } = useContext(MeetingDetailsContext);
  const isLoading = useMemo(
    () =>
      isLoadingVoteStatusOptions ||
      isLoadingVoteResultOptions ||
      isLoadingVotesDetails,
    [
      isLoadingVotesDetails,
      isLoadingVoteResultOptions,
      isLoadingVoteStatusOptions,
    ],
  );

  const columns = useMemo(
    () =>
      voteResultOptions && voteStatusOptions
        ? (isInternalEmployeeRole
            ? getInternalColumns(voteResultOptions, voteStatusOptions)
            : getExternalColumns(voteResultOptions, voteStatusOptions)
          ).map((column) => ({
            ...column,
          }))
        : [],
    [isInternalEmployeeRole, voteResultOptions, voteStatusOptions],
  );

  const tableActions = useMemo(
    () => [
      (rowData: VoteDetails) => ({
        icon: () => <EditIcon data-testid="edit-vote-icon" color="action" />,
        tooltip: 'Edit',
        onClick: () => onEditClick(rowData),
      }),
    ],
    [onEditClick],
  );

  return (
    <VirtualizedTable
      data={isLoadingVotesDetails ? [] : votesDetails}
      bodyHeight="85vh"
      rowHeight={49}
      isLoading={isLoading}
      columns={(columns || []) as any[]}
      options={{
        paging: false,
        toolbar: false,
        filtering: true,
        sorting: true,
        tableLayout: 'fixed',
      }}
      actions={isInternalEmployeeRole ? tableActions : undefined}
      showSortingArrows
    />
  );
};

export default VotesDetailsTable;
