import { memo, MouseEvent, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { VoteDocument } from 'modules/BondholdersMeeting/screens/Meeting/types';
import { downloadFile } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import { handleException } from 'utils/handleException';
import { useGetVoteDocuments } from '../../../api';
import DocumentsDisplayData from './components/DocumentsDisplayData';

interface Props {
  voteId: number;
  documents: VoteDocument[];
}

const DocumentsMenu = ({ voteId, documents }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const { getDocument } = useGetVoteDocuments();

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDownload = async (
    event: MouseEvent<HTMLElement>,
    { id, name }: VoteDocument
  ) => {
    event.stopPropagation();
    try {
      const file = await getDocument(voteId, { id, name });
      downloadFile(file);
    } catch (e) {
      handleException(e);
    }
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DocumentsDisplayData
        handleDownload={handleDownload}
        documents={documents}
        handleOpenMenu={handleOpenMenu}
      />
      {isMenuOpen && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={handleCloseMenu}
        >
          {documents?.map(({ id, name }) => (
            <MenuItem key={id} onClick={(e) => handleDownload(e, { id, name })}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default memo(DocumentsMenu);
