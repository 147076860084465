import { useMemo } from 'react';
import * as api from './api';

const useGetCaseHandlers = () => {
  const {
    caseHandlers,
    isLoadingCaseHandlers,
    getCaseHandlers,
  } = api.useGetCaseHandlers();

  const caseHandlerOptions = useMemo(
    () => caseHandlers?.map(({ id, name }) => ({ value: id, label: name })),
    [caseHandlers],
  );

  const value = useMemo(
    () => ({ caseHandlerOptions, isLoadingCaseHandlers, getCaseHandlers }),
    [caseHandlerOptions, getCaseHandlers, isLoadingCaseHandlers],
  );

  return value;
};

export default useGetCaseHandlers;
