import { useAxios } from 'utils/hooks/useAxios';

export const usePutVoteIssues = (bondholdersMeetingId: string) => {
  const [, putVoteIssues] = useAxios(
    {
      url: `/meetings/${bondholdersMeetingId}/vote-issues`,
      method: 'PUT',
    },
    {
      manual: true,
    },
  );
  return { putVoteIssues };
};
