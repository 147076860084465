import type { Props as TextInputControllerProps } from './TextInputController';
import TextInputController from './TextInputController';

type Props = Omit<TextInputControllerProps, 'AdvancedProps'>;

const TextInput = (props: Props) => {
  return <TextInputController {...props} />;
};

export default TextInput;
