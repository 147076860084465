import { MouseEventHandler } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { useAuth } from 'context/AuthProvider';
import Button from 'components/Button';
import Card from 'components/Card';
import { VoteDetails } from '../../types';
import VotesDetailsTable from '../VotesDetailsTable';

interface Props {
  handleAddClick: MouseEventHandler<HTMLButtonElement>;
  onEditClick: (rowData: VoteDetails) => void;
  disabledButtons?: boolean;
  handleOpenVotesDocumentForm: MouseEventHandler<HTMLButtonElement>;
}
const VotesCard = ({
  handleAddClick,
  onEditClick,
  disabledButtons,
  handleOpenVotesDocumentForm,
}: Props) => {
  const { isInternalEmployeeRole } = useAuth();

  return (
    <Card
      withoutPadding
      title="Votes"
      data-testid="votes"
      customAction={
        <Grid spacing={2} container justifyContent="flex-end">
          {isInternalEmployeeRole && (
            <Grid item>
              <Button
                onClick={handleOpenVotesDocumentForm}
                startIcon={<AddIcon />}
                disabled={disabledButtons}
                data-testid="upload-votes-button"
              >
                Upload Votes
              </Button>
            </Grid>
          )}
          {isInternalEmployeeRole && (
            <Grid item>
              <Button
                data-testid="add-vote-button"
                disabled={disabledButtons}
                onClick={handleAddClick}
                startIcon={<AddIcon />}
              >
                Add Vote
              </Button>
            </Grid>
          )}
        </Grid>
      }
    >
      <VotesDetailsTable onEditClick={onEditClick} />
    </Card>
  );
};

export default VotesCard;
