import { Chip } from '@mui/material';
import { uploadedFileResultColor } from 'theme/theme';
import { addSpaceBeforeCapitalLetters } from 'utils/formatters/textFormatter';
import { UploadedFileResult } from 'utils/types/UploadedFileResult';

export const getResultChip = (result: UploadedFileResult) =>
  result ? (
    <Chip
      variant="outlined"
      label={addSpaceBeforeCapitalLetters(result)}
      style={{
        color: uploadedFileResultColor[result],
        borderColor: uploadedFileResultColor[result],
      }}
      size="small"
    />
  ) : null;
