export const sortResult = (
  isVisibleA: boolean,
  isVisibleB: boolean,
  isResultVisibleA: boolean,
  isResultVisibleB: boolean,
) => {
  if (isVisibleA === isVisibleB && isResultVisibleA === isResultVisibleB) {
    return 0;
  }
  return (
    +(
      (isVisibleA && isResultVisibleA) ||
      (isVisibleA && !isVisibleB) ||
      (isResultVisibleA && !isVisibleB)
    ) || -1
  );
};

export const sortStatus = (statusA: boolean, statusB: boolean) => {
  if (statusA === statusB) {
    return 0;
  }
  return +statusA || -1;
};
