import InfoIcon from '@mui/icons-material/Info';
import { Tab as MTab } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme/theme';

export const Tab = styled(MTab)`
  && {
    min-width: 150px;
  }
`;

export const InfoIconStyle = styled(InfoIcon)`
  margin-left: 5px;
  color: white;
  background-color: ${theme.palette.text.secondary};
  border-radius: 50%;
`;
