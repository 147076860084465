import { useAxios } from 'utils/hooks/useAxios';
import { VoteIssueValidation } from './types';

export const usePostVoteIssueValidations = (meetingID: string) => {
  const [
    { data: validationData = [], loading: isLoadingValidationData },
    postVoteIssueValidation,
  ] = useAxios<VoteIssueValidation[]>(
    { url: `/meetings/${meetingID}/vote-issues/validate`, method: 'POST' },
    {
      manual: true,
    },
  );
  return { validationData, isLoadingValidationData, postVoteIssueValidation };
};
