import { ErrorInfo, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import * as S from './styles';

interface BoundaryState {
  error?: Error;
  info?: ErrorInfo;
}

type Props = RouteComponentProps<{}>;

class ErrorBoundary extends PureComponent<Props, BoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {} as BoundaryState;
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.location.key !== this.props.location.key &&
      this.state.error !== undefined
    ) {
      this.setState({ error: undefined, info: undefined });
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, info });
  }

  render = () =>
    this.state.error ? (
      <S.ErrorContainer>
        <S.ErrorHeader variant="h2">
          <Box mb={10}>
            We apologize, an error has occurred on our side while handling this
            activity.
          </Box>
          Please reload the page, and if you continue to see this error, please
          contact us.
        </S.ErrorHeader>
        {/* if we ever decide to show the stackTrace in development mode, we can do this as below, 
      but for now React shows a gray window with a better stackTrace that we'll ever be able to display */}
        {/* {process.env.NODE_ENV === 'development' && (
        <Box m={4}>
          <section>
            <p>Meeting: {error.toString()}</p>
            <pre>
              {info?.componentStack
                ? info.componentStack.split('    ')[1]
                : JSON.stringify(info)}
            </pre>
          </section>
        </Box>
      )} */}
      </S.ErrorContainer>
    ) : (
      this.props.children
    );
}

export default withRouter(ErrorBoundary);
