import { useAxios } from 'utils/hooks/useAxios';
import { CompanyFunction } from 'utils/types/CompanyFunction';
import { CompanyFunctionType } from 'utils/types/CompanyFunctionType';
import { PageableResponse } from 'utils/types/PageableResponse.types';

export const useGetCompanyFunctions = (
  companyFunctionType: CompanyFunctionType,
) => {
  const [
    { data: companyFunctions, loading: isLoadingCompanyFunctions },
    getCompanyFunctions,
  ] = useAxios<PageableResponse<CompanyFunction[]>>(
    {
      url: `/companies?pageSize=999999&page=0&searchPhrase=&companyFunctionType=${companyFunctionType}`,
    },
    { manual: true },
  );
  return {
    companyFunctions,
    isLoadingCompanyFunctions,
    getCompanyFunctions,
  };
};
