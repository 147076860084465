import styled from 'styled-components';

export const IconInput = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  margin-right: 0.5rem;
`;

export const Input = styled.div`
  flex: 1;
`;
