import { createContext, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { RouteParam } from 'utils/types/RouteParam';
import * as api from './api';
import { MeetingIsin } from './types';

export type Props = {
  children: ReactNode;
};

export type ProviderValue = {
  bondholdersMeetingId: string;
  isinOptions: {
    value: string;
    label: string;
  }[];
  isLoadingMeetingIsins: boolean;
  getMeetingIsins: AxiosPromiseGeneric<MeetingIsin[]>;
};

export const Context = createContext<ProviderValue>({
  bondholdersMeetingId: '',
  isinOptions: [],
  isLoadingMeetingIsins: false,
  getMeetingIsins: () => new Promise(() => {}),
});

export const VoteIssuesProvider = ({ children }: Props) => {
  const { bondholdersMeetingId } = useParams<RouteParam>();

  const { meetingIsins, isLoadingMeetingIsins, getMeetingIsins } =
    api.useGetMeetingIsins(bondholdersMeetingId);

  const isinOptions = useMemo(() => {
    return meetingIsins.map(({ isin, bondID }) => ({
      value: bondID,
      label: isin,
    }));
  }, [meetingIsins]);

  const value = useMemo(
    () => ({
      bondholdersMeetingId,
      isinOptions,
      isLoadingMeetingIsins,
      getMeetingIsins,
    }),
    [bondholdersMeetingId, isinOptions, isLoadingMeetingIsins, getMeetingIsins]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
