import styled from 'styled-components';
import theme from 'theme/theme';

export const DropZoneArea = styled.div`
  box-sizing: border-box;
  position: relative;

  &.fileInDropArea {
    opacity: 0.5;
    &:before {
      box-sizing: border-box;
      border: 0.25rem dashed ${theme.palette.common.black};
      content: '';
      position: absolute;
      left: 0;
      z-index: 12;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
`;
