import { useAxios } from 'utils/hooks/useAxios';
import { IsinItem } from './IsinsAndAdvisors/Isins/types';
import { MeetingDetails, VoteIssue } from './types';

export const useGetMeetingDetails = (bondholdersMeetingId: string) => {
  const [
    { data: meetingDetails, loading: isLoadingMeetingDetails },
    getMeetingDetails,
  ] = useAxios<MeetingDetails>(
    {
      url: `/meetings/${bondholdersMeetingId}/details`,
    },
    { manual: true }
  );
  return {
    meetingDetails,
    isLoadingMeetingDetails,
    getMeetingDetails,
  };
};

export const useGetIsinsData = (bondholdersMeetingId: string) => {
  const [{ data: isinsData = [], loading: isLoadingIsinsData }, getIsinsData] =
    useAxios<IsinItem[]>(
      {
        url: `/meetings/${bondholdersMeetingId}/bonds`,
      },
      { manual: false }
    );
  return {
    isinsData,
    isLoadingIsinsData,
    getIsinsData,
  };
};

export const useGetVoteIssues = (bondholdersMeetingId: string) => {
  const [
    { data: voteIssues = [], loading: isLoadingVoteIssues },
    getVoteIssues,
  ] = useAxios<VoteIssue[]>(
    {
      url: `/meetings/${bondholdersMeetingId}/vote-issues`,
    },
    { manual: false }
  );
  return {
    voteIssues,
    isLoadingVoteIssues,
    getVoteIssues,
  };
};
