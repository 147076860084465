import { AxiosResponse } from 'axios';

const getFilenameFromHeaders = (response: AxiosResponse<Blob>) => {
  return response.headers['content-disposition']
    .split('filename=')[1]
    .split(';')[0]
    .replace(/['"]/g, '');
};

export const exportFile = (
  response: AxiosResponse<Blob>,
  fileTitle?: string,
) => {
  const fileName = fileTitle || getFilenameFromHeaders(response);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};
