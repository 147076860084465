type ArrayObject = any & object;
export const getOneMoreThanTheHighestNumberOrIndex = (
  array: ArrayObject[],
  indexId: string = 'id'
) => {
  return array.reduce((prev, obj, currentIndex) => {
    const cur = obj[indexId];
    return Math.max(cur + 1, currentIndex + 1, prev);
  }, 0);
};
