import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import { USER_MANAGEMENT_ADVISORS } from 'router/url';
import Card from 'components/Card';
import { ToolbarComponentType } from 'modules/UserManagement/components/Advisors/ToolbarComponentType';
import { usePaginatedTable } from 'utils/hooks/usePaginatedTable';
import { useQuery } from 'utils/hooks/useQuery';
import * as api from './api';
import * as S from './styles';
import { Advisor } from './types';

const Advisors = () => {
  const { push, replace } = useHistory();
  const query = useQuery();

  const filterStringFromQuery = useMemo(
    () => query.get('query') || '',
    [query]
  );
  const pageFromQuery = useMemo(() => Number(query.get('page')) || 0, [query]);
  const pageSizeFromQuery = useMemo(
    () => Number(query.get('pageSize')) || 25,
    [query]
  );
  const advisorID = useMemo(() => Number(query.get('advisorId')) || 0, [query]);

  const orderByFromQuery = useMemo(() => query.get('Sorting') || '', [query]);
  const orderDirectionFromQuery = useMemo(
    () => query.get('SortingDirection') || '',
    [query]
  );

  const [orderByQuery, setOrderByQuery] = useState(orderByFromQuery);
  const [orderDirectionQuery, setOrderDirection] = useState(
    orderDirectionFromQuery
  );

  const setSorting = useCallback(
    (orderBy: number, orderDirection: 'desc' | 'asc') => {
      if (orderBy === -1) {
        setOrderByQuery('');
        setOrderDirection('');
      } else {
        const columns = ['name', 'country', 'ActiveStatus'];
        setOrderByQuery(columns[orderBy]);
        setOrderDirection(orderDirection);
      }
    },
    []
  );

  const [search, setSearch] = useState(filterStringFromQuery);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePaginatedTable(pageSizeFromQuery, pageFromQuery);

  const params = useMemo(
    () => ({
      page: page.toString(),
      pageSize: rowsPerPage.toString(),
      searchPhrase: search,
      sorting: orderByQuery,
      sortingDirection: orderDirectionQuery,
    }),
    [page, rowsPerPage, search, orderByQuery, orderDirectionQuery]
  );

  const searchQuery = useMemo(() => {
    let searchQuery: string[][] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== '') {
        searchQuery.push([key, value]);
      }
    });
    return new URLSearchParams(searchQuery);
  }, [params]);

  const { advisors, isLoadingAdvisors, getAdvisors } =
    api.useGetAdvisors(searchQuery);

  useEffect(() => {
    replace(
      `${USER_MANAGEMENT_ADVISORS}?advisorId=${advisorID}&${searchQuery}`
    );
    getAdvisors();
  }, [advisorID, getAdvisors, replace, searchQuery]);

  const handleSearch = useCallback(
    (searchText: string) => {
      setSearch(searchText);
      handleChangePage(0);
    },
    [handleChangePage]
  );

  const handleOnRowClick = (_?: MouseEvent, data?: Advisor) => {
    if (data)
      push(`${USER_MANAGEMENT_ADVISORS}?advisorId=${data.id}&${searchQuery}`);
  };

  const columns: Table.Column<Advisor>[] = useMemo(
    () =>
      (
        [
          {
            field: 'name',
            title: 'Advisor',
            render: (item) => item.name,
            cellStyle: {
              width: '300px',
              maxWidth: '300px',
            },
            sorting: true,
            customSort: () => {},
          },
          {
            field: 'country',
            title: 'Country',
            render: (item) => item.countryName,
            sorting: true,
          },
          {
            field: 'ActiveStatus',
            title: 'status',
            render: (item) => (item.isActive ? 'Active' : 'Inactive'),
            sorting: true,
          },
        ] as Table.Column<Advisor>[]
      ).map((column) => ({
        ...column,
        // magic sorting fixture:
        ...(orderByQuery === column.field && {
          defaultSort: orderDirectionQuery,
        }),
      })) as Table.Column<Advisor>[],
    [orderByQuery, orderDirectionQuery]
  );

  const ToolbarComponent: ToolbarComponentType<Advisor> = useMemo(
    () => (props) =>
      (
        <S.Header>
          <Box display="flex" alignItems="center" paddingLeft={0}>
            <Table.Toolbar {...props} />
          </Box>
        </S.Header>
      ),
    []
  );

  const tableComponents: Table.Components = useMemo(
    () => ({
      Toolbar: (props) => <ToolbarComponent {...props} />,
    }),
    [ToolbarComponent]
  );

  return (
    <Card
      data-testid="advisors"
      withoutPadding
      onEditClick={() => {}}
      isHeader={false}
    >
      <Table.Root
        components={tableComponents}
        columns={columns}
        data={advisors?.results ?? []}
        isLoading={isLoadingAdvisors}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        disableDefaultSearchAndFilter
        options={{
          sorting: true,
          searchAutoFocus: true,
          searchText: search,
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          pageSize: rowsPerPage,
          pageSizeOptions: [10, 25, 50, 100],
          debounceInterval: 300,
          maxBodyHeight: '70vh',
          minBodyHeight: '70vh',
          searchFieldStyle: { width: '260px' },
          toolbar: true,
        }}
        onOrderChange={setSorting}
        onSearchChange={handleSearch}
        localization={{
          toolbar: {
            searchPlaceholder: 'Search for advisor',
          },
        }}
        page={page}
        totalCount={advisors?.objectsCount}
        onRowClick={handleOnRowClick}
      />
    </Card>
  );
};
export default Advisors;
