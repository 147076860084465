import { DictionaryOption } from 'utils/types/Dictionary.types';
import { useAxios } from '../useAxios';

export const useGetTrusteeTypeOptions = () => {
  const [
    { data: trusteeTypeOptions = [], loading: isLoadingTrusteeTypeOptions },
    getTrusteeTypeOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: '/dictionaries/trustee-type-options',
    },
    { manual: true },
  );
  return {
    trusteeTypeOptions,
    isLoadingTrusteeTypeOptions,
    getTrusteeTypeOptions,
  };
};
