import { TabProps, Tooltip } from '@mui/material';
import * as S from './styles';

type Props = TabProps & {
  description?: string;
};

const Tab = (props: Props) => {
  const { value, description } = props;

  const infoIcon = !!description ? (
    <Tooltip title={description ?? ''}>
      <S.InfoIconStyle />
    </Tooltip>
  ) : undefined;

  return (
    <>
      <S.Tab
        id={`tab-${value}`}
        aria-controls={`tabpanel-${value}`}
        icon={infoIcon}
        {...props}
      />
    </>
  );
};
export default Tab;
