import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { Table } from '@nordictrustee/nt-ui-library';
import { AxiosError } from 'axios';
import BoxEmpty from 'components/BoxEmpty';
import Button from 'components/Button';
import Card from 'components/Card';
import ConfirmDialog from 'components/ConfirmDialog';
import { handleException } from 'utils/handleException';
import { useQuery } from 'utils/hooks/useQuery';
import { useToggle } from 'utils/hooks/useToggle';
import UserEditForm from './components/UserEditForm';
import UserStepper from './components/UserStepper';
import * as api from './api';
import * as S from './styles';
import { User } from './types';

const Users = () => {
  const [editItem, setEditItem] = useState<User | undefined>();
  const [deleteId, setDeleteId] = useState<number>();
  const [sendUserId, setSendUserId] = useState<number>();
  const [sendUsername, setSendUsername] = useState<string>();
  const query = useQuery();
  const advisorId = useMemo(() => Number(query.get('advisorId')) || 0, [query]);

  const columns: Table.Column<User>[] = [
    {
      field: 'username',
      title: 'Username',
      render: ({ username }) => username,
      cellStyle: {
        width: '300px',
        maxWidth: '300px',
      },
    },
    {
      field: 'firstName',
      title: 'First Name',
      render: ({ firstName }) => firstName,
    },
    {
      field: 'lastName',
      title: 'Last Name',
      render: ({ lastName }) => lastName,
    },
  ];

  const { users, isLoadingUsers, getUsers } = api.useGetUsers(advisorId);
  const { isLoadingSendInvitation, sendInvitation } = api.usePostInvitation();

  const [isFormOpen, handleOpenForm, handleCloseForm] = useToggle();
  const [isUserEditFormOpen, handleOpenUserEditForm, handleCloseUserEditForm] =
    useToggle();

  const [
    isSendUserInvitationDialogOpen,
    handleOpenSendUserInvitationDialog,
    handleCloseSendUserInvitationDialog,
  ] = useToggle();

  const [isDeleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] =
    useToggle();

  const { isLoadingDeleteUser, deleteUser } = api.useDeleteUser(advisorId);

  const handleAddNewOpen = () => {
    handleOpenForm();
  };

  const handleEditClose = useCallback(() => {
    setEditItem(undefined);

    handleCloseUserEditForm();
  }, [handleCloseUserEditForm]);

  const handleEditOpen = useCallback(
    (data: User) => {
      setEditItem(data);
      handleOpenUserEditForm();
    },
    [handleOpenUserEditForm]
  );

  const handleSendUserInvitationConfirmOpen = useCallback(
    (id: number, username: string) => {
      setSendUserId(id);
      setSendUsername(username);
      handleOpenSendUserInvitationDialog();
    },
    [handleOpenSendUserInvitationDialog]
  );

  const handleSendInvitation = async () => {
    try {
      await sendInvitation(sendUserId!);
      toast.success(`Invitation has been sent to ${sendUsername}`);
      handleCloseSendUserInvitationDialog();
    } catch (e) {
      handleException(e as AxiosError);
    }
  };

  useEffect(() => {
    if (advisorId) {
      getUsers();
    }
  }, [advisorId, getUsers]);

  const handleDeleteConfirmOpen = useCallback(
    (id: number) => {
      setDeleteId(id);
      handleOpenDeleteDialog();
    },
    [handleOpenDeleteDialog]
  );

  const handleDelete = async () => {
    try {
      if (deleteId) {
        await deleteUser(deleteId);
      }
      getUsers();
      handleCloseDeleteDialog();
      toast.success('User has been deleted');
    } catch (e) {
      handleException(e);
    }
  };

  const actions = useMemo(
    () => [
      ({ id, username }: User) => ({
        icon: () => <SendIcon color="action" />,
        tooltip: 'Send Invitation',
        onClick: () => handleSendUserInvitationConfirmOpen(id, username),
      }),
      (rowData: User) => ({
        icon: () => <EditIcon color="action" />,
        tooltip: 'Edit',
        onClick: () => handleEditOpen(rowData),
      }),
      ({ id }: User) => ({
        icon: () => <DeleteIcon color="action" />,
        tooltip: 'Delete',
        onClick: () => handleDeleteConfirmOpen(id),
      }),
    ],
    [
      handleDeleteConfirmOpen,
      handleEditOpen,
      handleSendUserInvitationConfirmOpen,
    ]
  );

  return (
    <>
      <Card
        title="Users"
        data-testid="users"
        withoutPadding
        customAction={
          advisorId ? (
            <S.ActionsWrapper>
              <Button startIcon={<AddIcon />} onClick={handleAddNewOpen}>
                add user
              </Button>
            </S.ActionsWrapper>
          ) : undefined
        }
      >
        {advisorId ? (
          <>
            <Table.Root
              columns={columns}
              data={users || []}
              actions={actions}
              isLoading={isLoadingUsers}
              options={{
                paging: false,
                maxBodyHeight: '50rem',
              }}
            />
            {isFormOpen && (
              <UserStepper
                open={isUserEditFormOpen}
                handleClose={handleCloseForm}
                handleRefreshUsers={getUsers}
                groupID={advisorId}
              />
            )}
            {isUserEditFormOpen && (
              <UserEditForm
                handleRefreshUsers={getUsers}
                handleClose={handleEditClose}
                user={editItem}
                advisorId={advisorId}
              />
            )}
            {isSendUserInvitationDialogOpen && (
              <ConfirmDialog
                title="Confirm invitation"
                message={`Do you want to send invitation to ${sendUsername}?`}
                submitText="Send"
                onCancel={handleCloseSendUserInvitationDialog}
                onSubmit={handleSendInvitation}
                isLoading={isLoadingSendInvitation}
              />
            )}
            {isDeleteDialogOpen && (
              <ConfirmDialog
                title="Delete confirmation"
                message="Do you want to delete this user?"
                submitText="Delete"
                onCancel={handleCloseDeleteDialog}
                onSubmit={handleDelete}
                isLoading={isLoadingDeleteUser}
              />
            )}
          </>
        ) : (
          <BoxEmpty height="45.3vh" message="Select Advisor for the details" />
        )}
      </Card>
    </>
  );
};

export default Users;
/*
* TODO: re-add
*
              customRowStyle={{
                height: '3rem',
              }}
* */
