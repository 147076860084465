import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { FormPageCard } from './FormPageCard';
import * as S from './styles';

interface Props {
  pageTitle?: string;
  cardTitle: string;
  children: ReactNode;
  isLoadingPage?: boolean;
  isLoadingButton?: boolean;
  boxSize?: number;
  emptyHeader?: boolean;
  buttonText?: string;
  onClick?: () => void;
}

const FormPage = ({
  pageTitle,
  cardTitle,
  boxSize,
  children,
  emptyHeader,
  isLoadingPage,
  isLoadingButton,
  buttonText,
  onClick,
}: Props) => {
  return (
    <S.Container>
      <>
        {emptyHeader ? (
          <S.EmptyHeader />
        ) : (
          <S.Header>
            <Typography variant="h1" align="center">
              {pageTitle}
            </Typography>
          </S.Header>
        )}
        <FormPageCard
          buttonText={buttonText}
          isLoadingPage={isLoadingPage}
          cardTitle={cardTitle}
          onClick={onClick}
          boxSize={boxSize}
          isLoadingButton={isLoadingButton}
        >
          {children}
        </FormPageCard>
      </>
    </S.Container>
  );
};

export default FormPage;
