import { Step, StepLabel, Stepper } from '@mui/material';
import { documentsUploadSteps } from './const';

interface Props {
  activeStep: number;
}

const VotesDocumentStepper = ({ activeStep }: Props) => (
  <Stepper activeStep={activeStep - 1}>
    {documentsUploadSteps.map((step) => (
      <Step key={step}>
        <StepLabel>{step}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default VotesDocumentStepper;
