import { InputAdornment } from '@mui/material';
import styled from 'styled-components';

export const GroupRadioWrapper = styled.div`
  & .MuiFormControl-root {
    width: 50%;
  }

  & .MuiFormLabel-root {
    padding: 15px 0 5px;
  }

  & .MuiFormGroup-row {
    justify-content: space-between;
  }
`;
export const CurrencyAdornment = styled(InputAdornment)`
  margin: 0 20px 20px;
`;
