import { useAxios } from 'utils/hooks/useAxios';
import { Advisor } from './types';

export const useGetAdvisorsData = (bondholdersMeetingId: string) => {
  const [
    { data: advisorsData = [], loading: isLoadingAdvisorsData },
    getAdvisorsData,
  ] = useAxios<Advisor[]>(
    {
      url: `/meetings/${bondholdersMeetingId}/advisors`,
    },
    { manual: true },
  );
  return {
    advisorsData,
    isLoadingAdvisorsData,
    getAdvisorsData,
  };
};
