import {
  Configuration,
  PopupRequest,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useRedirectURL } from 'utils/hooks/useRedirectURL';

export const msalConfig: Configuration = {
  auth: {
    clientId: window._env_.AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window._env_.TENANT}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

const scopes = [`api://${window._env_.AD_CLIENT_ID}/voting-portal.backend`];

export const GetLoginRequest = () => {
  const redirectURL = useRedirectURL();
  return {
    scopes: scopes,
    redirectStartPage: redirectURL,
  };
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest: PopupRequest = {
  scopes: scopes,
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, loginRequest, scopes };
