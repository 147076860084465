import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SelectOption } from 'react-select-material-ui';
import { Grid } from '@mui/material';
import Button from 'components/Button';
import FractionInput from 'components/Inputs/FractionInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import { resultOptions } from './const';
import { FormGrid } from './styles';
import { VoteIssueFormFieldsAdd, VoteIssueFormFieldsRow } from './types';

type VoteIssuesDataState = [
  VoteIssueFormFieldsRow[] | undefined,
  Dispatch<SetStateAction<VoteIssueFormFieldsRow[] | undefined>>
];
interface Props {
  disabled?: boolean;
  form: UseFormReturn<VoteIssueFormFieldsAdd>;
  voteIssuesDataState: VoteIssuesDataState;
  dirtyForm: () => void;
  getCurrentFormValues: () => VoteIssueFormFieldsRow[];
  isinOptions?: SelectOption[];
}
const AddRow = ({
  form,
  disabled,
  voteIssuesDataState,
  dirtyForm,
  getCurrentFormValues,
  isinOptions = [],
}: Props) => {
  const {
    control,
    formState: { errors },
    trigger,
    reset,
    getValues,
  } = form;
  const [voteIssuesData, setVoteIssuesData] = voteIssuesDataState;
  const addVoteIssueData = async () => {
    const valid = await trigger();
    if (valid) {
      const nextIndex =
        voteIssuesData && voteIssuesData.length > 0
          ? Math.max(...voteIssuesData.map((item) => item.index), 0) + 1
          : 1;
      const prevValues = getCurrentFormValues();
      const newlyAddedVoteIssueData = getValues();
      setVoteIssuesData(() => [
        ...prevValues,
        {
          ...newlyAddedVoteIssueData,
          index: nextIndex,
          hasCheckedVpsVotes: false,
        },
      ]);

      reset({
        matter: '',
        bondIDs: [],
        result: null,
        quorum: '50%',
        majority: '2/3',
      });

      dirtyForm();
    }
  };
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <FormGrid item xs={3}>
        <TextInput
          name="matter"
          label="Matter to be decided"
          control={control}
          errors={errors}
          required
          disabled={disabled}
          autoFocus
        />
      </FormGrid>
      <FormGrid item xs={4}>
        <MultiSelect
          name="bondIDs"
          isClearable
          label="ISINs"
          options={isinOptions || []}
          control={control}
          errors={errors}
          required
          disabled={disabled}
        />
      </FormGrid>
      <FormGrid item xs={2}>
        <Select
          name="result"
          label="Result"
          options={resultOptions}
          errors={errors}
          control={control}
          isClearable
          disabled={disabled}
        />
      </FormGrid>
      <FormGrid item xs={1}>
        <FractionInput
          name="quorum"
          label="Quorum"
          control={control}
          errors={errors}
          disabled={disabled}
          defaultValue="50%"
          percent
        />
      </FormGrid>
      <FormGrid item xs={1}>
        <FractionInput
          name="majority"
          label="Majority"
          control={control}
          errors={errors}
          disabled={disabled}
          defaultValue="2/3"
          fraction
          percent
        />
      </FormGrid>
      <Grid item>
        <Button
          data-testid="add-button"
          variant="outlined"
          onClick={addVoteIssueData}
          disabled={disabled}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};
export default AddRow;
