import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Table } from '@nordictrustee/nt-ui-library';
import { useAuth } from 'context/AuthProvider';
import * as URL from 'router/url';
import { useQuery } from 'utils/hooks/useQuery';
import { RouteParam } from 'utils/types/RouteParam';
import { IndexedVoteIssue } from '../../types';
import { externalColumns, internalColumns } from './Columns';

interface Props {
  isLoading: boolean;
  data: IndexedVoteIssue[];
}

export const MattersList = ({ isLoading, data }: Props) => {
  const { isInternalEmployeeRole } = useAuth();
  const { bondholdersMeetingId } = useParams<RouteParam>();

  const { push, replace } = useHistory();

  const query = useQuery();

  const voteIssueIdFromQuery = useMemo(() => {
    const idStr = query.get('voteIssueId');
    if (idStr) {
      return parseInt(idStr, 10);
    }
    return undefined;
  }, [query]);

  const createVoteIssueUrl = useCallback(
    (id?: number) =>
      `${URL.BONDHOLDERS_MEETINGS}/${bondholdersMeetingId}?voteIssueId=${
        id || 0
      }`,
    [bondholdersMeetingId],
  );

  useEffect(() => {
    if (!voteIssueIdFromQuery && data.length > 0) {
      replace(createVoteIssueUrl(data[0].id));
    }
  }, [createVoteIssueUrl, data, replace, voteIssueIdFromQuery]);

  const handleRowClick = useCallback(
    (
      _?: MouseEvent<Element, globalThis.MouseEvent>,
      data?: IndexedVoteIssue,
    ) => {
      push(createVoteIssueUrl(data?.id));
    },
    [createVoteIssueUrl, push],
  );
  return (
    <Table.Root
      columns={isInternalEmployeeRole ? internalColumns : externalColumns}
      data={data}
      isLoading={isLoading}
      onRowClick={handleRowClick}
      options={{
        paging: false,
        maxBodyHeight: '20rem',
        toolbar: false,
      }}
      activeRow={voteIssueIdFromQuery}
      disableDefaultSearchAndFilter
    />
  );
};
