import BigNumber from 'bignumber.js';
import { currencyFormat } from 'utils/constants';

export const calculateAmountWithConversion = (
  amount: number,
  bondCurrency: string,
  conversionCurrency: string,
  conversion: number,
) => {
  const bigNumberAmount = new BigNumber(amount).toFormat(currencyFormat);
  const bigNumberConvertedAmount = new BigNumber(amount)
    .multipliedBy(conversion)
    .toFormat(currencyFormat);

  return `${bigNumberAmount} ${bondCurrency} / ${bigNumberConvertedAmount} ${conversionCurrency}`;
};
