import { FC, ReactNode } from 'react';
import {
  NewReleases as MitigateIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import {
  Button,
  LandingPageLayout as Layout,
  LandingPageScreenShot as ScreenShot,
  LandingPageSection as Section,
} from '@nordictrustee/nt-ui-library';
import screenIntro from './images/landingpage1.png';
import screenMitigate from './images/landingpage2.png';
import classes from './AboutLayout.module.css';

export type AboutLayoutProps = {
  logInMarkup?: ReactNode;
};
type MuiButtonColors =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';
const AboutLayout: FC<AboutLayoutProps> = ({ logInMarkup }) => {
  const DemoButton: FC<{ color?: MuiButtonColors }> = ({ color }) => (
    <Button
      startIcon={<SendIcon />}
      href="mailto:portals@nordictrustee.com?subject=Requesting%20Voting%20Portal%20Demo"
      size="large"
      variant={!color && logInMarkup ? 'outlined' : 'contained'}
      color={color ? color : 'primary'}
    >
      Request Demo
    </Button>
  );
  const introMarkup = logInMarkup ? (
    <div className={classes.login}>{logInMarkup}</div>
  ) : (
    <ScreenShot
      screenShot={
        <img src={screenIntro} alt="Screenshot from the Voting Portal" />
      }
    />
  );
  return (
    <Layout
      className={classes.votingPortalTheme}
      title={
        <>
          Voting <strong>Portal</strong>
        </>
      }
      description={
        <p>
          Provides up to date information on voting status in consent
          solicitation processes in which the relevant financial advisor acts as
          advisor to the bond issuer
        </p>
      }
      introFooter={<DemoButton />}
      introDecoration={introMarkup}
      joinUsProps={{
        primaryButton: <DemoButton color={'secondary'} />,
      }}
    >
      <Section
        className={classes.mitigateSection}
        decoration={
          <ScreenShot
            screenShot={
              <img
                src={screenMitigate}
                alt="Screenshot from the Voting Portal"
              />
            }
            backgroundElement={<MitigateIcon />}
          />
        }
        content={
          <>
            <h2>
              <MitigateIcon />
              &nbsp;Access&nbsp;to&nbsp;live&nbsp;
              <strong>voting&nbsp;status</strong>
            </h2>
            <p>
              Access information about live aggregate{' '}
              <strong>voting status</strong>. Depending on the level of
              information sharing accepted by the individual bondholders,
              information about individual holdings and votes cast are
              accessible.
            </p>
          </>
        }
      />
    </Layout>
  );
};

export default AboutLayout;
