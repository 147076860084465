import { useAxios } from 'utils/hooks/useAxios';

export const usePutMeetingDetails = (bondholdersMeetingId: string) => {
  const [, putMeetingDetails] = useAxios(
    {
      url: `/meetings/${bondholdersMeetingId}/details`,
      method: 'PUT',
    },
    {
      manual: true,
    },
  );
  return { putMeetingDetails };
};

export const usePatchMeetingStatus = (bondholdersMeetingId: string) => {
  const [, patchMeetingStatus] = useAxios(
    {
      url: `/meetings/${bondholdersMeetingId}/status`,
      method: 'PATCH',
    },
    {
      manual: true,
    },
  );
  return { patchMeetingStatus };
};
