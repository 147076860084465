import { Table } from '@nordictrustee/nt-ui-library';
import { calculateAmountWithConversion } from 'utils/calculateAmountWithConversion';
import { columnRightText } from 'utils/constants';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { TrusteeType } from 'utils/types/TrusteeType';
import IsinsForm from './IsinsForm';
import SummaryRow from './SummaryRow';
import { IndexedIsinItem, IsinItem } from './types';
import { calculateVotingBonds } from './utils';

const columns: Table.Column<IsinItem>[] = [
  {
    title: 'ISIN',
    field: 'isin',
    width: '15%',
    type: 'string',
  },
  {
    title: 'Outs. Amount',
    field: 'outstandingAmount',
    render: ({
      outstandingAmount,
      bondCurrency,
      conversionCurrency,
      conversionRate,
    }) =>
      calculateAmountWithConversion(
        outstandingAmount,
        bondCurrency,
        conversionCurrency,
        conversionRate
      ),
    ...columnRightText,
  },
  {
    title: 'Issuers Bond Amount',
    field: 'issuerBondAmount',
    render: ({
      issuerBondAmount,
      bondCurrency,
      conversionCurrency,
      conversionRate,
    }) =>
      calculateAmountWithConversion(
        issuerBondAmount,
        bondCurrency,
        conversionCurrency,
        conversionRate
      ),
    ...columnRightText,
  },
  {
    title: 'Voting Bonds',
    field: 'votingBonds',
    render: ({
      outstandingAmount,
      issuerBondAmount,
      bondCurrency,
      conversionRate,
      conversionCurrency,
    }) =>
      calculateVotingBonds(
        outstandingAmount,
        issuerBondAmount,
        bondCurrency,
        conversionRate,
        conversionCurrency
      ),
    ...columnRightText,
  },
];

interface Props {
  isIsinsFormOpen: boolean;
  handleCloseIsinsForm: () => void;
  issuerID?: string;
  isinsData: IsinItem[];
  isLoadingIsinsData: boolean;
  getIsinsData: AxiosPromiseGeneric<IsinItem[]>;
  isinsAssignedToVoteIssues: string[];
  trustee?: TrusteeType;
}

const Isins = ({
  isIsinsFormOpen,
  handleCloseIsinsForm,
  issuerID,
  isinsData,
  isLoadingIsinsData,
  getIsinsData,
  isinsAssignedToVoteIssues,
  trustee,
}: Props) => {
  const indexedIsins: IndexedIsinItem[] = isinsData.map((isinData, index) => {
    return {
      ...isinData,
      index: index + 1,
      id: isinData.id,
      outstandingAmount: isinData.outstandingAmount,
      issuerBondAmount: isinData.issuerBondAmount,
      conversion: isinData.conversionRate,
    };
  });

  return (
    <>
      <Table.Root
        columns={columns}
        data={indexedIsins}
        isLoading={isLoadingIsinsData}
        options={{
          toolbar: false,
          paging: false,
          minBodyHeight: '12.5rem',
          maxBodyHeight:
            trustee === TrusteeType.NordicTrusteeNO ? '12.5rem' : '15rem',
          rowStyle: { padding: 0 },
        }}
        onRowClick={() => {}}
      />
      <SummaryRow data={isinsData} />
      {isIsinsFormOpen && (
        <IsinsForm
          onClose={handleCloseIsinsForm}
          data={indexedIsins}
          getIsinsData={getIsinsData}
          issuerID={issuerID}
          isinsAssignedToVoteIssues={isinsAssignedToVoteIssues}
        />
      )}
    </>
  );
};

export default Isins;
