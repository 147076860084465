import { useMemo } from 'react';
import { CompanyFunctionType } from 'utils/types/CompanyFunctionType';
import * as api from './api';

const useGetCompanyFunction = (companyFunctionType: CompanyFunctionType) => {
  const { companyFunctions, isLoadingCompanyFunctions, getCompanyFunctions } =
    api.useGetCompanyFunctions(companyFunctionType);

  const companyFunctionOptions = useMemo(
    () =>
      companyFunctions?.results?.map(({ id, companyFunctionName }) => ({
        value: id,
        label: companyFunctionName,
      })) ?? [],
    [companyFunctions],
  );

  const value = useMemo(
    () => ({
      companyFunctionOptions,
      isLoadingCompanyFunctions,
      getCompanyFunctions,
    }),
    [companyFunctionOptions, getCompanyFunctions, isLoadingCompanyFunctions],
  );

  return value;
};

export default useGetCompanyFunction;
