import { LinkProps } from 'react-router-dom';
import * as S from './TableLink.css';

interface Props extends LinkProps {
  noPadding?: boolean;
  display?: string;
}

const TableLink = (props: Props) => {
  const { noPadding = false, display, ...rest } = props;
  return (
    <S.Link {...rest} nopadding={noPadding.toString()} display={display} />
  );
};

export default TableLink;
