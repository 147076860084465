import { useAxios } from 'utils/hooks/useAxios';
import { PageableResponse } from 'utils/types/PageableResponse.types';
import { Advisor } from './types';

export const useGetAdvisors = (params: URLSearchParams) => {
  const companyFunctionType = 'Advisor';
  const [{ data: advisors, loading: isLoadingAdvisors }, getAdvisors] =
    useAxios<PageableResponse<Advisor[]>>(
      {
        url: `/user-management/groups${
          params.toString()
            ? `?${params}&companyFunctionType=${companyFunctionType}`
            : ''
        }`,
      },
      { manual: true },
    );
  return {
    advisors,
    isLoadingAdvisors,
    getAdvisors,
  };
};
