import { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useAuth } from 'context/AuthProvider';
import Card from 'components/Card';
import { useQuery } from 'utils/hooks/useQuery';
import { IsinItem } from '../IsinsAndAdvisors/Isins/types';
import { MeetingDetailsContext } from '../MeetingProvider/MeetingDetailsProvider';
import { IndexedVoteIssue } from '../types';
import { getConvertedVotingBondsSum } from '../utils';
import { PlaceholderBox, ResultGraph, StatusGraph } from './components';
import * as S from './styles';
import {
  fractionToPercent,
  getFormattedNumberWithCurrency,
  getNumberUpToAMaxNumber,
  getTotalOfVotes,
} from './utils';

interface Props {
  votingBonds: IsinItem[];
  voteData?: IndexedVoteIssue;
}

const Statistics = ({ votingBonds, voteData }: Props) => {
  const { isInternalEmployeeRole } = useAuth();
  const query = useQuery();
  const voteIssueIdFromQuery = useMemo(
    () => query.get('voteIssueId') || '',
    [query],
  );

  const { votesDetails, isLoadingStatisticsData, statisticsData } = useContext(
    MeetingDetailsContext,
  );

  const convertedVotingBondsSum = useMemo(
    () => getConvertedVotingBondsSum(votingBonds),
    [votingBonds],
  );
  const totalInFavourOfApproved = getTotalOfVotes(votesDetails, true, true);
  const totalVotesReceived = getNumberUpToAMaxNumber(
    getTotalOfVotes(votesDetails),
    convertedVotingBondsSum,
  );

  const weightedCurrency =
    votesDetails.length > 0 ? votesDetails[0].weightedAmountCurrency : '';

  const inFavourOfVotingBonds = `${getFormattedNumberWithCurrency(
    totalInFavourOfApproved,
    weightedCurrency,
  )}/${fractionToPercent(
    new BigNumber(totalInFavourOfApproved),
    new BigNumber(convertedVotingBondsSum),
  )}  % of Voting Bonds`;

  const inFavourOfApprovedVotes = `${getFormattedNumberWithCurrency(
    totalInFavourOfApproved,
    weightedCurrency,
  )}/${fractionToPercent(
    new BigNumber(totalInFavourOfApproved),
    new BigNumber(getTotalOfVotes(votesDetails, true)),
  )}  % of Approved Votes`;
  const getThresholds = () => {
    const { quorum, majorityDenominator, majorityNumerator } = voteData || {};
    return { quorum, majorityDenominator, majorityNumerator };
  };
  const getTotalVotesReceived = () => {
    if (isInternalEmployeeRole) {
      return getFormattedNumberWithCurrency(
        totalVotesReceived,
        weightedCurrency,
      );
    } else {
      const currency =
        votingBonds.length > 0 ? votingBonds[0].conversionCurrency : '';
      const votesReceived =
        !isLoadingStatisticsData && !!statisticsData?.status
          ? statisticsData.status.VotesApproved +
            statisticsData.status.VotesNotApproved
          : 0;

      const percentage = fractionToPercent(
        new BigNumber(votesReceived),
        new BigNumber(convertedVotingBondsSum),
      );
      return `${getFormattedNumberWithCurrency(
        votesReceived,
        currency,
      )} / ${getFormattedNumberWithCurrency(
        convertedVotingBondsSum,
        currency,
      )}. ${percentage} % of Voting Bonds`;
    }
  };
  const { quorum, majorityNumerator, majorityDenominator } = getThresholds();
  return voteIssueIdFromQuery ? (
    <Card
      title="Statistics"
      data-testid="statistics"
      isLoading={isLoadingStatisticsData}
      customAction={
        isInternalEmployeeRole ? (
          <>
            <S.ParagraphContainer>
              <S.LeftSideParagraph>In Favour:</S.LeftSideParagraph>
              <S.RightSideParagraph>
                {inFavourOfVotingBonds}
              </S.RightSideParagraph>
            </S.ParagraphContainer>
            <S.ParagraphContainer>
              <S.LeftSideParagraph>In Favour:</S.LeftSideParagraph>
              <S.RightSideParagraph>
                {inFavourOfApprovedVotes}
              </S.RightSideParagraph>
            </S.ParagraphContainer>
          </>
        ) : undefined
      }
    >
      <Grid
        container
        spacing={4}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item md={6}>
          <StatusGraph data={statisticsData?.status} quorum={quorum} />
        </Grid>
        <Grid item md={6}>
          <ResultGraph
            data={statisticsData?.result}
            majorityNumerator={majorityNumerator}
            majorityDenominator={majorityDenominator}
          />
        </Grid>
        <Grid item xs={12}>
          <S.ParagraphContainer>
            <S.LeftSideParagraph>Total Votes Received:</S.LeftSideParagraph>
            <S.RightSideParagraph>
              {getTotalVotesReceived()}
            </S.RightSideParagraph>
          </S.ParagraphContainer>
        </Grid>
      </Grid>
    </Card>
  ) : (
    <PlaceholderBox
      data-testid="statistics-placeholder"
      text="Select matter to be decided for the details"
    />
  );
};

export default Statistics;
