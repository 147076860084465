
interface Props {
  disabled?: boolean;
}

const DocIcon = ({disabled}: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.01 4C4.01 2.9 4.9 2 6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4ZM13 3.5V9H18.5L13 3.5ZM15.0417 11.6797L13.989 17.2441L12.7053 11.6797H11.3411L10.052 17.2334L8.9939 11.6797H7.38794L9.1228 19.5H10.7449L12.0178 14.2686L13.2908 19.5H14.9128L16.6477 11.6797H15.0417Z"
      fill={disabled ? "rgba(0, 0, 0, 0.26)" : "#6F6F6F"}
    />
  </svg>
);

export default DocIcon;
