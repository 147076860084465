import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { VoteIssue } from 'modules/BondholdersMeeting/screens/Meeting/types';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { MeetingType } from 'utils/types/MeetingType';
import { RouteParam } from 'utils/types/RouteParam';
import { TrusteeType } from 'utils/types/TrusteeType';
import { IndexedVoteIssue } from '../../types';
import { Context } from '../Context';
import VpsCheck from './EditMattersForm/VpsCheck';
import AddRow from './AddRow';
import * as api from './api';
import { EditMattersForm } from './EditMattersForm';
import {
  VoteIssueFormFieldMap,
  VoteIssueFormFieldsAdd,
  VoteIssueFormFieldsRow,
} from './types';
import {
  convertFractionToNumbers,
  convertPercentToNumber,
  getVpsVotesMustBeChecked,
} from './utils';

interface Props {
  status?: MeetingStatus;
  onClose: () => void;
  data: IndexedVoteIssue[];
  getVoteIssues: AxiosPromiseGeneric<VoteIssue[]>;
  trusteeType?: TrusteeType;
  meetingType?: MeetingType;
}

const EditDialog = ({
  status,
  onClose,
  data,
  getVoteIssues,
  trusteeType,
  meetingType,
}: Props) => {
  const disabledEnded = status === 'Ended';
  const { bondholdersMeetingId } = useParams<RouteParam>();

  const { putVoteIssues } = api.usePutVoteIssues(bondholdersMeetingId);

  const { isinOptions, isLoadingMeetingIsins, getMeetingIsins } =
    useContext(Context);

  const voteIssuesDataState = useState<VoteIssueFormFieldsRow[]>();
  const [voteIssuesData, setVoteIssuesData] = voteIssuesDataState;
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [canDisplayError, setCanDisplayError] = useState(false);
  const [hasCheckedVpsVotes, setHasCheckedVpsVotes] = useState(
    voteIssuesData != null && voteIssuesData.length > 0
      ? voteIssuesData?.every((v) => v.hasCheckedVpsVotes)
      : false
  );

  useEffect(() => {
    getMeetingIsins();
  }, [getMeetingIsins]);

  const makeFractionString = (n: number, d?: number) => {
    if (!d || d === 100) {
      return `${n}%`;
    }
    return `${n}/${d}`;
  };
  useEffect(() => {
    if (!isinOptions || !isinOptions.length) {
      return;
    }
    const voteIssuesWithBonds = data.map(
      ({ isins, quorum, majorityNumerator, majorityDenominator, ...rest }) => {
        const bondIDs = (isinOptions || [])
          .filter((isinItem) => isins.includes(isinItem.label))
          .map(({ value }) => value);
        return {
          ...rest,
          quorum: quorum ? makeFractionString(quorum) : undefined,
          majority: majorityNumerator
            ? makeFractionString(majorityNumerator, majorityDenominator)
            : undefined,
          bondIDs,
        };
      }
    );
    setVoteIssuesData(voteIssuesWithBonds);
  }, [data, isinOptions, setVoteIssuesData]);

  const tableForm = useForm<VoteIssueFormFieldMap>({ mode: 'onBlur' });
  const addForm = useForm<VoteIssueFormFieldsAdd>({ mode: 'onSubmit' });
  const {
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset,
  } = tableForm;
  const dirtyForm = () => setIsFormDirty(true);
  const getCurrentFormValues: () => VoteIssueFormFieldsRow[] =
    useCallback((): VoteIssueFormFieldsRow[] => {
      const currentValues: VoteIssueFormFieldMap = getValues();
      let currentValuesArray: VoteIssueFormFieldsRow[] = [];
      Object.keys(currentValues).forEach((key) => {
        currentValuesArray.push({
          ...currentValues[key as unknown as number],
        });
      });

      return currentValuesArray;
    }, [getValues]);

  useEffect(() => {
    setHasCheckedVpsVotes(
      getCurrentFormValues() != null &&
        getCurrentFormValues().length > 0 &&
        getCurrentFormValues()?.every((v) => v.hasCheckedVpsVotes)
    );
  }, [getCurrentFormValues]);

  const formData = useMemo(() => {
    return voteIssuesData?.reduce(
      (
        obj: VoteIssueFormFieldMap,
        item: VoteIssueFormFieldsRow
      ): VoteIssueFormFieldMap => {
        return {
          ...obj,
          [item.index]: item,
        };
      },
      {}
    );
  }, [voteIssuesData]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const onSubmit = handleSubmit(async () => {
    if (vpsVotesMustBeChecked && !hasCheckedVpsVotes) {
      setCanDisplayError(true);
    } else {
      try {
        const formattedVoteIssuesData = getCurrentFormValues().map(
          ({ id, quorum, majority, ...rest }: VoteIssueFormFieldsRow) => {
            const [majorityNumerator, majorityDenominator] = majority
              ? convertFractionToNumbers(majority)
              : [];
            return {
              ...rest,
              voteIssueID: id,
              quorum: quorum ? convertPercentToNumber(quorum) : undefined,
              majorityNumerator,
              majorityDenominator,
            };
          }
        );
        await putVoteIssues({ data: formattedVoteIssuesData });
        toast.success('Matters to be decided have been saved');
        getVoteIssues();
        onClose();
      } catch (e) {
        handleException(e);
      }
    }
  });

  const handleConfirmClose = () => {
    confirmClose(isFormDirty, onClose);
  };

  const handleHasCheckedVpsVotesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasCheckedVpsVotes(!hasCheckedVpsVotes);
    let updatedValues = getCurrentFormValues()?.map((a) => ({
      ...a,
      hasCheckedVpsVotes: event.target.checked,
    }));
    setVoteIssuesData(updatedValues);
  };

  const vpsVotesMustBeChecked = getVpsVotesMustBeChecked(
    getCurrentFormValues(),
    trusteeType,
    meetingType
  );

  return (
    <Dialog
      title="Edit Matters to be decided"
      data-testid="edit-matters-to-be-decided-dialog"
      onClose={handleConfirmClose}
      maxWidth="xl"
      loading={isLoadingMeetingIsins}
      dialogActions={
        <>
          <Button onClick={onSubmit}>Save</Button>
          <Button variant="outlined" onClick={handleConfirmClose}>
            Cancel
          </Button>
        </>
      }
    >
      <AddRow
        form={addForm}
        disabled={disabledEnded}
        voteIssuesDataState={voteIssuesDataState}
        dirtyForm={dirtyForm}
        getCurrentFormValues={getCurrentFormValues}
        isinOptions={isinOptions}
      />
      <EditMattersForm
        form={tableForm}
        disabled={disabledEnded}
        voteIssuesData={voteIssuesData}
        setVoteIssuesData={setVoteIssuesData}
        getCurrentFormValues={getCurrentFormValues}
      />
      <VpsCheck
        hasCheckedVpsVotes={hasCheckedVpsVotes}
        meetingType={meetingType}
        trusteeType={trusteeType}
        vpsVotesMustBeChecked={vpsVotesMustBeChecked}
        handleHasCheckedVpsVotesChange={handleHasCheckedVpsVotesChange}
        canDisplayError={canDisplayError}
      />
    </Dialog>
  );
};

export default EditDialog;
