import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useFormContext, UseFormTrigger } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Dropzone from 'components/Dropzone';
import FileInput from 'components/Inputs/FileInput';
import {
  VoteDetails,
  VoteDocument,
} from 'modules/BondholdersMeeting/screens/Meeting/types';
import { downloadFile } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import { handleException } from 'utils/handleException';
import { helperText } from 'utils/reactHookFormUtils';
import { fileInputCustomStyle } from '../../../VotesDocumentForm/VotesDocumentForm.styles';
import * as api from '../../api';
import FilesList from './FilesList';

interface Props {
  setVoteDocuments: Dispatch<SetStateAction<(File | VoteDocument)[]>>;
  voteDocuments: (File | VoteDocument)[];
  trigger: UseFormTrigger<any>; // TODO - type
  editedItem?: VoteDetails;
}

const AddDocumentsForm = ({
  voteDocuments,
  setVoteDocuments,
  trigger,
  editedItem,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { getDocument } = api.useGetVoteDocuments();
  const { deleteDocument } = api.useDeleteVoteDocument();

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    var files = (event?.currentTarget as HTMLInputElement)?.files;
    if (files) {
      const filesAsArray = Array.from(files);
      setVoteDocuments((prevState) =>
        prevState ? [...prevState, ...filesAsArray] : filesAsArray
      );
      trigger();
    }
  };

  const dropAction = async (droppedFile: File[]) => {
    setVoteDocuments((prevState) =>
      prevState ? [...prevState, ...droppedFile] : droppedFile
    );
    trigger();
  };

  const deleteVotesDocument = async (file: File | VoteDocument) => {
    setVoteDocuments((prevState) => prevState.filter((el) => el !== file));
    if (editedItem) {
      const voteDocument = file as VoteDocument;
      try {
        await deleteDocument(editedItem.id, voteDocument.id);
        toast.success(`Deleted file ${file.name}`);
      } catch (e) {
        handleException(e);
      }
    }
  };

  const handleDownloadFile = async (file: File | VoteDocument) => {
    let blob;
    if (file.hasOwnProperty('id') && editedItem) {
      try {
        blob = await getDocument(editedItem.id, file as VoteDocument);
      } catch (e) {
        handleException(e);
      }
    } else {
      blob = file;
    }
    if (blob) {
      downloadFile(blob as File);
    }
  };

  return (
    <Dropzone multiple onDropCallback={dropAction} acceptFormats="">
      <Box height="200px">
        <FileInput
          control={control}
          acceptFormat=""
          name="voteDocuments"
          onChangeCallback={handleFileInputChange}
          data-testid="votes-file"
          text="Choose file"
          multiple
          style={fileInputCustomStyle}
          error={helperText('votes', errors)}
          label="Documents"
        />
        <FilesList
          files={voteDocuments}
          onDelete={deleteVotesDocument}
          onDownload={handleDownloadFile}
        />
      </Box>
    </Dropzone>
  );
};

export default AddDocumentsForm;
