import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box, Card, Typography } from '@mui/material';
import { useAuth } from 'context/AuthProvider';
import * as URL from 'router/url';
import styled from 'styled-components';
import Button from 'components/Button';
import { useToggle } from 'utils/hooks/useToggle';
import BondholdersMeetingForm from './AddMeetingForm';
import * as api from './api';
import { MeetingsList } from './MeetingsList';
import { useMeetingQuery } from './useMeetingQuery';

const MeetingsCard = styled(Card)`
  padding-top: 1rem;
  width: 100%;
`;

const Meetings = () => {
  const { isInternalEmployeeRole } = useAuth();
  const { replace } = useHistory();

  const { searchQuery, ...meetingQuery } = useMeetingQuery();

  const { meetings, isLoadingMeetings, getMeetings } =
    api.useGetBondholdersMeeting(searchQuery);

  useEffect(() => {
    replace(`${URL.BONDHOLDERS_MEETINGS}?${searchQuery.toString()}`);
    getMeetings();
  }, [getMeetings, replace, searchQuery]);

  const [
    bondholderMeetingsFormOpen,
    openBondholderMeetingsForm,
    closeBondholderMeetingsForm,
  ] = useToggle();

  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        sx={{ pb: '24px' }}
      >
        <Typography variant="h2">Bondholders Meeting</Typography>
        {isInternalEmployeeRole && (
          <Button startIcon={<AddIcon />} onClick={openBondholderMeetingsForm}>
            Add meeting
          </Button>
        )}
      </Box>
      <MeetingsCard>
        {meetingQuery && (
          <MeetingsList
            meetingQuery={meetingQuery}
            isLoadingMeetings={isLoadingMeetings}
            meetings={meetings}
            isInternalEmployee={isInternalEmployeeRole}
          />
        )}
      </MeetingsCard>
      {bondholderMeetingsFormOpen && (
        <BondholdersMeetingForm
          title="Add Bondholders Meeting"
          onClose={closeBondholderMeetingsForm}
        />
      )}
    </>
  );
};

export default Meetings;
