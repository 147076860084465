import styled from 'styled-components';

export const AddSectionWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    :nth-of-type(4) {
      flex: 1;
    }
    :nth-of-type(1),
    :nth-of-type(2),
    :nth-of-type(3) {
      flex: 2.5;
    }
    padding-right: 2rem;
  }

  & > span {
    display: flex;
    flex: 1;
    padding-right: 2rem;
  }
`;

export const CurrencyWrapper = styled.div`
  padding: 0;
  margin: 0;
`;
