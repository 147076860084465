import { Tooltip } from '@mui/material';

import styled from 'styled-components';
import theme from 'theme/theme';

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.palette.grey[200]};
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
  &&:last-of-type {
    flex: 1;
    overflow: auto;
  }
`;

const LeftSideParagraph = styled.div`
  padding: 0.5rem 0;
  color: ${theme.palette.text.secondary};
  text-transform: capitalize;
`;

const RightSideParagraph = styled.div`
  padding: 0.5rem 0;
  margin-left: 0.5rem;
  text-align: right;
  color: ${theme.palette.text.primary};
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 25%;
  white-space: nowrap;
`;

interface Props {
  label: string;
  value?: string;
}

export const DataRow = ({ label, value }: Props) => {
  const labelWithoutSpaces = label.replace(' ', '');
  const text = value ? value : ''; // value can be null from API
  return (
    <Row data-testid={`data-row-wrapper-${labelWithoutSpaces}`}>
      <LeftSideParagraph data-testid={`data-row-label-${labelWithoutSpaces}`}>
        {label}
      </LeftSideParagraph>
      {text.length > 12 ? (
        <Tooltip title={text}>
          <RightSideParagraph
            data-testid={`data-row-text-${labelWithoutSpaces}`}
          >
            {text}
          </RightSideParagraph>
        </Tooltip>
      ) : (
        <RightSideParagraph data-testid={`data-row-text-${labelWithoutSpaces}`}>
          {text}
        </RightSideParagraph>
      )}
    </Row>
  );
};
