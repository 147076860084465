import { memo } from 'react';
import MCheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/material';
import theme from 'theme/theme';

const RejectIcon = () => (
  <ClearIcon style={{ color: theme.palette.error.main }} />
);
const CheckIcon = () => (
  <MCheckIcon style={{ color: theme.palette.success.main }} />
);

interface Props {
  isVisible: boolean;
  isResultVisible: boolean;
}

export const VotesIcons = memo(({ isVisible, isResultVisible }: Props) => (
  <Box display="flex" alignItems="center" justifyContent="center" width="100%">
    {isVisible ? <CheckIcon /> : <RejectIcon />}
    {isResultVisible ? <CheckIcon /> : <RejectIcon />}
  </Box>
));
