import { FormControl as FormControlM, Grid } from '@mui/material';
import styled from 'styled-components';

export const FormGrid = styled(Grid)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  &&:first-of-type {
    padding-left: 0;
  }
`;

export const FormControl = styled(FormControlM)`
  height: 62px;
`;
