import { FC, ReactNode } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useAuth } from 'context/AuthProvider';
import { AuthMethod } from 'context/AuthProvider/types';
import Layout, { MainContainer } from 'components/Layout';
import ForgotPassword from 'modules/Auth/ForgotPassword';
import Login from 'modules/Auth/Login';
import VerifyAccount from 'modules/Auth/VerifyAccount';
import BondholdersMeeting from 'modules/BondholdersMeeting';
import { Terms, TermsAndConditions } from 'modules/TermsAndConditions';
import UploadedFiles from 'modules/UploadedFiles';
import UserManagement from 'modules/UserManagement';
import About, { AboutLayout } from '../modules/About';
import SentEmailConfirmation from '../modules/Auth/ForgotPassword/SentEmailConfirmation';
import ResetPassword from '../modules/Auth/ResetPassword';
import * as URL from './url';

const RouteContent = ({
  page,
  children,
}: {
  page?: string;
  children: ReactNode;
}) => {
  const { path } = useRouteMatch();

  const displayPage = page ? ` - ${page}` : '';
  document.title = `Voting Portal${displayPage}`;
  if (path.startsWith(URL.ABOUT) || path.startsWith(URL.LOGIN)) {
    return <>{children}</>;
  }
  return <MainContainer>{children}</MainContainer>;
};

const Routes: FC<{ children: ReactNode }> = ({ children }) => (
  <Switch>
    <Route exact path={URL.ABOUT}>
      <RouteContent page="About" children={<About />} />
    </Route>
    <Route path={URL.TERMS}>
      <RouteContent page="Terms" children={<Terms />} />
    </Route>
    {children}
  </Switch>
);
const CommonRoutes = ({
  termsSigned,
  externalUser,
  children,
}: {
  termsSigned?: string;
  externalUser?: boolean;
  children?: ReactNode;
}) => {
  if (externalUser && termsSigned === null) {
    return <TermsAndConditions />;
  }
  return (
    <Routes>
      {children}
      <Route exact path={URL.BONDHOLDERS_MEETINGS}>
        <RouteContent
          page="Meetings"
          children={<BondholdersMeeting screen={'List'} />}
        />
      </Route>
      <Route path={URL.BONDHOLDERS_MEETINGS_DATA}>
        <RouteContent
          page="Meeting"
          children={<BondholdersMeeting screen={'Data'} />}
        />
      </Route>
      <Redirect to={URL.BONDHOLDERS_MEETINGS} />
    </Routes>
  );
};

const InternalRoutes = () => (
  <CommonRoutes>
    <Route path={URL.UPLOADED_FILES}>
      <RouteContent page="Uploaded Files" children={<UploadedFiles />} />
    </Route>
    <Route path={URL.USER_MANAGEMENT}>
      <RouteContent page="User Management" children={<UserManagement />} />
    </Route>
  </CommonRoutes>
);

const UnauthenticatedRoutes = () => {
  const currentLoc = window.location.pathname;
  const redirectURL =
    currentLoc === '/' || currentLoc === URL.LOGIN
      ? URL.LOGIN
      : URL.LOGIN + `?redirectTo=${currentLoc}`;
  return (
    <Routes>
      <Route exact path={URL.LOGIN}>
        <RouteContent
          page=""
          children={<AboutLayout logInMarkup={<Login />} />}
        />
      </Route>
      <Route path={URL.FORGOT_PASSWORD}>
        <RouteContent page="Forgot Password" children={<ForgotPassword />} />
      </Route>
      <Route path={URL.SENT_EMAIL_CONFIRMATION}>
        <RouteContent page="" children={<SentEmailConfirmation />} />
      </Route>
      <Route path={URL.RESET_PASSWORD}>
        <RouteContent page="Reset Password" children={<ResetPassword />} />
      </Route>
      <Route path={URL.VERIFY_ACCOUNT}>
        <RouteContent page="Verify Account" children={<VerifyAccount />} />
      </Route>
      <Redirect to={redirectURL} />
    </Routes>
  );
};

const RoutesController = () => {
  const { user, isAuthenticated } = useAuth();
  const isExternalUser =
    isAuthenticated && user?.authMethod === AuthMethod.IdentityService;
  const termsSigned = user?.termsAndConditionsAcceptedDate;
  return (
    <Layout>
      {isExternalUser ? (
        <CommonRoutes externalUser={true} termsSigned={termsSigned} />
      ) : (
        <>
          <AuthenticatedTemplate>
            <InternalRoutes />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <UnauthenticatedRoutes />
          </UnauthenticatedTemplate>
        </>
      )}
    </Layout>
  );
};

export default RoutesController;
