import { useCallback, useState } from 'react';
import { useAuth } from 'context/AuthProvider';
import Card from 'components/Card';
import TabMenu from 'components/TabMenu';
import { useToggle } from 'utils/hooks/useToggle';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { TrusteeType } from 'utils/types/TrusteeType';
import { MeetingStatus } from '../../../../../utils/types/MeetingStatus';
import { IsinItem } from './Isins/types';
import Advisors from './Advisors';
import { tabs } from './const';
import Isins from './Isins';

interface Props {
  status?: MeetingStatus;
  issuerID?: string;
  isinsData: IsinItem[];
  isLoadingIsinsData: boolean;
  getIsinsData: AxiosPromiseGeneric<IsinItem[]>;
  isinsAssignedToVoteIssues: string[];
  trustee?: TrusteeType;
}

const IsinsAndAdvisors = ({
  status,
  issuerID,
  isinsData,
  isLoadingIsinsData,
  getIsinsData,
  isinsAssignedToVoteIssues,
  trustee,
}: Props) => {
  const { isInternalEmployeeRole, isAdvisorRole } = useAuth();

  const [isIsinsFormOpen, handleOpenIsinForm, handleCloseIsinsForm] =
    useToggle();
  const [isAdvisorsFormOpen, handleOpenAdvisorsForm, handleCloseAdvisorsForm] =
    useToggle();

  const [currentTab, setCurrentTab] = useState(tabs.isins.value);

  const handleTabChange = useCallback((_, value) => {
    setCurrentTab(value);
  }, []);

  const handleOpenEditForm = () => {
    switch (currentTab) {
      case tabs.isins.value: {
        handleCloseAdvisorsForm();
        handleOpenIsinForm();
        break;
      }
      case tabs.advisors.value: {
        handleCloseIsinsForm();
        handleOpenAdvisorsForm();
        break;
      }
    }
  };

  return (
    <Card
      customHeader={
        isInternalEmployeeRole ? (
          <TabMenu
            items={tabs}
            data-testid="tab-menu"
            tabsValue={currentTab}
            handleChange={handleTabChange}
          />
        ) : undefined
      }
      title={isAdvisorRole ? 'Isins' : undefined}
      onEditClick={handleOpenEditForm}
      canEdit={
        isInternalEmployeeRole &&
        (status === 'Active' || currentTab !== 'isins')
      }
      withoutPadding
      data-testid="isins-and-advisors"
    >
      {currentTab === tabs.isins.value && (
        <Isins
          isIsinsFormOpen={isIsinsFormOpen}
          handleCloseIsinsForm={handleCloseIsinsForm}
          issuerID={issuerID}
          isinsData={isinsData}
          isLoadingIsinsData={isLoadingIsinsData}
          getIsinsData={getIsinsData}
          isinsAssignedToVoteIssues={isinsAssignedToVoteIssues}
          trustee={trustee}
        />
      )}
      {currentTab === tabs.advisors.value && (
        <Advisors
          isAdvisorsFormOpen={isAdvisorsFormOpen}
          handleCloseAdvisorsForm={handleCloseAdvisorsForm}
        />
      )}
    </Card>
  );
};

export default IsinsAndAdvisors;
