import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const MARGIN = 45;

export const meetingStatusColor = {
  // Uppercase to handle Status[Value] notation
  Active: '#6546bb',
  Ended: '#DEAD00',
  Closed: '#E77E3B',
  Canceled: '#979797',
};

export const meetingResultColor = {
  // Uppercase to handle Result[Value] notation
  Ongoing: '#6546bb',
  Approved: '#4CAF50',
  NotApproved: '#FF0000',
  Mixed: '#E77E3B',
  Inconclusive: '#a25827',
  Canceled: '#979797',
};

export const uploadedFileResultColor = {
  // Uppercase to handle Result[Value] notation
  Processing: '#6546bb',
  Succeeded: '#4CAF50',
  Waiting: '#DEAD00',
  Failed: '#FF0000',
};

export const statisticsVotesReceivedColor = {
  notVoted: '#CDC3E8',
  votesNotApproved: '#9179D1',
  votesApproved: '#3F18AA',
};

export const statisticsVotesColor = {
  inFavour: '#7F96F7', // yes
  against: '#A84924', // no
  noVote: '#003748', // no vote
  undisclosed: '#91e5ce', // undisclosed
};

const palette = {
  common: {
    black: '#13161c',
  },
  primary: {
    main: '#3f18aa',
    light: '#6546bb',
    dark: '#2c1076',
  },
  secondary: {
    main: '#e87f38',
    light: '#ec985f',
    dark: '#a25827',
  },
  error: {
    main: '#ff0000',
    dark: '#e80d0d',
  },
  success: {
    main: '#4caf50',
  },
  text: {
    primary: '#13161c',
    secondary: '#0000008a',
  },
  background: {
    default: '#f7f8f6',
    paper: '#fff',
  },
  info: {
    main: '#2196f3',
    contrastText: '#fff',
  },
};

const typography: TypographyOptions = {
  h1: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '40px',
    lineHeight: '38px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  h2: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '30px',
    lineHeight: '35px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
    marginBottom: '24px',
  },
  h3: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '20px',
    lineHeight: '23px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  h4: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '20px',
    lineHeight: '23px',
    color: '#13161c',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  h5: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: '17px',
    lineHeight: '20px',
    color: '#0000008a',
    fontWeight: 700,
  },
  h6: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '17px',
    lineHeight: '25px',
    color: '#13161c',
    fontWeight: 'bold',
  },
  body1: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '17px',
    lineHeight: '25px',
    color: '#13161c',
  },
  body2: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',
    lineHeight: '20px',
    color: '#13161c',
    fontWeight: 400,
  },
  button: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '17px',
    lineHeight: '20px',
    color: '#13161c',
    fontWeight: 500,
    letterSpacing: '0.59px',
  },
};

// TODO: verify which properties are used
const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1650,
    },
  },
  typography,
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 5px 5px #00000033',
          borderRadius: '4px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          paddingLeft: '45px',
          paddingRight: '45px',
          '@media (max-width:600px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '5px',
          borderRadius: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // textTransform: 'capitalize',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          // https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 8,
        },
        head: {
          color: 'rgba(0,0,0,0.56)',
          fontWeight: 'bold',
          fontFamily: 'Roboto Condensed',
          fontSize: 16,
          '&&:first-of-type': {
            paddingLeft: '1rem',
          },
          '&&:last-of-type': {
            paddingRight: '1rem',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'inherit',
          position: 'relative',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        icon: {
          position: 'sticky',
          top: 'calc(50% - 9px)',
          right: -4,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          '@media (min-width: 600px)': {
            padding: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          whiteSpace: 'pre-wrap',
          maxWidth: '600px',
          overflowY: 'auto',
          maxHeight: '60vh',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '36px',
          fontSize: 14,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          fontSize: 'inherit', // or unset
        },
        colorInherit: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
        root: {
          marginTop: '0rem',
          marginBottom: '-0.75rem',
          textTransform: 'capitalize',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          padding: '16px',
        },
      },
    },
  },
});
export default theme;
