import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const SingleDocument = styled.div`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;
export const MoreButton = styled(IconButton)`
  padding: 0;
`;
