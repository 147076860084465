import { useCallback, useMemo, useState } from 'react';
import { Table } from '@nordictrustee/nt-ui-library';
import { useQuery } from 'utils/hooks/useQuery';

export const useMeetingQuery = () => {
  const query = useQuery();

  const pageSizeFromQuery = useMemo(
    () => Number(query.get('pageSize')) || 25,
    [query]
  );
  const pageFromQuery = useMemo(() => Number(query.get('page')) || 0, [query]);

  const votingDeadlineFromQuery = useMemo(
    () => query.get('votingDeadline') || '',
    [query]
  );
  const administratorFromQuery = useMemo(
    () => query.get('administrator') || '',
    [query]
  );
  const issuerFromQuery = useMemo(() => query.get('issuer') || '', [query]);
  const titleFromQuery = useMemo(() => query.get('title') || '', [query]);
  const isinsFromQuery = useMemo(() => query.get('isins') || '', [query]);

  const typeFromQuery = useMemo(() => query.getAll('type') || [], [query]);
  const resultFromQuery = useMemo(() => query.getAll('result') || [], [query]);
  const statusFromQuery = useMemo(
    () =>
      query.getAll('status').length > 0 ? query.getAll('status') : ['Active'],
    [query]
  );

  const orderByFromQuery = useMemo(() => query.get('orderBy') || '', [query]);
  const orderDirectionFromQuery = useMemo(
    () => query.get('orderDirection') || '',
    [query]
  );

  const [pageSizeQuery, setPageSizeQuery] = useState(pageSizeFromQuery);
  const [pageQuery, setPageQuery] = useState(pageFromQuery);
  const [votingDeadlineQuery, setVotingDeadlineQuery] = useState(
    votingDeadlineFromQuery
  );
  const [administratorQuery, setAdministratorQuery] = useState(
    administratorFromQuery
  );
  const [issuerQuery, setIssuerQuery] = useState(issuerFromQuery);
  const [titleQuery, setTitleQuery] = useState(titleFromQuery);
  const [isinsQuery, setIsinsQuery] = useState(isinsFromQuery);
  const [typeQuery, setTypeQuery] = useState(typeFromQuery);
  const [resultQuery, setResultQuery] = useState(resultFromQuery);
  const [statusQuery, setStatusQuery] = useState(statusFromQuery);

  const [orderByQuery, setOrderByQuery] = useState(orderByFromQuery);
  const [orderDirectionQuery, setOrderDirection] = useState(
    orderDirectionFromQuery
  );

  const setSorting = useCallback(
    (orderBy: number, orderDirection: 'desc' | 'asc') => {
      if (orderBy === -1) {
        setOrderByQuery('');
        setOrderDirection('');
      } else {
        const columns = [
          'votingDeadline',
          'administrator',
          'issuer',
          'title',
          'isins',
          'type',
          'result',
          'status',
        ];
        setOrderByQuery(columns[orderBy]);
        setOrderDirection(orderDirection);
      }
    },
    []
  );

  const setFilter = useCallback((filters: Table.Filter<any>[]) => {
    setVotingDeadlineQuery('');
    setAdministratorQuery('');
    setIssuerQuery('');
    setTitleQuery('');
    setIsinsQuery('');
    setTypeQuery([]);
    setResultQuery([]);
    setStatusQuery([]);

    filters.forEach((f) => {
      switch (f.column.field) {
        case 'votingDeadline':
          setVotingDeadlineQuery(f.value);
          break;
        case 'administrator':
          setAdministratorQuery(f.value);
          break;
        case 'issuer':
          setIssuerQuery(f.value);
          break;
        case 'title':
          setTitleQuery(f.value);
          break;
        case 'isins':
          setIsinsQuery(f.value);
          break;
        case 'type':
          setTypeQuery(f.value);
          break;
        case 'result':
          setResultQuery(f.value);
          break;
        case 'status':
          setStatusQuery(f.value);
          break;
      }
    });
  }, []);

  const params = useMemo(
    () => ({
      page: pageQuery,
      pageSize: pageSizeQuery,
      votingDeadline: votingDeadlineQuery,
      administrator: administratorQuery,
      issuer: issuerQuery,
      title: titleQuery,
      isins: isinsQuery,
      type: typeQuery,
      result: resultQuery,
      status: statusQuery,
      sorting: orderByQuery,
      sortingDirection: orderDirectionQuery,
    }),
    [
      administratorQuery,
      isinsQuery,
      issuerQuery,
      orderByQuery,
      orderDirectionQuery,
      pageQuery,
      pageSizeQuery,
      resultQuery,
      statusQuery,
      titleQuery,
      typeQuery,
      votingDeadlineQuery,
    ]
  );

  // skips unnecessary query params in URL
  // passing params arrays: x=1&x=2&x=3 instead of x=1,2,3
  const searchQuery = useMemo(() => {
    let searchQuery: string[][] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== '') {
        // typeof value === 'number' for page and pageSize types compatibility
        if (typeof value === 'string' || typeof value === 'number') {
          searchQuery.push([key, `${value}`]);
        } else if (value?.length > 0) {
          value.forEach((value) => {
            searchQuery.push([key, value]);
          });
        }
      }
    });
    return new URLSearchParams(searchQuery);
  }, [params]);

  const value = useMemo(
    () => ({
      pageSizeQuery,
      pageQuery,
      setPageSizeQuery,
      setPageQuery,
      votingDeadlineQuery,
      administratorQuery,
      issuerQuery,
      titleQuery,
      isinsQuery,
      resultQuery,
      statusQuery,
      typeQuery,
      setFilter,
      setSorting,
      searchQuery,
      orderByQuery,
      orderDirectionQuery,
    }),
    [
      administratorQuery,
      isinsQuery,
      issuerQuery,
      orderByQuery,
      orderDirectionQuery,
      pageQuery,
      pageSizeQuery,
      resultQuery,
      searchQuery,
      setFilter,
      setSorting,
      statusQuery,
      titleQuery,
      typeQuery,
      votingDeadlineQuery,
    ]
  );

  return value;
};
