import { NavLink } from 'react-router-dom';
import { Toolbar as MToolbar } from '@mui/material';
import styled from 'styled-components';
import theme, { MARGIN } from 'theme/theme';
import Button from 'components/Button';

export const LogoLink = styled(NavLink)`
  padding: 2rem 2rem 0.5rem 0;
`;

export const Header = styled.header`
  background-color: ${theme.palette.common.black};
  color: ${theme.palette.common.white};
  margin-top: -0.3rem;
`;

export const MainMenuBox = styled.div`
  cursor: pointer;
`;

export const MainMenuLink = styled(NavLink)`
  text-transform: uppercase;
  padding: 1.5rem 1rem 20px 1rem;
  color: ${theme.palette.common.white};
  font-weight: 500;
  line-height: 20px;
  font-size: 0.8125rem;
  &.active {
    background-color: ${theme.palette.grey[800]};
    border-bottom: 4px solid ${theme.palette.common.white};
  }
`;

export const RightToolbar = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  & > button {
    color: white;
  }
  @media (max-width: 1500px) {
    & > button {
      padding: 0;
      margin-right: 1rem;
    }
  }
`;

export const HeaderToolbar = styled(MToolbar)`
  max-height: '64px';
  display: flex;
  justify-content: space-between;
  max-width: ${`${theme.breakpoints.values.xl}px`};
  margin: 0 auto;
  min-height: 4rem;
  box-sizing: border-box;
  &.MuiToolbar-gutters {
    padding: ${`0 ${MARGIN}px`};
  }
`;

export const LeftToolbar = styled(RightToolbar)`
  @media (min-width: 1501px) {
    & > button {
      display: none;
    }
  }
`;

interface Props {
  ['data-testid']: string;
}
export const MenuItem = styled(Button).attrs((props: Props) => {
  return { 'data-testid': props['data-testid'] };
})`
  &&.MuiButtonBase-root {
    width: 100%;
    height: 3rem;
    justify-content: flex-start;
    font-size: 0.8125rem;
  }
`;
