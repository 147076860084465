import { VoteIssueValidation } from './types';

export const isIsinRemovable = (
  validationData: VoteIssueValidation[],
  isin: string,
  id?: number
) => {
  if (id == null) return true;
  const voteIssueValidationData = validationData.find(
    (d) => +d.voteIssueID === id
  );

  return !voteIssueValidationData?.validatedIsins.find((u) => u.isin === isin)
    ?.votesExist;
};

export const canRemoveAllIsins = (
  validationData: VoteIssueValidation[],
  id?: number
) => {
  const voteIssueValidationData = validationData.find(
    (d) => +d.voteIssueID === id
  );
  const isinsWithVotes = voteIssueValidationData?.validatedIsins.filter(
    (validatedIsin) => validatedIsin.votesExist
  );
  return isinsWithVotes ? isinsWithVotes.length === 0 : true;
};

export const canDeleteVoteIssue = (
  validationData: VoteIssueValidation[],
  id?: number
) => {
  return (
    validationData.filter(
      (voteIssueValidationData: VoteIssueValidation) =>
        +voteIssueValidationData.voteIssueID === id &&
        voteIssueValidationData.votesExist
    ).length <= 0
  );
};
