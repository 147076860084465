
const shouldBeFraction = (value: string) => {
  if (value) {
    const [ n, d] =  value.split('/');
    const error = `${value} is not a valid fraction`;
    const nn = parseInt(n ?? '');
    const dn = parseInt(d ?? '');
    if(isNaN(nn) || isNaN(dn) || dn < nn) {
      return error;
    }
    const regex: RegExp = /^([^-]?[1-9][0-9]*)\/([^-]?[1-9]\d*)?$/;
    if(!regex.test(value)) return error;
  }
  return true;
};
const shouldBePercentage = (value: string) => {
  if(value){
    const error = `${value} is not a valid percentage`;
    const n = parseFloat(value);
    if(isNaN(n)) {
      return error;
    }
    const regex: RegExp = /^(100([,.]0{1,2})?|[1-9]?\d([,.]\d{1,2})?)%?$/;
    if(!regex.test(value)) return error;
  }
  return true;
};
const shouldBeEither = (value: string) => {
  const a1 = shouldBeFraction(value);
  const a2 = shouldBePercentage(value);
  const result = a1 === true || a2 === true;
  return result || 'The value must either be a fraction or a percentage';
};
export const rules = (percent = false, fraction = false) => ({
  ...(!percent && fraction && { shouldBeFraction }),
  ...(!fraction && percent && { shouldBePercentage }),
  ...(fraction && percent && { shouldBeEither }),
});