import { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { DoughnutChart } from '@nordictrustee/nt-ui-library';
import { useAuth } from 'context/AuthProvider';
import { StatisticsResultData, StatisticsResultDataAdvisor } from '../../types';
import GraphLabel from '../GraphLabel';
import VoteLegendItem from '../VoteLegendItem';
import { generateData, generateDataForAdvisor } from './utils';

interface Props {
  data?: StatisticsResultData | StatisticsResultDataAdvisor;
  majorityNumerator?: number;
  majorityDenominator?: number;
}

const ResultGraph = ({
  data = { Against: 0, NoVote: 0, InFavour: 0, Undisclosed: 0 },
  majorityDenominator,
  majorityNumerator,
}: Props) => {
  const { isInternalEmployeeRole } = useAuth();
  const generatedData = useMemo(() => {
    return isInternalEmployeeRole
      ? generateData(data as StatisticsResultData)
      : generateDataForAdvisor(data as StatisticsResultDataAdvisor);
  }, [data, isInternalEmployeeRole]);

  const isEmptyResult = useMemo(
    () => data && Object.values(data).every((i) => i === 0),
    [data],
  );
  if (isEmptyResult) {
    return (
      <Box mt={9}>
        <p style={{ textAlign: 'center' }}>No approved votes</p>
      </Box>
    );
  }
  const values = generatedData?.values;

  const chartName = generatedData?.chartLabel;
  const newData: DoughnutChart.ChartData[] =
    values &&
    values.map(
      (value): DoughnutChart.ChartData => ({
        ...value,
        tooltipStyle: 'percentage',
      }),
    );
  const threshold = majorityNumerator
    ? {
        name: 'Majority',
        numerator: majorityNumerator,
        denominator:
          majorityDenominator != null ? majorityDenominator : undefined,
      }
    : undefined;
  return (
    <DoughnutChart.Graph
      name={chartName}
      data={newData}
      threshold={threshold}
      legendItemComponent={VoteLegendItem}
      labelComponent={GraphLabel}
    />
  );
};

export default memo(ResultGraph);
