import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import EmailInput from 'components/Inputs/EmailInput';
import TextInput from 'components/Inputs/TextInput';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import * as api from '../../api';
import { User } from '../../types';

interface Props {
  handleClose: () => void;
  handleRefreshUsers: () => void;
  user?: User;
  advisorId: number;
}

const UserEditForm = ({
  handleClose,
  handleRefreshUsers,
  user,
  advisorId,
}: Props) => {
  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });

  const { isLoadingPutUser, putUser } = api.usePutUser();

  const fillFormFields = useCallback(() => {
      reset({
        ...user,
      });
  }, [reset, user]);

  useEffect(() => {
    fillFormFields();
  }, [fillFormFields]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { username, firstName, lastName } = data;
      if (user) {
        const id = user?.id;
        await putUser({ id, username, firstName, lastName, groupId: advisorId });
      } 
      handleRefreshUsers();
      toast.success('User has been saved');
      handleClose();
    } catch (e) {
      handleException(e as AxiosError);
    }
  });
  
  const handleConfirmClose = () => {
    confirmClose(isDirty, handleClose);
  };

  return (
    <Dialog
      onClose={handleConfirmClose}
      maxWidth="sm"
      title="Edit User"
      loading={false}
      data-testid="addUserDialog"
      dialogActions={
        <>
          <Button
            isLoading={isLoadingPutUser}
            data-testid="users-form-save-button"
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button
            data-testid="users-form-cancel-button"
            variant="outlined"
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
        </>
      }
    >
      <EmailInput
        control={control}
        errors={errors}
        data-testid="username"
        name="username"
        label="Username (Email)"
        autoFocus
        isRequired
      />
      { (
        <>
          <TextInput
            control={control}
            errors={errors}
            data-testid="firstName"
            name="firstName"
            label="First Name"
            required
          />
          <TextInput
            control={control}
            errors={errors}
            data-testid="lastName"
            name="lastName"
            label="Last Name"
            required
          />
        </>
      )}
    </Dialog>
  );
};

export default UserEditForm;
