import { CaseHandler } from 'utils/types/CaseHandler';
import { MeetingResult } from 'utils/types/MeetingResult';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { MeetingType } from 'utils/types/MeetingType';
import { TrusteeType } from 'utils/types/TrusteeType';
import { VoteIssueResult } from 'utils/types/VoteIssueResult';

interface CompanyFunction {
  id: string;
  name: string;
}

interface MeetingIssuer {
  id: string;
  name: string;
  companyFunction: CompanyFunction;
}

export interface MeetingDetails {
  id: string;
  title: string;
  meetingIssuer: MeetingIssuer;
  type: MeetingType;
  votingDeadline: string;
  caseHandler: CaseHandler;
  administrator: CaseHandler;
  result: MeetingResult;
  remark: string;
  status: MeetingStatus;
  statusChangedAt: string;
  statusChangedBy: string;
  recordDate: string;
  trustee: TrusteeType;
}

export interface VoteIssue {
  voteIssueID: string;
  matter: string;
  isins: string[];
  result: VoteIssueResult | null;
  hasCheckedVpsVotes: boolean;
  quorum?: number;
  majorityNumerator?: number;
  majorityDenominator?: number;
}
interface CompanyFunction {
  id: string;
  name: string;
}

interface MeetingIssuer {
  id: string;
  name: string;
  companyFunction: CompanyFunction;
}

export interface IndexedVoteIssue extends Omit<VoteIssue, 'voteIssueID'> {
  index: number;
  id: number;
}
export enum VoteOption {
  Yes = 'Yes',
  No = 'No',
  NoVote = 'NoVote',
}

export interface VoteDocument {
  id: number;
  name: string;
}

export enum VoteStatus {
  Approved = 'Approved',
  NotApproved = 'NotApproved',
  Deleted = 'Deleted',
}

export interface VoteDetails {
  id: number;
  meetingBondID: number;
  isin: string;
  bondholder: string;
  fundManagerCompany: string;
  custodian: string;
  amount: number;
  currency: string;
  weightedAmount: number;
  weightedAmountCurrency: string;
  result: VoteOption;
  documents: VoteDocument[];
  isVisible: boolean;
  isResultVisible: boolean;
  lastUpdatedAt: string; //date with time
  approvedBy: string | null;
  status: VoteStatus;
}
