import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DateInput from 'components/Inputs/DateInput';
import DateTimeInput from 'components/Inputs/DateTimeInput';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import useGetCaseHandlers from 'utils/hooks/useGetCaseHandlers';
import useGetCompanyFunctions from 'utils/hooks/useGetCompanyFunction';
import useGetMeetingTypeOptions from 'utils/hooks/useGetMeetingTypeOptions';
import { useGetTrusteeTypeOptions } from 'utils/hooks/useGetTrusteeOptions/api';
import { CompanyFunctionType } from 'utils/types/CompanyFunctionType';
import { TrusteeType } from 'utils/types/TrusteeType';
import { IsinAddFormFields } from '../types';

interface Props {
  setIsinsTableData: Dispatch<SetStateAction<IsinAddFormFields[]>>;
}

const BondholdersMeetingAddDetailsForm = ({ setIsinsTableData }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    meetingTypeOptions,
    isLoadingMeetingTypeOptions,
    getMeetingTypeOptions,
  } = useGetMeetingTypeOptions();

  const { caseHandlerOptions, isLoadingCaseHandlers, getCaseHandlers } =
    useGetCaseHandlers();

  const {
    trusteeTypeOptions,
    isLoadingTrusteeTypeOptions,
    getTrusteeTypeOptions,
  } = useGetTrusteeTypeOptions();

  const {
    companyFunctionOptions: issuerCompanyFunctionOptions,
    isLoadingCompanyFunctions: isLoadingIssuerCompanyFunctions,
    getCompanyFunctions: getIssuerCompanyFunctions,
  } = useGetCompanyFunctions(CompanyFunctionType.Issuer);

  useEffect(() => {
    getMeetingTypeOptions();
    getCaseHandlers();
    getIssuerCompanyFunctions();
    getTrusteeTypeOptions();
  }, [
    getCaseHandlers,
    getIssuerCompanyFunctions,
    getMeetingTypeOptions,
    getTrusteeTypeOptions,
  ]);

  const handleIssuerChange = useCallback(() => {
    setIsinsTableData([]);
  }, [setIsinsTableData]);

  const trustee: TrusteeType = useWatch({
    control,
    name: 'trustee',
  });

  return (
    <div data-testid="details-fields">
      <TextInput
        name="title"
        label="Title"
        control={control}
        errors={errors}
        required
        maxCharactersLength={200}
        autoFocus
      />
      <Select
        name="issuerID"
        label="Issuer"
        control={control}
        options={issuerCompanyFunctionOptions}
        errors={errors}
        required
        disabled={isLoadingIssuerCompanyFunctions}
        onChange={handleIssuerChange}
      />
      <Select
        name="type"
        label="Type"
        control={control}
        options={meetingTypeOptions}
        errors={errors}
        required
        disabled={isLoadingMeetingTypeOptions}
      />
      <DateTimeInput
        control={control}
        errors={errors}
        label="Voting Deadline"
        name="votingDeadline"
        required
      />
      <Select
        name="caseHandlerID"
        label="Case Handler"
        control={control}
        options={caseHandlerOptions}
        errors={errors}
        required
        disabled={isLoadingCaseHandlers}
      />
      <Select
        name="administratorID"
        label="Administrator"
        control={control}
        options={caseHandlerOptions}
        errors={errors}
        disabled={isLoadingCaseHandlers}
      />
      <Select
        name="trustee"
        label="Trustee"
        options={trusteeTypeOptions}
        control={control}
        errors={errors}
        disabled={isLoadingTrusteeTypeOptions}
        required
      />
      {trustee === TrusteeType.NordicTrusteeSE && (
        <DateInput
          label="Record Date"
          name="recordDate"
          control={control}
          errors={errors}
        />
      )}
    </div>
  );
};

export default BondholdersMeetingAddDetailsForm;
