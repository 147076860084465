import { Box, Container, Grid, Typography } from '@mui/material';
import Advisors from './components/Advisors';
import Users from './components/Users';

const UserManagement = () => {
  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="space-between" sx={{ pb: '24px' }}>
        <Typography variant="h2">Advisors</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md>
          <Advisors />
        </Grid>
        <Grid item xs={12} md>
          <Users />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserManagement;
