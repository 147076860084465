import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import EmailInput from 'components/Inputs/EmailInput';
import { User } from '../../../types';

type Props = {
  formContent?: User;  
};

export const UserFormUsernameStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { reset, control, formState: { errors } } = methods;

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);

  return (
    <EmailInput
      control={control}
      errors={errors}
      data-testid="username"
      name="username"
      label="Username (Email)"
      autoFocus
      isRequired
    />
  );
};

export default UserFormUsernameStep;
