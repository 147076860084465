import { DictionaryOption } from '../../types/Dictionary.types';
import { useAxios } from '../useAxios';

export const useGetUploadedFilesResultOptions = () => {
  const [
    {
      data: uploadedFilesResultOptions,
      loading: isLoadingUploadedFilesResultOptions,
    },
    getUploadedFilesResultOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: `/dictionaries/processing-status-type-options`,
    },
    { manual: true },
  );

  return {
    getUploadedFilesResultOptions,
    uploadedFilesResultOptions,
    isLoadingUploadedFilesResultOptions,
  };
};
