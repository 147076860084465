export const adjustedDateDisplayFormat = 'YYYY-MM-DD';
export const dateDisplayFormat = 'DD.MM.YYYY';
export const dateAndTimeDisplayFormat = 'DD.MM.YYYY HH:mm';
export const monthYearDisplayFormat = 'MM.YYYY';
export const currencyDecimalSeparator = ',';

export const rightText: React.CSSProperties = {
  textAlign: 'right',
  justifyContent: 'end',
};

export const centerText: React.CSSProperties = {
  textAlign: 'center',
  justifyContent: 'center',
};

export const columnRightText = {
  cellStyle: rightText,
  headerStyle: rightText,
};

// bignumber.js
export const currencyFormat = {
  decimalSeparator: '.',
  groupSeparator: ' ',
  groupSize: 3,
};

export const centerColumnText = {
  cellStyle: centerText,
  headerStyle: centerText,
};

export const columnWithUnsetTextOverflow = {
  cellStyle: {
    textOverflow: 'unset',
  },
};
