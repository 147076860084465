import React from 'react';
import { Typography } from '@mui/material';
import { DoughnutChart } from '@nordictrustee/nt-ui-library';
import styles from './VoteLegendItem.module.css';

const VoteLegendItem: DoughnutChart.LegendItem = ({ color, name }) => {
  return (
    <>
      <div className={styles.color} style={{ backgroundColor: color }} />
      <div className={styles.label}>
        <Typography variant="body2">{name}</Typography>
      </div>
    </>
  );
};

export default VoteLegendItem;
