import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import { useQuery } from 'utils/hooks/useQuery';
import AddDocumentsStep from './components/AddDocumentsStep';
import UploadVotesFileStep from './components/UploadVotesFileStep';
import VotesDocumentStepper from './components/VotesDocumentStepper';
import * as api from './api';

interface Props {
  onClose: () => void;
  votesFile?: any;
  setVotesFile: Dispatch<SetStateAction<File | undefined>>;
  meetingTitle: string;
}

const VotesDocumentForm = ({
  onClose,
  votesFile,
  setVotesFile,
  meetingTitle,
}: Props) => {
  const query = useQuery();
  const { push } = useHistory();

  const [voteDocuments, setVoteDocuments] = useState<File[]>([]);

  const [activeStep, setActiveStep] = useState(1);

  const form = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    trigger,
    setValue,
  } = form;

  const voteIssueIdFromQuery = useMemo(
    () => query.get('voteIssueId') || '',
    [query],
  );

  const { isLoadingPostValidation, validateVotesFile } =
    api.usePostVotesFileValidation(voteIssueIdFromQuery);

  const { isLoadingPostVotesAndAttachments, uploadVotesAndAttachments } =
    api.usePostVotesAndAttachments(voteIssueIdFromQuery);

  const onSubmit = handleSubmit(async () => {
    try {
      if (votesFile) {
        await uploadVotesAndAttachments(votesFile, voteDocuments);
      }
      toast.success('Documents have been uploaded');
      push(`/uploaded-files?page=0&pageSize=25&meetingName=${meetingTitle}`);
      onClose();
    } catch (e) {
      handleException(e);
    }
  });

  useEffect(() => {
    setVoteDocuments([]);
    setVotesFile(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const incrementStep = async () => {
    const isValid = await trigger();
    if (isValid && votesFile) {
      const validationResult = await validateVotesFile(votesFile);
      if (validationResult.status === 200) {
        setActiveStep((prevStep) => ++prevStep);
      }
    }
  };

  const decrementStep = useCallback(() => {
    setActiveStep((prevStep) => --prevStep);
  }, []);
  
  const handleConfirmClose = () => {
    confirmClose(isDirty, onClose);
  };

  return (
    <Dialog
      data-testid="multiple-votes-dialog"
      onClose={handleConfirmClose}
      title="Upload Votes"
      dialogActions={
        <>
        {activeStep === 2 && (
          <Button
            isLoading={isLoadingPostVotesAndAttachments}
            data-testid="save-button"
            onClick={onSubmit}
          >
            Save
          </Button>
        )}
        {activeStep === 1 && (
          <Button
            isLoading={isLoadingPostValidation}
            data-testid="save-continue-button"
            onClick={incrementStep}
          >
            Save & Continue
          </Button>
        )}
          <Button
            data-testid="cancel-button"
            variant="outlined"
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
          {activeStep === 2 && (
            <Button data-testid="back-button" onClick={decrementStep}>
              Back
            </Button>
          )}
        </>
      }
      subheader={<VotesDocumentStepper activeStep={activeStep} />}
    >
      <FormProvider {...form}>
        {activeStep === 1 && (
          <UploadVotesFileStep
            control={control}
            errors={errors}
            votesFile={votesFile}
            setVotesFile={setVotesFile}
            trigger={trigger}
            setValue={setValue}
          />
        )}
        {activeStep === 2 && (
          <AddDocumentsStep
            control={control}
            errors={errors}
            voteDocuments={voteDocuments}
            setVoteDocuments={setVoteDocuments}
            trigger={trigger}
          />
        )}
      </FormProvider>
    </Dialog>
  );
};

export default VotesDocumentForm;
