import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { BrowserRouter } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@nordictrustee/nt-ui-library';
import { AuthProvider } from 'context/AuthProvider';
import Routes from 'router/RoutesController';
import ToastProvider from 'components/ToastProvider';
import { Environment } from 'utils/types/Environment.types';
import reportWebVitals from './reportWebVitals';
import './index.css';

type Props = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: Props) => {
  useEffect(() => {
    const { ENVIRONMENT, GOOGLE_ANALYTICS_ID, REACT_APP_HOTJAR_ID } = window._env_;

    if (ENVIRONMENT && ENVIRONMENT !== Environment.prod) {
      const favicon = document.getElementById('favicon');
      favicon?.setAttribute('href', `/favicon-${ENVIRONMENT}.ico?v=1`);
    }
    
    if (GOOGLE_ANALYTICS_ID !== 'disabled' ) {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: document.location.pathname,
        title: document.title,
      });
    }

    if (REACT_APP_HOTJAR_ID !== 'disabled') {
      const hotjarSnippetVersion = 6;
      hotjar.initialize(REACT_APP_HOTJAR_ID, hotjarSnippetVersion);
    }

  }, []);

  return (
    <MsalProvider instance={pca}>
      <AuthProvider>
        <BrowserRouter>
          <ToastProvider />
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </MsalProvider>
  );
};

export default App;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
