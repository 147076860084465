import { ReactElement, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog as MDialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import * as S from './styles';

interface Props {
  title: string;
  children: ReactNode;
  loading?: boolean;
  onClose: () => void;
  dialogActions: ReactElement;
  subheader?: ReactElement;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  'data-testid'?: string; // TODO - make it required!
}

const Dialog = ({
  title,
  children,
  loading = false,
  onClose,
  dialogActions,
  subheader,
  maxWidth,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <MDialog open fullWidth maxWidth={maxWidth} data-testid={dataTestId}>
      <>
        {loading ? (
          <Box m={2} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mr={1}
            >
              <DialogTitle>{title}</DialogTitle>
              <IconButton onClick={onClose} data-testid="close-button">
                <CloseIcon />
              </IconButton>
            </Box>
            {subheader && (
              <DialogContent dividers sx={{ overflowY: 'visible' }}>
                {subheader}
              </DialogContent>
            )}
            <S.DialogContentWrapper>{children}</S.DialogContentWrapper>
            <S.DialogActions>{dialogActions}</S.DialogActions>
          </>
        )}
      </>
    </MDialog>
  );
};

export default Dialog;
