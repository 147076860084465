import { SelectProps } from 'react-select-material-ui';
import { CSSProperties } from '@mui/styles';
import theme from 'theme/theme';

export const getSelectStyles = (
  isErrorTooltipMessage?: boolean,
  isClearable?: boolean
): any => ({
  singleValue: (provided: CSSProperties): CSSProperties => ({
    ...provided,
    marginLeft: 0,
    border: 0,
    top: '65%',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    width: 'auto',
    minWidth: '100%',
    zIndex: 11,
  }),
  control: (_: CSSProperties, state: SelectProps) => ({
    borderBottom: state.isDisabled
      ? `1px solid ${theme.palette.grey[400]}`
      : `1px solid ${theme.palette.grey[600]}`,
  }),
  clearIndicator: (provided: CSSProperties) =>
    !isClearable
      ? { display: 'none' }
      : {
          ...provided,
          color: theme.palette.grey[400],
          '&:hover': {
            color: theme.palette.grey[600],
          },
        },
  valueContainer: () => ({
    '& > .MuiFormLabel-root': {
      maxWidth: 'calc(100% - 8px)',
      marginLeft: '2px',
      marginRight: '2px',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      top: '65%',
      transform: 'translateY(-50%)',
    },
    marginRight: '25px',
    paddingTop: '12px',
    '& > div > div > b': {
      fontWeight: 'normal',
    },
  }),
  placeholder: () => ({
    ...(isErrorTooltipMessage && { color: 'red' }),
  }),
  menuPortal: (provided: CSSProperties) => ({
    ...provided,
    zIndex: 9999,
  }),
  multiValueRemove: (
    provided: CSSProperties,
    state: { data: { isFixed: boolean } }
  ) => {
    return state.data.isFixed ? { ...provided, display: 'none' } : provided;
  },
});

export const getLabelColor = (isActive: boolean) => {
  if (isActive) {
    return theme.palette.primary.main;
  }
  return theme.palette.text.secondary;
};
