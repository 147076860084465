import { MeetingType } from 'utils/types/MeetingType';
import { TrusteeType } from '../../../../../utils/types/TrusteeType';
import { Document, DocumentType } from './types';

export const documentsData: Document[] = [
  {
    name: 'Minutes',
    type: DocumentType.MinutesBondholdersMeeting,
    meetingType: MeetingType.BondholderMeeting,
    trusteeType: TrusteeType.NordicTrusteeNO,
  },
  {
    name: 'Notice From Bondholder Meeting',
    type: DocumentType.NoticeBondholdersMeeting,
    meetingType: MeetingType.BondholderMeeting,
    trusteeType: TrusteeType.NordicTrusteeNO,
  },
  {
    name: 'Notice From Written Resolution',
    type: DocumentType.NoticeWrittenResolution,
    meetingType: MeetingType.WrittenResolution,
    trusteeType: TrusteeType.NordicTrusteeNO,
  },
  {
    name: 'Proxy',
    type: DocumentType.Proxy,
    meetingType: MeetingType.BondholderMeeting,
    trusteeType: TrusteeType.NordicTrusteeNO,
  },
  {
    name: 'Voting Form',
    type: DocumentType.VotingFormWrittenResolution,
    meetingType: MeetingType.WrittenResolution,
    trusteeType: TrusteeType.NordicTrusteeNO,
  },
  {
    name: 'Certificate Of Holdings',
    type: DocumentType.CertificateOfHoldings,
    meetingType: MeetingType.WrittenResolution,
    trusteeType: TrusteeType.NordicTrusteeSE,
  },
  {
    name: 'Notice Of Results, Amendments And Waivers',
    type: DocumentType.NoticeOfResultsAmendmentsAndWaivers,
    meetingType: MeetingType.WrittenResolution,
    trusteeType: TrusteeType.NordicTrusteeSE,
  },
  {
    name: 'Notice Of Results (Quorum Requirement Not Fulfilled)',
    type: DocumentType.NoticeOfResultsQuorumRequirementNotFulfilled,
    meetingType: MeetingType.WrittenResolution,
    trusteeType: TrusteeType.NordicTrusteeSE,
  },
  {
    name: 'Minutes From Written Procedure',
    type: DocumentType.MinutesFromWrittenProcedure,
    meetingType: MeetingType.WrittenResolution,
    trusteeType: TrusteeType.NordicTrusteeSE,
  },
].map((d, i) => ({ ...d, id: i + 1 }));
