import { useAxios } from 'utils/hooks/useAxios';
import { CaseHandler } from 'utils/types/CaseHandler';

export const useGetCaseHandlers = () => {
  const [
    { data: caseHandlers = [], loading: isLoadingCaseHandlers },
    getCaseHandlers,
  ] = useAxios<CaseHandler[]>(
    {
      url: '/case-handlers',
    },
    { manual: true },
  );
  return {
    caseHandlers,
    isLoadingCaseHandlers,
    getCaseHandlers,
  };
};
