import BigNumber from 'bignumber.js';
import { statisticsVotesColor as graphColor } from 'theme/theme';
import type {
  StatisticsResultData,
  StatisticsResultDataAdvisor,
} from '../../types';
import { fractionToPercent as fToP } from '../../utils';

const mapValues = (
  data: StatisticsResultData | StatisticsResultDataAdvisor,
  includeUndisclosed?: boolean,
) => {
  const { Against, NoVote, InFavour } = data || {};
  const Undisclosed = includeUndisclosed
    ? (data as StatisticsResultDataAdvisor).Undisclosed
    : 0;
  const againstValue = new BigNumber(Against ?? 0);
  const noVoteValue = new BigNumber(NoVote ?? 0);
  const inFavourValue = new BigNumber(InFavour ?? 0);
  const undisclosedValue = includeUndisclosed
    ? new BigNumber(Undisclosed)
    : undefined;

  const votesSum = againstValue
    .plus(noVoteValue)
    .plus(inFavourValue)
    .plus(undisclosedValue || 0);
  const values = [
    {
      name: 'In Favour',
      value: InFavour,
      label: fToP(inFavourValue, votesSum),
      color: graphColor.inFavour,
    },
    {
      name: 'Against',
      value: Against,
      label: fToP(againstValue, votesSum),
      color: graphColor.against,
    },
    {
      name: 'No Vote',
      value: NoVote,
      label: fToP(noVoteValue, votesSum),
      color: graphColor.noVote,
    },
  ];

  return undisclosedValue === undefined
    ? values
    : [
        ...values,
        {
          name: 'Undisclosed',
          value: Undisclosed,
          label: fToP(undisclosedValue, votesSum),
          color: graphColor.undisclosed,
        },
      ];
};
const chartLabel = 'Voting Results';
export const generateData = (data: StatisticsResultData) => ({
  chartLabel,
  values: mapValues(data),
});

export const generateDataForAdvisor = (data: StatisticsResultDataAdvisor) => ({
  chartLabel,
  values: mapValues(data, true),
});
