import { useCallback } from 'react';
import { useAxios } from 'utils/hooks/useAxios';
import { VoteDocument } from '../types';

export const useGetVoteDocuments = () => {
  const [, getVoteDocument] = useAxios<Blob>(
    {
      method: 'GET',
      responseType: 'blob',
    },
    {
      manual: true,
    },
  );

  const getDocument = useCallback(
    async (voteId: number, document: VoteDocument) => {
      const url = `/votes/${voteId}/documents/${document.id}`;

      const result = await getVoteDocument({ url });
      return new File([result.data as BlobPart], document.name);
    },
    [getVoteDocument],
  );
  return { getDocument };
};
