import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { Option } from 'react-select/src/filters';
import { SelectOption, SingleSelect } from 'react-select-material-ui';
import { checkIfErrors, helperText } from 'utils/reactHookFormUtils';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';
import { getLabelColor, getSelectStyles } from '../utils';

interface Props {
  name: string;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  options: string[] | (SelectOption & Partial<{ hidden?: boolean }>)[];
  label: string;
  isClearable?: boolean;
  required?: boolean;
  maxMenuHeight?: number;
  autoFocus?: boolean;
  onChange?: (value: any, option: any) => void;
  onInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  disabled?: boolean;
  validator?: (value: any) => string | boolean | undefined;
  'data-testid'?: string;
  isLoading?: boolean;
}

const Select = (props: Props) => {
  const error = checkIfErrors(props.name, props.errors);
  const isErrorTooltipMessage = error;
  const [active, setActive] = useState(false);

  return (
    <div>
      <Controller
        rules={{
          ...registerRequiredRule(props.required),
          validate: props.validator,
        }}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue ?? null}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <SingleSelect
            InputLabelProps={{
              style: {
                color: getLabelColor(active),
              },
            }}
            data-testid={props['data-testid']}
            disabled={(props.disabled || props.isLoading) ?? false}
            style={{ textTransform: 'capitalize' }}
            onInput={props.onInput}
            size="small"
            margin="dense"
            label={`${props.label}${props.required ? '*' : ''}`}
            name={name}
            onChange={(value, option) => {
              onChange(value);
              onBlur();
              if (props.onChange) {
                props.onChange(value, option);
              }
            }}
            onBlur={(_) => {
              onBlur();
            }}
            options={props.options}
            value={value}
            onFocusCapture={() => setActive(true)}
            onBlurCapture={() => setActive(false)}
            SelectProps={{
              //filter only by visible label for user (prevent filtering by value)
              filterOption: (option: Option, rawInput: string): boolean =>
                option.label?.toLowerCase().includes(rawInput.toLowerCase()),
              onKeyDown: (event: KeyboardEvent<HTMLElement>) => {
                //stopPropagation fixes handling ot ESC in select inside modal
                //ESC should close select dropdown menu and not the whole modal
                event.stopPropagation();
              },
              name: props.name,
              isValidNewOption: (inputValue: string) => inputValue !== '',
              isClearable: props.isClearable,
              menuPlacement: 'bottom',
              menuPortalTarget: document.body,
              maxMenuHeight: props.maxMenuHeight,
              autoFocus: props.autoFocus,
              styles: getSelectStyles(isErrorTooltipMessage, props.isClearable),
              isLoading: props.isLoading,
            }}
          />
        )}
      />
      {/* handles required error only! */}
      <ErrorLabel data-testid="error-label">
        {helperText(props.name, props.errors)}
      </ErrorLabel>
    </div>
  );
};

export default Select;
