import React, { KeyboardEvent } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { registerMaxLength } from 'utils/registerMaxLength';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';

interface Props {
  'data-testid': string;
  name: string;
  label: string;
  control: Control;
  errors: DeepMap<object, FieldError>;
  isRequired?: boolean;
  hideRequiredStar?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onKeyUp?: (data: KeyboardEvent<HTMLElement>) => void;
}

const EmailInput = ({
  'data-testid': dataTestId,
  name,
  label,
  control,
  errors,
  isRequired,
  hideRequiredStar,
  autoFocus,
  disabled,
  onKeyUp,
}: Props) => {
  const required = isRequired && !hideRequiredStar ? '*' : '';
  return (
    <>
      <Controller
        defaultValue=""
        name={name ?? ''}
        render={({ field: { onChange, value, onBlur } }) => (
          <TextField
            autoFocus={autoFocus}
            label={`${label}${required}`}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            value={value}
            disabled={disabled}
            inputProps={{
              'data-testid': dataTestId,
            }}
            size="small"
            margin={'dense'}
            fullWidth
          />
        )}
        control={control}
        rules={{
          ...registerRequiredRule(isRequired),
          pattern: {
            value: /^\S+@\S+\.\S+$/i,
            message: 'Invalid email',
          },
          ...registerMaxLength(100),
        }}
      />
      {errors && (
        <ErrorLabel data-testid="error-label">
          {helperText(name, errors)}
        </ErrorLabel>
      )}
    </>
  );
};

export default EmailInput;
