import { FC, useEffect, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import {
  SearchableMultiSelectFilter,
  Table,
} from '@nordictrustee/nt-ui-library';
import * as URL from 'router/url';
import { CENTER_COLUMN } from 'components/Table/const';
import TableLink from 'components/TableLink';
import HeaderTextWithTooltip from 'components/VirtualizedTable/HeaderTextWithTooltip';
import { getInitLocalDateTime } from 'utils/formatters/dateFormatter';
import { getAcronym } from 'utils/formatters/textFormatter';
import { getLookup } from 'utils/getLookUpFromOptions';
import useGetMeetingResultOptions from 'utils/hooks/useGetMeetingResultOptions';
import useGetMeetingTypeOptions from 'utils/hooks/useGetMeetingTypeOptions';
import { getCompareDates } from 'utils/sortCompares';
import { PageableResponse } from 'utils/types/PageableResponse.types';
import StatusChip from '../Meeting/StatusChip';
import * as api from './api';
import { BondholdersMeeting, MeetingQuery } from './types';
import { getChipList, getResultChip } from './utils';

type Props = {
  meetingQuery: Omit<MeetingQuery, 'searchQuery'>;
  meetings?: PageableResponse<BondholdersMeeting[]>;
  isLoadingMeetings?: boolean;
  isInternalEmployee?: boolean;
};
export const MeetingsList: FC<Props> = ({
  meetingQuery,
  meetings,
  isLoadingMeetings,
  isInternalEmployee,
}) => {
  const {
    pageQuery,
    setPageQuery,
    pageSizeQuery,
    setPageSizeQuery,
    administratorQuery,
    isinsQuery,
    issuerQuery,
    titleQuery,
    resultQuery,
    statusQuery,
    typeQuery,
    votingDeadlineQuery,
    orderByQuery,
    orderDirectionQuery,
    setFilter,
    setSorting,
  } = meetingQuery;

  const {
    getMeetingResultOptions,
    meetingResultOptions,
    isLoadingMeetingResultOptions,
  } = useGetMeetingResultOptions();

  const {
    getMeetingStatusOptions,
    meetingStatusOptions,
    isLoadingMeetingStatusOptions,
  } = api.useGetMeetingStatusOptions();

  const {
    getMeetingTypeOptions,
    meetingTypeOptions,
    isLoadingMeetingTypeOptions,
  } = useGetMeetingTypeOptions();

  useEffect(() => {
    getMeetingResultOptions();
    getMeetingStatusOptions();
    getMeetingTypeOptions();
  }, [getMeetingResultOptions, getMeetingStatusOptions, getMeetingTypeOptions]);

  const columns: Table.Column<BondholdersMeeting>[] = useMemo(
    () =>
      (
        [
          {
            title: 'Voting Deadline',
            field: 'votingDeadline',
            width: '11%',
            type: 'string',
            defaultFilter: votingDeadlineQuery,
            customSort: (a: BondholdersMeeting, b: BondholdersMeeting) =>
              getCompareDates(a.votingDeadline, b.votingDeadline),
            ...CENTER_COLUMN,
          },
          isInternalEmployee && {
            title: (
              <HeaderTextWithTooltip tooltipTitle="Administrator - (Filter by full name)">
                Admin.
              </HeaderTextWithTooltip>
            ),
            field: 'administrator',
            width: '7%',
            render: ({ administrator }) => {
              let admin =
                administrator != null
                  ? getAcronym(administrator.replace('(Inactive)', ''))
                  : '';
              return (
                <Tooltip title={administrator}>
                  <div>{admin}</div>
                </Tooltip>
              );
            },
            defaultFilter: administratorQuery,
            ...CENTER_COLUMN,
          },
          {
            title: 'Issuer',
            field: 'issuer',
            width: '19%',
            type: 'string',
            defaultFilter: issuerQuery,
          },
          {
            title: 'Meeting',
            field: 'title',
            width: '20%',
            type: 'string',
            defaultFilter: titleQuery,
          },
          {
            title: 'ISINs',
            field: 'isins',
            width: '20%',
            render: ({ isins }) => getChipList(isins),
            defaultFilter: isinsQuery,
          },
          {
            title: 'Type',
            field: 'type',
            width: '9%',
            render: ({ type }) => {
              if (meetingTypeOptions) {
                return meetingTypeOptions.find(
                  (option) => option.value === type
                )?.label;
              }
              return '';
            },
            type: 'string',
            filterComponent: SearchableMultiSelectFilter,
            defaultFilter: typeQuery,
            lookup: getLookup(meetingTypeOptions),
          },
          {
            title: 'Result',
            field: 'result',
            width: '7%',
            render: ({ result }) => result && getResultChip(result),
            filterComponent: SearchableMultiSelectFilter,
            defaultFilter: resultQuery,
            lookup: getLookup(meetingResultOptions),
            ...CENTER_COLUMN,
          },
          {
            title: 'Status',
            field: 'status',
            width: '7%',
            render: ({ status }) => <StatusChip status={status} />,
            filterComponent: SearchableMultiSelectFilter,
            defaultFilter: statusQuery,
            lookup: getLookup(meetingStatusOptions),
            ...CENTER_COLUMN,
          },
        ] as Table.Column<BondholdersMeeting>[]
      ).map((column) => ({
        ...column,
        // magic sorting fixture:
        ...(column &&
          orderByQuery === column.field && {
            defaultSort: orderDirectionQuery,
          }),
        render: (row) => (
          <TableLink
            noPadding
            display="block"
            to={`${URL.BONDHOLDERS_MEETINGS}/${row.id}`}
          >
            {column.render
              ? column.render(row, 'row')
              : row[column.field as keyof BondholdersMeeting]}
          </TableLink>
        ),
      })) as Table.Column<BondholdersMeeting>[],
    [
      administratorQuery,
      isInternalEmployee,
      isinsQuery,
      issuerQuery,
      meetingResultOptions,
      meetingStatusOptions,
      meetingTypeOptions,
      orderByQuery,
      orderDirectionQuery,
      resultQuery,
      statusQuery,
      titleQuery,
      typeQuery,
      votingDeadlineQuery,
    ]
  );

  const tableOptions: Table.Options<BondholdersMeeting> = useMemo(() => {
    return {
      sorting: true,
      filtering: true,
      minBodyHeight: '75vh',
      maxBodyHeight: '75vh',
      pageSize: pageSizeQuery,
      toolbar: false,
    };
  }, [pageSizeQuery]);

  const isLoading = useMemo(
    () =>
      isLoadingMeetings ||
      isLoadingMeetingResultOptions ||
      isLoadingMeetingStatusOptions ||
      isLoadingMeetingTypeOptions,
    [
      isLoadingMeetingResultOptions,
      isLoadingMeetingStatusOptions,
      isLoadingMeetingTypeOptions,
      isLoadingMeetings,
    ]
  );

  const meetingResult = useMemo(
    () =>
      meetings?.results.map((result) => ({
        ...result,
        votingDeadline: getInitLocalDateTime(result.votingDeadline),
      })),
    [meetings?.results]
  );

  return (
    <Table.Root
      columns={columns}
      data={meetingResult || []}
      options={tableOptions}
      isLoading={isLoading}
      onOrderChange={setSorting}
      onFilterChange={setFilter}
      onPageChange={setPageQuery}
      onRowsPerPageChange={setPageSizeQuery}
      page={pageQuery}
      totalCount={meetings?.objectsCount ?? 0}
      disableDefaultSearchAndFilter
    />
  );
};
