import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAuth } from 'context/AuthProvider';
import { getInitLocalDateTime } from 'utils/formatters/dateFormatter';
import { useQuery } from 'utils/hooks/useQuery';
import { RouteParam } from 'utils/types/RouteParam';
import { VoteIssuesProvider } from './MattersToBeDecided/Context';
import * as api from './api';
import Details from './Details';
import Documents from './Documents';
import IsinsAndAdvisors from './IsinsAndAdvisors';
import { IndexedVoteIssue, MattersToBeDecided } from './MattersToBeDecided';
import { ScreenHeader } from './ScreenHeader';
import Statistics from './Statistics';
import VotesDetails from './Votes';
import classes from './Meeting.module.css';

export const Meeting = () => {
  const { bondholdersMeetingId } = useParams<RouteParam>();
  const { isInternalEmployeeRole } = useAuth();

  const { meetingDetails, isLoadingMeetingDetails, getMeetingDetails } =
    api.useGetMeetingDetails(bondholdersMeetingId);

  useEffect(() => {
    getMeetingDetails();
  }, [getMeetingDetails]);

  const { isinsData, isLoadingIsinsData, getIsinsData } =
    api.useGetIsinsData(bondholdersMeetingId);

  const { voteIssues, isLoadingVoteIssues, getVoteIssues } =
    api.useGetVoteIssues(bondholdersMeetingId);

  const isinsAssignedToVoteIssues = voteIssues.flatMap(
    (item: { isins: string[] }) => item.isins.map((isin) => isin),
  );
  const uniqueIsinsAssignedToVoteIssues = isinsAssignedToVoteIssues.filter(
    (v, i, a) => a.indexOf(v) === i,
  );

  const details = useMemo(
    () =>
      meetingDetails
        ? {
            ...meetingDetails,
            votingDeadline: getInitLocalDateTime(
              meetingDetails?.votingDeadline,
            ),
            statusChangedAt: getInitLocalDateTime(
              meetingDetails?.statusChangedAt,
            ),
            recordDate: getInitLocalDateTime(meetingDetails?.recordDate),
          }
        : undefined,
    [meetingDetails],
  );
  const query = useQuery();

  const voteIssueIdFromQuery = useMemo(
    () => query.get('voteIssueId') || '',
    [query],
  );

  const indexedVoteIssues: IndexedVoteIssue[] = voteIssues.map(
    ({ voteIssueID, ...voteIssue }, index) => {
      return { ...voteIssue, index: index + 1, id: parseInt(voteIssueID, 10) };
    },
  );

  const selectedVoteIssue = indexedVoteIssues.find(
    (v) => v.id?.toString() === voteIssueIdFromQuery,
  );

  const matterToBeDecided = useMemo(() => {
    return selectedVoteIssue
      ? `${selectedVoteIssue.index}. ${selectedVoteIssue.matter}`
      : '';
  }, [selectedVoteIssue]);

  return (
    <>
      <ScreenHeader
        votingDeadline={details?.votingDeadline}
        voteIssues={voteIssues}
        status={details?.status}
        title={details?.title}
        statusChangedAt={details?.statusChangedAt}
        statusChangedBy={details?.statusChangedBy}
        getMeetingDetails={getMeetingDetails}
        meetingType={details?.type}
      />
      <Grid container spacing={2}>
        <Grid item xs md={3} className={classes.limitedWidth}>
          <Details
            meetingDetails={details!}
            isLoading={isLoadingMeetingDetails}
            getMeetingDetails={getMeetingDetails}
            hasIsins={isinsData.length > 0}
          />
        </Grid>
        {isInternalEmployeeRole && (
          <Grid item xs md={9} lg={3} className={classes.limitedWidth}>
            <Documents
              numberOfIsins={isinsData.length}
              meetingType={details?.type}
              trusteeType={details?.trustee}
              isLoadingDetails={isLoadingMeetingDetails}
            />
          </Grid>
        )}
        <Grid item xs lg={isInternalEmployeeRole ? 6 : 9}>
          <IsinsAndAdvisors
            status={details?.status}
            issuerID={details?.meetingIssuer.id}
            isinsData={isinsData}
            isLoadingIsinsData={isLoadingIsinsData}
            getIsinsData={getIsinsData}
            isinsAssignedToVoteIssues={uniqueIsinsAssignedToVoteIssues}
            trustee={details?.trustee}
          />
        </Grid>

        <Grid item xs md={5}>
          <VoteIssuesProvider>
            <MattersToBeDecided
              status={details?.status}
              indexedVoteIssues={indexedVoteIssues}
              isLoadingVoteIssues={isLoadingVoteIssues}
              getVoteIssues={getVoteIssues}
              trusteeType={details?.trustee}
              meetingType={details?.type}
            />
          </VoteIssuesProvider>
        </Grid>
        <Grid item xs lg={7}>
          <Statistics
            voteData={selectedVoteIssue}
            votingBonds={isinsData.filter(
              (isinItem) => selectedVoteIssue?.isins.includes(isinItem.isin),
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VotesDetails
            status={details?.status}
            matterToBeDecided={matterToBeDecided}
            meetingTitle={details?.title ?? ''}
          />
        </Grid>
      </Grid>
    </>
  );
};
