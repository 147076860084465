import DoneIcon from '@mui/icons-material/Done';
import { Avatar, AvatarProps } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme/theme';
import Button from 'components/Button';

export const StepButton = styled(Button)`
  && {
    color: black;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: normal;
  }
`;

export const StepBadge = styled(Avatar)<AvatarProps & { isPrimary: boolean }>`
  && {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    font-size: 10px;
    background-color: ${({ isPrimary }) =>
      isPrimary ? theme.palette.primary.main : theme.palette.grey[300]};
  }
`;

export const DoneStepIcon = styled(DoneIcon)`
  && {
    font-size: 10px;
  }
`;

export const Content = styled.div`
  min-height: 550px;
`;
