import { useCallback } from 'react';
import { useAxios } from 'utils/hooks/useAxios';
import { VoteDocument } from '../../types';
import { VoteFields } from './types';

export const usePostVote = (voteIssueId: string) => {
  const [{ loading: isLoadingPostVote }, postVote] = useAxios(
    { url: `/vote-issues/${voteIssueId}/votes`, method: 'POST' },
    {
      manual: true,
    },
  );
  return { isLoadingPostVote, postVote };
};

export const usePutVote = (voteIssueId: string) => {
  const [{ loading: isLoadingPutVote }, putVote] = useAxios(
    { method: 'PUT' },
    {
      manual: true,
    },
  );
  const updateVote = useCallback(
    async (voteId: number, data: VoteFields) => {
      const url = `/vote-issues/${voteIssueId}/votes/${voteId}`;
      return await putVote({ url, data });
    },
    [putVote, voteIssueId],
  );
  return { isLoadingPutVote, updateVote };
};

export const usePutVoteDocuments = () => {
  const [{ loading: isLoadingPutVoteDocuments }, putVoteDocuments] = useAxios(
    {
      method: 'PUT',
    },
    {
      manual: true,
    },
  );

  const uploadVoteDocuments = useCallback(
    async (voteId: number, data: File[]) => {
      let formData = new FormData();
      data.map((file) => formData.append('files', file as Blob));
      const url = `/votes/${voteId}/documents`;
      return await putVoteDocuments({ url, data: formData });
    },
    [putVoteDocuments],
  );
  return { isLoadingPutVoteDocuments, uploadVoteDocuments };
};

export const useGetVoteDocuments = () => {
  const [, getVoteDocument] = useAxios<Blob>(
    {
      method: 'GET',
      responseType: 'blob',
    },
    {
      manual: true,
    },
  );

  const getDocument = useCallback(
    async (voteId: number, document: VoteDocument) => {
      const url = `/votes/${voteId}/documents/${document.id}`;

      const result = await getVoteDocument({ url });
      return new File([result.data as BlobPart], document.name);
    },
    [getVoteDocument],
  );
  return { getDocument };
};

export const useDeleteVoteDocument = () => {
  const [, deleteVoteDocument] = useAxios(
    {
      method: 'DELETE',
    },
    {
      manual: true,
    },
  );

  const deleteDocument = useCallback(
    async (voteId: number, documentId: number) => {
      const url = `/votes/${voteId}/documents/${documentId}`;

      return await deleteVoteDocument({ url });
    },
    [deleteVoteDocument],
  );

  return { deleteDocument };
};
