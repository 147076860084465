import { useAxios } from 'utils/hooks/useAxios';
import { Role } from './types';

interface Me {
  userExternalID: string;
  roles: Role[];
  termsAndConditionsAcceptedDate?: string;
}

export const useGetMe = () => {
  const [{ data: me, loading: isLoadingMe }, getMe] = useAxios<Me>({
    url: '/me',
  });
  return {
    me,
    isLoadingMe,
    getMe,
  };
};
