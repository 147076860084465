import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { InputAdornment, TextField } from '@mui/material';

interface Props {
  columnDef: {
    title: string | JSX.Element;
    tableData?: {
      id: string;
      filterValue: string[];
    };
    lookup?: object;
    field: string;
  };
  onFilterChanged: (id: string, value: string) => void;
}

export const TableFilterInput: React.FC<Props> = ({
  columnDef,
  onFilterChanged,
}) => {
  return (
    <TextField
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterListIcon />
          </InputAdornment>
        ),
        'aria-label': `filter data by ${
          typeof columnDef.title === 'string'
            ? columnDef.title
            : columnDef.title.props.tooltipTitle
        }`,
      }}
      onChange={(event) => {
        onFilterChanged(columnDef?.tableData?.id ?? '', event.target.value);
      }}
    />
  );
};
