import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import TextInput from 'components/Inputs/TextInput';
import { User } from '../../../types';

interface Props {
  formContent?: User;
}

const UserFormInfoStep = ({ formContent }: Props) => {
  const methods = useFormContext();
  const { formState: { errors }, control, reset } = methods;

  useEffect(() => {
    reset(formContent);
  }, [formContent, reset]);
  
  return (
    <>
    <TextInput
      control={control}
      errors={errors}
      data-testid="firstName"
      name="firstName"
      label="First Name"
      required
      autoFocus
    />
    <TextInput
      control={control}
      errors={errors}
      data-testid="lastName"
      name="lastName"
      label="Last Name"
      required
    />
    </>
  );
};

export default UserFormInfoStep;
