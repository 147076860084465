import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { RouteParam } from 'utils/types/RouteParam';
import { Advisor } from '../types';
import AdvisorsFormAddRow from './AdvisorsFormAddRow';
import AdvisorsFormTable from './AdvisorsFormTable';
import * as api from './api';
import { AdvisorFormFields, AdvisorFormType, AdvisorWithUsers } from './types';

interface Props {
  onClose: () => void;
  data: AdvisorFormFields[];
  getAdvisorsData: AxiosPromiseGeneric<Advisor[]>;
}

const AdvisorsForm = ({ onClose, data, getAdvisorsData }: Props) => {
  const { bondholdersMeetingId } = useParams<RouteParam>();
  const [advisorsData, setAdvisorsData] = useState<AdvisorFormFields[]>(data);
  const [advisorsWithUsers, setAdvisorsWithUsers] = useState<
    AdvisorWithUsers[]
  >([]);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
    getValues,
  } = useForm<AdvisorFormType>({ mode: 'onSubmit' });

  const { putAdvisorsData } = api.usePutAdvisorsData(bondholdersMeetingId);

  const getCurrentFormValues = () => {
    const currentValues = getValues();
    let currentValuesArray: AdvisorFormFields[] = [];
    Object.keys(currentValues).forEach((key) => {
      currentValuesArray.push({
        ...currentValues[key as unknown as number],
      });
    });

    return currentValuesArray;
  };

  const onSubmit = handleSubmit(async () => {
    try {
      const data = getCurrentFormValues().map(({ companyID, users }) => ({
        companyID: companyID,
        users: users.map((userID) => ({ userID })),
      }));

      await putAdvisorsData({ data });
      onClose();
      toast.success('Meeting advisors have been saved');
      getAdvisorsData();
    } catch (e) {
      handleException(e);
    }
  });

  const formData = useMemo(
    () =>
      advisorsData.reduce(
        (obj: AdvisorFormType, item: AdvisorFormFields) => ({
          ...obj,
          [item.index]: {
            id: item.id,
            companyID: item.companyID,
            companyName: item.companyName,
            users: item.users,
            index: item.index,
          },
        }),
        {}
      ),
    [advisorsData]
  );

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const handleConfirmClose = () => {
    confirmClose(isFormDirty, onClose);
  };

  return (
    <Dialog
      title="Edit Advisors"
      data-testid="advisors-dialog"
      onClose={handleConfirmClose}
      maxWidth="md"
      dialogActions={
        <>
          <Button data-testid="save-button" onClick={onSubmit}>
            Save
          </Button>
          <Button
            data-testid="cancel-button"
            variant="outlined"
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
        </>
      }
    >
      <AdvisorsFormAddRow
        advisorsData={advisorsData}
        setAdvisorsData={setAdvisorsData}
        getCurrentFormValues={getCurrentFormValues}
        setIsFormDirty={setIsFormDirty}
      />
      <Box mt={2}>
        <AdvisorsFormTable
          advisorsData={advisorsData}
          setAdvisorsData={setAdvisorsData}
          control={control}
          errors={errors}
          setAdvisorsWithUsers={setAdvisorsWithUsers}
          advisorsWithUsers={advisorsWithUsers}
          getCurrentFormValues={getCurrentFormValues}
        />
      </Box>
    </Dialog>
  );
};

export default AdvisorsForm;
