import { ReactText } from 'react';

export const numberFormatter = (
  amount: number,
  options?: Intl.NumberFormatOptions,
) => amount.toLocaleString('nb-NO', options);

export const decimalNumberFormatter = (
  value?: ReactText | null,
  reverted = false,
): string => {
  if (typeof value === 'undefined' || value === null) return '';
  if (value === '-') return '0';
  return reverted
    ? `${String(value).replace(',', '.')}`
    : `${String(value).replace('.', ',')}`;
};
