import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import Button from 'components/Button';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import useGetCompanyAdvisors from 'utils/hooks/useGetCompanyAdvisors';
import useGetCompanyFunctions from 'utils/hooks/useGetCompanyFunction';
import { CompanyFunctionType } from 'utils/types/CompanyFunctionType';
import { AdvisorFormFields, FormFields } from '../types';

interface Props {
  advisorsData: AdvisorFormFields[];
  setAdvisorsData: Dispatch<SetStateAction<AdvisorFormFields[]>>;
  getCurrentFormValues: () => AdvisorFormFields[];
  setIsFormDirty: Dispatch<SetStateAction<boolean>>;
}

const AdvisorsFormAddRow = ({
  advisorsData,
  setAdvisorsData,
  getCurrentFormValues,
  setIsFormDirty,
}: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const {
    companyFunctionOptions: advisorCompanyFunctionOptions,
    isLoadingCompanyFunctions: isLoadingAdvisorCompanyFunctionOptions,
    getCompanyFunctions: getAdvisorCompanyFunctions,
  } = useGetCompanyFunctions(CompanyFunctionType.Advisor);

  useEffect(() => {
    getAdvisorCompanyFunctions();
  }, [getAdvisorCompanyFunctions]);

  const {
    companyAdvisorsOptions,
    getCompanyAdvisors,
    isLoadingCompanyAdvisors,
  } = useGetCompanyAdvisors();

  const [advisorUsersOptions, setAdvisorUsersOptions] = useState(
    companyAdvisorsOptions
  );

  const filteredCompanyOptions = useMemo(() => {
    return advisorCompanyFunctionOptions.filter(
      ({ value }) =>
        !advisorsData.map(({ companyID }) => companyID).includes(Number(value))
    );
  }, [advisorCompanyFunctionOptions, advisorsData]);

  const handleSelectCompany = (companyId: number) => {
    setValue('users', []);
    if (companyId != null) {
      getCompanyAdvisors(companyId);
    } else {
      setAdvisorUsersOptions([]);
    }
  };

  useEffect(() => {
    setAdvisorUsersOptions(companyAdvisorsOptions);
  }, [companyAdvisorsOptions]);

  const addAdvisorsData = handleSubmit(async () => {
    const { companyID, users } = getValues();
    const nextIndex =
      advisorsData && advisorsData.length > 0
        ? Math.max(...advisorsData.map((item) => item.index), 0) + 1
        : 1;

    const prevValues = getCurrentFormValues();
    setAdvisorsData(() => [
      ...prevValues,
      {
        index: nextIndex,
        id: -1,
        companyID: companyID,
        companyName:
          advisorCompanyFunctionOptions?.find(
            ({ value }: any) => Number(value) === companyID
          )?.label ?? '',
        users: advisorUsersOptions
          ?.filter(({ value }) => users.includes(value))
          .map(({ value }) => {
            return value;
          }),
      },
    ]);

    setValue('companyID', null);
    setValue('users', []);
    setAdvisorUsersOptions([]);
    setIsFormDirty(true);
  });

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={4}>
        <Select
          name="companyID"
          isClearable
          label="Advisor Company"
          required
          options={filteredCompanyOptions}
          onChange={handleSelectCompany}
          control={control}
          errors={errors}
          autoFocus
          isLoading={isLoadingAdvisorCompanyFunctionOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <MultiSelect
          name="users"
          isClearable
          label="Advisor Users"
          required
          disabled={isLoadingCompanyAdvisors}
          options={advisorUsersOptions}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item>
        <Button
          data-testid="add-button"
          onClick={addAdvisorsData}
          variant="outlined"
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

export default AdvisorsFormAddRow;
