import { memo, useMemo } from 'react';
import { Table } from '@nordictrustee/nt-ui-library';
import theme from 'theme/theme';
import { columnRightText } from 'utils/constants';
import {
  getConvertedIssuerBondAmountSum,
  getConvertedOutstandingAmountSum,
  getConvertedVotingBondsSum,
} from '../../../utils';
import { IsinItem } from '../types';
import { getFormattedAmountWithCurrency } from '../utils';
import { IsinsSummary } from './types';

const columns: Table.Column<IsinsSummary>[] = [
  {
    title: 'Sum',
    field: 'sum',
    render: () => 'Sum',
    width: '15%',
    disableClick: true,
  },
  {
    title: 'convertedOutstandingAmountSum',
    field: 'convertedOutstandingAmountSum',
    render: ({ convertedOutstandingAmountSum, currency }) =>
      getFormattedAmountWithCurrency(convertedOutstandingAmountSum, currency),
    disableClick: true,
    ...columnRightText,
  },
  {
    title: 'convertedIssuerBondAmountSum',
    field: 'convertedIssuerBondAmountSum',
    render: ({ convertedIssuerBondAmountSum, currency }) =>
      getFormattedAmountWithCurrency(convertedIssuerBondAmountSum, currency),
    disableClick: true,
    ...columnRightText,
  },
  {
    title: 'convertedVotingBondsSum',
    field: 'convertedVotingBondsSum',
    render: ({ convertedVotingBondsSum, currency }) =>
      getFormattedAmountWithCurrency(convertedVotingBondsSum, currency),
    disableClick: true,
    ...columnRightText,
  },
];

interface Props {
  data: IsinItem[];
}

const SummaryRow = ({ data }: Props) => {
  const convertedOutstandingAmountSum = useMemo(
    () => getConvertedOutstandingAmountSum(data),
    [data]
  );

  const convertedIssuerBondAmountSum = useMemo(
    () => getConvertedIssuerBondAmountSum(data),
    [data]
  );

  const convertedVotingBondsSum = useMemo(
    () => getConvertedVotingBondsSum(data),
    [data]
  );

  return (
    <Table.Root
      style={{
        borderTop: `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        height: '3rem',
        overflow: 'visible',
      }}
      columns={columns}
      data={[
        {
          id: 1,
          convertedOutstandingAmountSum,
          convertedIssuerBondAmountSum,
          convertedVotingBondsSum,
          currency: (data && data[0]?.conversionCurrency) ?? '',
        },
      ]}
      options={{
        toolbar: false,
        paging: false,
        header: false,
        rowStyle: {
          color: theme.palette.text.secondary,
          height: '3rem',
          padding: 'auto',
        },
      }}
    />
  );
};
export default memo(SummaryRow);
