import type { TextInputControllerProps } from '../TextInput';
import TextInputController from '../TextInput';
import { rules } from './rules';

export interface Props extends TextInputControllerProps {
  percent?: boolean;
  fraction?: boolean;
}
const FractionInput = ({ percent, fraction, ...props }: Props) => {
  const r = rules(percent, fraction);
  return <TextInputController {...props} rules={r} />;
};
export default FractionInput;
