import { useMemo } from 'react';
import { useQuery } from 'utils/hooks/useQuery';

export function useRedirectURL() {
  const query = useQuery();
 const redirectTo = useMemo(
   () => query.get('redirectTo') || '',
   [query],
 );

 return redirectTo;
}