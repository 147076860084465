import { Grid } from '@mui/material';
import styled from 'styled-components';

export const ColumnGrid = styled(Grid)`
  padding-top: 0.8rem;
`;

export const Conversion = styled.div`
  padding-top: 0.8rem;
`;
