import { Box, Typography } from '@mui/material';

interface Props {
  header?: string;
  children: any;
}

const TextParagraph = ({ header, children }: Props) => {
  return (
    <Box mb={4}>
      {header && (
        <Box mb={1}>
          <Typography variant="h4">{header}</Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default TextParagraph;
