import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  message: string;
  height: string;
}

const BoxEmpty = ({ message, height }: Props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height={height}
  >
    <Typography variant="h5">{message}</Typography>
  </Box>
);

export default BoxEmpty;
