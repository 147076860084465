import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Grid } from '@mui/material';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import NumberInput from 'components/Inputs/NumberInput';
import Select from 'components/Inputs/Select';
import { decimalNumberFormatter } from 'utils/formatters/numberFormatter';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import useGetCurrencyOptions from 'utils/hooks/useGetCurrencyOptions';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { RouteParam } from 'utils/types/RouteParam';
import { IndexedIsinItem, IsinItem } from '../types';
import * as api from './api';
import IsinsFormTable from './IsinsFormTable';
import * as S from './styles';
import {
  IsinFormFields,
  IsinFormType,
  IsinFormTypeAdd,
  IsinOption,
} from './types';

interface Props {
  onClose: () => void;
  data: IndexedIsinItem[];
  getIsinsData: AxiosPromiseGeneric<IsinItem[]>;
  issuerID?: string;
  isinsAssignedToVoteIssues: string[];
}

const IsinsForm = ({
  onClose,
  data,
  getIsinsData,
  issuerID,
  isinsAssignedToVoteIssues,
}: Props) => {
  const { bondholdersMeetingId } = useParams<RouteParam>();

  const { putBonds } = api.usePutBonds(bondholdersMeetingId);

  const {
    currencyTypeOptions,
    isLoadingCurrencyTypeOptions,
    getCurrencyTypeOptions,
  } = useGetCurrencyOptions();

  const { bondsForIssuer, isLoadingBondsForIssuer, getBondsForIssuer } =
    api.useGetBondsForIssuer(issuerID!);

  useEffect(() => {
    getCurrencyTypeOptions();
    getBondsForIssuer();
  }, [getBondsForIssuer, getCurrencyTypeOptions]);

  const [availableIsinOptions, setAvailableIsinOptions] = useState<
    IsinOption[]
  >([]);

  const [selectedBondCurrency, setSelectedBondCurrency] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (bondsForIssuer.length > 0) {
      setAvailableIsinOptions(
        bondsForIssuer
          .filter(
            (bond) =>
              data.filter((item) => item.isin === bond.isin).length === 0
          )
          .map(({ bondID, isin, currency }) => ({
            label: isin,
            value: bondID,
            currency: currency,
          }))
      );
    }
  }, [bondsForIssuer, bondsForIssuer.length, data]);

  const [isinsData, setIsinsData] = useState<IsinFormFields[]>(data || []);

  const form = useForm<IsinFormType>({ mode: 'onSubmit' });

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    reset,
    setValue,
    handleSubmit,
  } = form;

  const {
    control: controlAdd,
    formState: { errors: errorsAdd },
    trigger: triggerAdd,
    getValues: getValuesAdd,
    reset: resetAdd,
    setValue: setValueAdd,
  } = useForm<IsinFormTypeAdd>({
    mode: 'onSubmit',
    defaultValues: {
      conversionCurrency:
        isinsData.length > 0 ? isinsData[0].conversionCurrency : undefined,
      outstandingAmount: '',
    },
  });

  const selectedCurrency = useWatch({
    control: controlAdd,
    name: 'conversionCurrency',
  });

  const selectedIsin = useWatch({
    control: controlAdd,
    name: 'isin',
  });

  useEffect(() => {
    if (
      availableIsinOptions.find((i) => i.value === selectedIsin)?.currency ===
      selectedCurrency
    ) {
      setValueAdd('conversionRate', '1');
    } else {
      setValueAdd('conversionRate', '');
    }
  }, [availableIsinOptions, selectedCurrency, selectedIsin, setValueAdd]);

  const getCurrentFormValues = () => {
    const currentValues = getValues();
    let currentValuesArray: IsinFormFields[] = [];
    Object.keys(currentValues).forEach((key) => {
      currentValuesArray.push({
        ...currentValues[key as unknown as number],
      });
    });

    return currentValuesArray;
  };

  const onAddClick = async () => {
    const valid = await triggerAdd();
    if (valid) {
      let {
        isin,
        outstandingAmount,
        issuerBondAmount,
        conversionRate,
        conversionCurrency,
      } = getValuesAdd();

      if (issuerBondAmount === '') {
        issuerBondAmount = '0';
      }

      const bondIsin = availableIsinOptions.find(
        (option) => option.value === isin
      );

      // remove selected isin from the list
      setAvailableIsinOptions((prevIsinsOptions) =>
        prevIsinsOptions.filter((option) => option.value !== isin)
      );

      const nextIndex =
        isinsData && isinsData.length > 0
          ? Math.max(...isinsData.map((item) => item.index), 0) + 1
          : 1;

      const prevValues = getCurrentFormValues();
      if (bondIsin) {
        setIsinsData(() => [
          ...(prevValues as IsinFormFields[]),
          {
            id: -1,
            index: nextIndex,
            isin: bondIsin.label,
            bondID: bondIsin.value,
            outstandingAmount: +outstandingAmount,
            issuerBondAmount: +issuerBondAmount,
            conversionRate: +conversionRate,
            conversionCurrency,
            bondCurrency: bondIsin.currency,
          },
        ]);

        resetAdd({
          isin: null,
          outstandingAmount: '',
          issuerBondAmount: '',
          conversionRate: '',
          bondCurrency: '',
          conversionCurrency,
        });

        setIsFormDirty(true);
      }
    }
  };

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const onRemoveClick = (index: number, bondID: string) => {
    const bond = bondsForIssuer.find((bond) => bondID === bond.bondID);
    setAvailableIsinOptions((prevIsinsOptions: IsinOption[]) => [
      ...prevIsinsOptions,
      {
        label: bond!.isin,
        value: bond!.bondID,
        currency: bond!.currency,
      },
    ]);

    setIsinsData(() =>
      getCurrentFormValues().filter((row) => row.index !== index)
    );
  };

  const formData = useMemo(
    () =>
      isinsData.reduce(
        (
          obj: IsinFormType,
          { id, index, ...item }: IsinFormFields
        ): IsinFormType => ({
          ...obj,
          [index]: {
            id,
            index,
            ...item,
          },
        }),
        {}
      ),
    [isinsData]
  );

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const onIsinSelect = (value: string) => {
    setSelectedBondCurrency(
      availableIsinOptions.find((option) => option.value === value)?.currency!
    );

    const amount = bondsForIssuer.find(
      (option) => option.bondID === value
    )?.outstandingAmount;

    setValueAdd('outstandingAmount', decimalNumberFormatter(amount));
  };

  const onSubmit = handleSubmit(async () => {
    try {
      const formattedBondsData = getCurrentFormValues().map(
        ({ id, ...rest }) => ({
          ...rest,
          id: id !== -1 ? id : undefined,
        })
      );
      await putBonds({ data: { MeetingBonds: formattedBondsData } });
      toast.success('Isins have been saved');
      getIsinsData();
      onClose();
    } catch (e) {
      handleException(e);
    }
  });

  const handleConfirmClose = () => {
    confirmClose(isFormDirty, onClose);
  };

  return (
    <Dialog
      title="Edit ISINs"
      data-testid="isins-dialog"
      onClose={handleConfirmClose}
      maxWidth="md"
      loading={isLoadingCurrencyTypeOptions || isLoadingBondsForIssuer}
      dialogActions={
        <>
          <Button data-testid="save-button" onClick={onSubmit}>
            Save
          </Button>
          <Button
            data-testid="cancel-button"
            variant="outlined"
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
        </>
      }
    >
      <Select
        name="conversionCurrency"
        label="Common Currency"
        options={currencyTypeOptions}
        required
        errors={errorsAdd}
        control={controlAdd}
        disabled={isLoadingCurrencyTypeOptions}
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <S.FormGrid item xs={3}>
          <Select
            name="isin"
            label="ISIN"
            options={availableIsinOptions}
            required
            errors={errorsAdd}
            control={controlAdd}
            disabled={isLoadingBondsForIssuer}
            onChange={onIsinSelect}
            isClearable
            autoFocus
          />
        </S.FormGrid>
        <S.FormGrid item xs={3}>
          <CurrencyInput
            control={controlAdd}
            name="outstandingAmount"
            errors={errorsAdd}
            currency={selectedBondCurrency}
            label="Outstanding Amount"
            required
            setValue={setValueAdd}
          />
        </S.FormGrid>
        <S.FormGrid item xs={3}>
          <CurrencyInput
            control={controlAdd}
            name="issuerBondAmount"
            errors={errorsAdd}
            currency={selectedBondCurrency}
            label="Issuers Bond Amount"
            setValue={setValueAdd}
          />
        </S.FormGrid>
        <S.FormGrid item xs={2}>
          <NumberInput
            label="Conversion"
            control={controlAdd}
            errors={errorsAdd}
            name="conversionRate"
            required
            setValue={setValueAdd}
          />
        </S.FormGrid>
        <Grid item>
          <Button onClick={onAddClick} variant="outlined">
            Add
          </Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <IsinsFormTable
          data={isinsData}
          selectedCurrency={selectedCurrency}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          onRemoveClick={onRemoveClick}
          isinsAssignedToVoteIssues={isinsAssignedToVoteIssues}
        />
      </Box>
    </Dialog>
  );
};

export default IsinsForm;
