import { useCallback } from 'react';
import { GROUPS,USER_MANAGEMENT, USERS } from 'router/url';
import { useAxios } from 'utils/hooks/useAxios';
import { User, ValidatedUserInfo } from './types';

export const useGetUsers = (groupID: number) => {
  const [{ data: users, loading: isLoadingUsers }, getUsers] = useAxios<User[]>(
    {
      url: `${USER_MANAGEMENT}${GROUPS}/${groupID}${USERS}`,
    },
    { manual: true },
  );
  return {
    users,
    isLoadingUsers,
    getUsers,
  };
};

export const usePostUser = () => {
  const [{ loading: isLoadingPostUser }, postUser] = useAxios<User>(
    {
      url: `${USER_MANAGEMENT}${USERS}`,
      method: 'POST',
    },
    { manual: true },
  );

  return { isLoadingPostUser, postUser };
};

export const useValidateUser = (username: string) => {
  const [{ data: validatedUserData, loading: isLoadingUser }, validateUser] =
    useAxios<ValidatedUserInfo>(
      `${USER_MANAGEMENT}${USERS}/validate?username=${username}`,
      {
        manual: true,
      },
    );
  return { validatedUserData, isLoadingUser, validateUser };
};

export const usePostInvitation = () => {
  const [{ loading: isLoadingSendInvitation }, postInvitation] = useAxios<User>(
    {
      method: 'POST',
    },
    { manual: true },
  );
  const sendInvitation = useCallback(
    async (userId: number) => {
      const url = `${USER_MANAGEMENT}${USERS}/${userId}/invites`;
      return await postInvitation({ url });
    },
    [postInvitation],
  );
  return { isLoadingSendInvitation, sendInvitation };
};

export const useDeleteUser = (groupID: number) => {
  const [{ loading: isLoadingDeleteUser }, deleteAdvisorUser] = useAxios(
    {
      method: 'DELETE',
    },
    {
      manual: true,
    },
  );

  const deleteUser = useCallback(
    async (userId: number) => {
      const url = `${USER_MANAGEMENT}${USERS}/${userId}${GROUPS}/${groupID}`;
      return await deleteAdvisorUser({ url });
    },
    [deleteAdvisorUser, groupID],
  );

  return { isLoadingDeleteUser, deleteUser };
};

export const usePutUser = () => {
  const [{ loading: isLoadingPutUser }, putAdvisorUser] = useAxios<User>(
    {
      method: 'PUT',
    },
    {
      manual: true,
    },
  );

  const putUser = useCallback(
    async (data: User) => {
      const userId = data.id;
      const url = `${USER_MANAGEMENT}${USERS}/${userId}`;
      return await putAdvisorUser({ url, data });
    },
    [putAdvisorUser],
  );

  return { isLoadingPutUser, putUser };
};
