import { memo, useMemo } from 'react';
import { DoughnutChart } from '@nordictrustee/nt-ui-library';
import { StatisticsStatusData } from '../../types';
import GraphLabel from '../GraphLabel';
import VoteLegendItem from '../VoteLegendItem';
import { generateData } from './utils';

interface Props {
  data?: StatisticsStatusData;
  quorum?: number;
}

const StatusGraph = ({ data, quorum }: Props) => {
  const generatedData = useMemo(
    () =>
      generateData(
        data || { NoVote: 0, VotesApproved: 0, VotesNotApproved: 0 }
      ),
    [data]
  );

  const values = generatedData?.values;

  const chartName = generatedData?.chartLabel;
  const newData: DoughnutChart.ChartData[] =
    values &&
    values.map(
      (value): DoughnutChart.ChartData => ({
        ...value,
        tooltipStyle: 'percentage',
      })
    );

  const threshold = quorum
    ? {
        name: 'Quorum',
        numerator: quorum,
      }
    : undefined;

  return (
    <DoughnutChart.Graph
      name={chartName}
      data={newData}
      threshold={threshold}
      legendItemComponent={VoteLegendItem}
      labelComponent={GraphLabel}
    />
  );
};

export default memo(StatusGraph);
