import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const RefreshButton = styled(IconButton)`
  && {
    margin-right: 10px;
    margin-top: 10px;

    svg {
      font-size: 2rem;
    }
  }
`;
