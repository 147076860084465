import { KeyboardEvent } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import FaceIcon from '@mui/icons-material/Face';
import EmailInput from '../EmailInput';
import * as S from './styles';

export interface Props {
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  isRequired?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onKeyUp?: (data: KeyboardEvent<HTMLElement>) => void;
}

const UsernameInput = ({
  control,
  errors,
  isRequired,
  disabled,
  autoFocus,
  onKeyUp,
}: Props) => {
  return (
    <S.IconInput>
      <S.Icon>
        <FaceIcon />
      </S.Icon>
      <S.Input>
        <EmailInput
          data-testid={'username'}
          name="username"
          label="Username(email)"
          control={control}
          errors={errors}
          isRequired={isRequired}
          disabled={disabled}
          autoFocus={autoFocus}
          onKeyUp={onKeyUp}
        />
      </S.Input>
    </S.IconInput>
  );
};

export default UsernameInput;
