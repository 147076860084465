import { toast } from 'react-toastify';
import Axios, { AxiosError } from 'axios';
import { AuthMethod, Role } from '../../context/AuthProvider/types';

export async function authenticate(username: string, password: string) {
  try {
    const payload = {
      username,
      password,
      appPublicID: window._env_.REACT_APP_IDENTITY,
    };
    const authenticateResponse = await Axios.post(
      `${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/token`,
      payload,
    );
    if (authenticateResponse.request.status === 200) {
      const { tokenValue } = authenticateResponse.data;
      const state = {
        user: {
          authMethod: AuthMethod.IdentityService,
          accessToken: tokenValue,
          authorized: true,
          roles: [Role.Advisor],
        },
      };
      localStorage.setItem('state', JSON.stringify(state));
      return {
        status: authenticateResponse.request.status,
        accessToken: tokenValue,
      };
    }
  } catch (e) {
    let status = (e as AxiosError)?.response?.status;
    let message = '';
    switch (status) {
      case 401:
        message = 'Username and password do not match';
        break;
      default:
        message = 'System unavailable, please try again later';
    }
    toast.error(message, {
      autoClose: false,
    });
  }
}

export async function sendResetEmail(email: string) {
  try {
    const payload = {
      email,
      appPublicID: window._env_.REACT_APP_IDENTITY,
    };
    const sendEmailResponse = await Axios.post(
      `${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/password-reset`,
      payload,
    );
    if (sendEmailResponse.request.status === 204) {
      return {
        status: sendEmailResponse.request.status,
      };
    }
  } catch (e) {
    let status = (e as AxiosError)?.response?.status;
    let message = '';
    switch (status) {
      case 401:
        message =
          'Request lacks valid authentication credentials for the target resource';
        break;
      default:
        message = 'Something went wrong';
    }
    toast.error(message, {
      autoClose: false,
    });
  }
}

export async function resetPassword(token: string, newPassword: string) {
  try {
    const payload = {
      token,
      newPassword,
      appPublicID: window._env_.REACT_APP_IDENTITY,
    };
    const response = await Axios.put(
      `${window._env_.REACT_APP_IDENTITY_URL || ''}/api/auth/password`,
      payload,
    );
    if (response.request.status === 202) {
      return {
        status: response.request.status,
      };
    }
  } catch (e) {
    let status = (e as AxiosError)?.response?.status;
    let message = '';
    switch (status) {
      case 400:
        message = 'Bad request. Validation failed';
        break;
      case 401:
        message =
          'Request lacks valid authentication credentials for the target resource';
        break;
      default:
        message = 'Something went wrong';
    }
    toast.error(message, {
      autoClose: false,
    });
  }
}
export async function verifyAccount(token: string, password: string) {
  try {
    const payload = {
      token,
      password,
      appPublicID: window._env_.REACT_APP_IDENTITY,
    };
    const response = await Axios.post(
      `${
        window._env_.REACT_APP_IDENTITY_URL || ''
      }/api/auth/account-verification`,
      payload,
    );
    if (response.request.status === 202) {
      return {
        status: response.request.status,
      };
    }
  } catch (e) {
    let status = (e as AxiosError)?.response?.status;
    let message = '';
    switch (status) {
      case 400:
        message = 'Bad request. Validation failed';
        break;
      case 401:
        message =
          'Request lacks valid authentication credentials for the target resource';
        break;
      default:
        message = 'Something went wrong';
    }
    toast.error(message, {
      autoClose: false,
    });
  }
}
