import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table } from '@nordictrustee/nt-ui-library';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  FIRST_COLUMN_NO_PADDING,
  TOP_BOTTOM_OF_CELL_NO_PADDING,
} from 'components/Table/const';
import useGetCompanyAdvisors from 'utils/hooks/useGetCompanyAdvisors';
import { AdvisorFormFields, AdvisorWithUsers } from '../types';

interface Props {
  advisorsData: AdvisorFormFields[];
  setAdvisorsData: Dispatch<SetStateAction<AdvisorFormFields[]>>;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  setAdvisorsWithUsers: any;
  advisorsWithUsers: AdvisorWithUsers[];
  getCurrentFormValues: () => AdvisorFormFields[];
}

const AdvisorsFormTable = ({
  advisorsData,
  setAdvisorsData,
  control,
  errors,
  setAdvisorsWithUsers,
  advisorsWithUsers,
  getCurrentFormValues,
}: Props) => {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const columns = useMemo(
    (): Table.Column<AdvisorFormFields>[] => [
      {
        title: 'Advisor Company',
        field: 'companyName',
        width: '39%',
        disableClick: true,
        ...FIRST_COLUMN_NO_PADDING,
      },
      {
        title: 'Advisor Users',
        disableClick: true,
        render: ({ companyID, index }) => {
          return (
            <MultiSelect
              name={`${index}.users`}
              isClearable
              label="Advisor Users"
              options={
                advisorsWithUsers.find((u) => u.companyID === companyID)
                  ?.users || []
              }
              control={control}
              errors={errors}
              isLoading={loadingUsers}
            />
          );
        },
        ...TOP_BOTTOM_OF_CELL_NO_PADDING,
      },
    ],
    [control, errors, loadingUsers, advisorsWithUsers]
  );

  const handleDeleteAdvisorData = (companyID: number | null) => {
    if (companyID === null) {
      throw new Error('trying to delete user with id null');
    }
    const prevValues = getCurrentFormValues();
    setAdvisorsData(() =>
      prevValues.filter((item) => item.companyID !== companyID)
    );
  };

  const { getCompanyAdvisors } = useGetCompanyAdvisors();

  useEffect(() => {
    advisorsData
      .filter(
        (advisor) =>
          !advisorsWithUsers.find(
            (advisorWithUsers) =>
              advisorWithUsers.companyID === advisor.companyID
          )
      )
      .forEach((filteredAdvisor) => {
        setLoadingUsers(true);
        getCompanyAdvisors(filteredAdvisor.companyID!).then(
          (advisorUserResult) => {
            if (advisorUserResult.data) {
              setAdvisorsWithUsers((prevValues: any) => [
                ...prevValues,
                {
                  companyID: filteredAdvisor.companyID,
                  users:
                    advisorUserResult.data?.map(
                      ({ id, firstName, lastName }) => ({
                        value: id,
                        label: `${firstName} ${lastName}`,
                      })
                    ) ?? [],
                },
              ]);
            }
            setLoadingUsers(false);
          }
        );
      });
  }, [
    advisorsData,
    advisorsWithUsers,
    getCompanyAdvisors,
    setAdvisorsWithUsers,
  ]);

  return (
    <Table.Root<AdvisorFormFields>
      actions={[
        (rowData) => ({
          icon: () => <DeleteIcon color="action" />,
          onClick: () => {
            handleDeleteAdvisorData(rowData.companyID);
          },
        }),
      ]}
      data={advisorsData}
      columns={columns}
      options={{
        toolbar: false,
        paging: false,
        maxBodyHeight: '20rem',
      }}
    />
  );
};

export default AdvisorsFormTable;
