import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Tooltip } from '@mui/material';
import * as URL from 'router/url';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import AdvisorsStep from './AdvisorsStep';
import * as api from './api';
import DetailsStep from './DetailsStep';
import ISINsStep from './ISINsStep';
import MattersToBeDecidedStep from './MattersToBeDecidedStep';
import * as S from './styles';
import {
  VoteIssueAddFormFields,
  AdvisorAddFormFields,
  IsinAddFormFields,
  BondholdersMeetingDetailsAddFormFields,
} from './types';

type Step =
  | 'Add Details'
  | 'Add ISINs'
  | 'Add Advisors'
  | 'Add Matters to be decided';

interface Props {
  title: string;
  onClose: () => void;
}

const AddMeetingForm = ({ title, onClose }: Props) => {
  const { isPostBondholdersMeetingLoading, postBondholdersMeeting } =
    api.usePostBondholdersMeeting();

  const { push } = useHistory();

  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [isinsTableData, setIsinsTableData] = useState<IsinAddFormFields[]>([]);
  const [advisorsTableData, setAdvisorsTableData] = useState<
    AdvisorAddFormFields[]
  >([]);
  const [voteIssueTableData, setVoteIssueTableData] = useState<
    VoteIssueAddFormFields[]
  >([]);

  const stepNames: Step[] = useMemo(
    () => [
      'Add Details',
      'Add ISINs',
      'Add Advisors',
      'Add Matters to be decided',
    ],
    []
  );

  const form = useForm<BondholdersMeetingDetailsAddFormFields>({
    mode: 'onBlur',
  });
  const {
    trigger,
    getValues,
    handleSubmit,
    formState: { dirtyFields },
  } = form;

  const onSubmit = handleSubmit(async () => {
    try {
      const details = getValues();
      // TODO - simplify payload for bonds and voteIssues - no need to send all those information to Backend

      const data = {
        title: details.title,
        caseHandlerID: details.caseHandlerID,
        administratorID: details.administratorID,
        issuerID: details.issuerID,
        votingDeadline: details.votingDeadline,
        type: details.type,
        advisors: advisorsTableData.map(({ advisorCompanyID, users }) => ({
          companyID: advisorCompanyID,
          users: users.map(({ id }) => ({ userID: id })),
        })),
        bonds: isinsTableData,
        voteIssues: voteIssueTableData.map(({ id: _, ...rest }) => rest), // remove id from post body
        trustee: details.trustee,
        recordDate: details.recordDate,
      };
      const response = await postBondholdersMeeting({ data }).catch(function (
        thrown
      ) {
        throw thrown;
      });
      toast.success('Bondholders meeting has been created');
      push(`${URL.BONDHOLDERS_MEETINGS}/${response.data.id}`);
      onClose();
    } catch (e) {
      handleException(e);
    }
  });

  const nextStepTooltipMessage = useMemo(() => {
    if (
      stepNames[currentStepNumber] === 'Add ISINs' &&
      !isinsTableData.length
    ) {
      return 'At least one ISIN has to be added to enable this action';
    }
    if (
      stepNames[currentStepNumber] === 'Add Matters to be decided' &&
      !voteIssueTableData.length
    ) {
      return 'At least one Matter to be decided has to be added to the list to enable this action';
    }
    return '';
  }, [
    currentStepNumber,
    isinsTableData.length,
    stepNames,
    voteIssueTableData.length,
  ]);

  const goToNextStep = async (index: number) => {
    const isPreviousStep = index < currentStepNumber;
    if (isPreviousStep) {
      setCurrentStepNumber(index);
      return;
    }
    if (stepNames[currentStepNumber] === 'Add Details') {
      const valid = await trigger();
      if (valid) {
        setCurrentStepNumber(index);
      }
      return;
    }
    if (!nextStepTooltipMessage) {
      setCurrentStepNumber(index);
    }
  };

  const isinOptions = useMemo(
    () =>
      isinsTableData?.map(({ bondID, isin }) => ({
        label: isin,
        value: bondID,
      })),
    [isinsTableData]
  );

  const handleConfirmClose = () => {
    confirmClose(!!Object.keys(dirtyFields).length, onClose);
  };

  return (
    <Dialog
      title={title}
      onClose={handleConfirmClose}
      maxWidth="md"
      subheader={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          {stepNames.map((stepName, index) => (
            <S.StepButton
              key={stepName}
              variant="text"
              onClick={() =>
                index !== currentStepNumber ? goToNextStep(index) : {}
              }
            >
              <S.StepBadge isPrimary={currentStepNumber >= index}>
                {currentStepNumber >= index + 1 ? (
                  <S.DoneStepIcon />
                ) : (
                  index + 1
                )}
              </S.StepBadge>
              {stepName}
            </S.StepButton>
          ))}
        </Box>
      }
      dialogActions={
        <>
          <Tooltip title={nextStepTooltipMessage}>
            <div>
              <Button
                disabled={!!nextStepTooltipMessage}
                isLoading={isPostBondholdersMeetingLoading}
                onClick={
                  currentStepNumber === stepNames.length - 1
                    ? onSubmit
                    : () => goToNextStep(currentStepNumber + 1)
                }
              >
                {currentStepNumber === stepNames.length - 1
                  ? 'Save'
                  : 'Continue'}
              </Button>
            </div>
          </Tooltip>
          <Button variant="outlined" onClick={handleConfirmClose}>
            Cancel
          </Button>
          {currentStepNumber > 0 && (
            <Button
              onClick={() => setCurrentStepNumber((current) => current - 1)}
            >
              Back
            </Button>
          )}
        </>
      }
    >
      <FormProvider {...form}>
        <S.Content>
          {stepNames[currentStepNumber] === 'Add Details' && (
            <DetailsStep setIsinsTableData={setIsinsTableData} />
          )}
          {stepNames[currentStepNumber] === 'Add ISINs' && (
            <ISINsStep
              tableData={isinsTableData}
              setTableData={setIsinsTableData}
            />
          )}
          {stepNames[currentStepNumber] === 'Add Advisors' && (
            <AdvisorsStep
              tableData={advisorsTableData}
              setTableData={setAdvisorsTableData}
            />
          )}
          {stepNames[currentStepNumber] === 'Add Matters to be decided' && (
            <MattersToBeDecidedStep
              tableData={voteIssueTableData}
              setTableData={setVoteIssueTableData}
              isinOptions={isinOptions}
            />
          )}
        </S.Content>
      </FormProvider>
    </Dialog>
  );
};

export default AddMeetingForm;
