import { ChangeEvent, CSSProperties } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Button, Tooltip } from '@mui/material';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';
import * as S from './FileInput.styles';

interface Props {
  'data-testid': string;
  name: string;
  text?: string;
  acceptFormat: string;
  onChangeCallback?: (event: ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  control: Control;
  error?: string;
  isRequired?: boolean;
  label?: string;
  multiple?: boolean;
  disabled?: boolean;
  tooltipText?: string;
}

const FileInput = ({
  label,
  control,
  name,
  isRequired,
  style,
  text,
  'data-testid': dataTestId,
  multiple,
  acceptFormat,
  onChangeCallback,
  error,
  disabled,
  tooltipText,
}: Props) => (
  <S.FileInputWrapper>
    {label && <S.LabelWrapper>{label}</S.LabelWrapper>}
    <Controller
      control={control}
      defaultValue=""
      name={name ?? ''}
      rules={registerRequiredRule(isRequired)}
      render={({ field: { name, onChange } }) => (
        <Tooltip title={tooltipText ? tooltipText : ''}>
          <span>
            <Button
              component="label"
              variant="outlined"
              color="primary"
              style={style}
              disabled={disabled}
            >
              {text ?? 'Choose file'}
              <input
                hidden
                data-testid={dataTestId}
                type="file"
                name={name}
                multiple={multiple}
                accept={acceptFormat}
                onChange={(event) => {
                  onChange(event);
                  if (onChangeCallback) {
                    onChangeCallback(event);
                  }
                }}
              />
            </Button>
          </span>
        </Tooltip>
      )}
    />
    <S.ErrorMessageWrapper>
      {error && <ErrorLabel data-testid="error-label">{error}</ErrorLabel>}
    </S.ErrorMessageWrapper>
  </S.FileInputWrapper>
);

export default FileInput;
