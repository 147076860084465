import { useAxios } from 'utils/hooks/useAxios';
import { BondForIssuer } from './types';

export const useGetBondsForIssuer = (issuerId: string) => {
  const [
    { data: bondsForIssuer = [], loading: isLoadingBondsForIssuer },
    getBondsForIssuer,
  ] = useAxios<BondForIssuer[]>(
    {
      url: `/issuers/${issuerId}/bonds`,
    },
    { manual: true },
  );
  return {
    bondsForIssuer,
    isLoadingBondsForIssuer,
    getBondsForIssuer,
  };
};

export const usePutBonds = (bondholdersMeetingId: string) => {
  const [, putBonds] = useAxios(
    {
      url: `/meetings/${bondholdersMeetingId}/bonds`,
      method: 'PUT',
    },
    {
      manual: true,
    },
  );
  return { putBonds };
};
