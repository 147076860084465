import { MeetingType } from 'utils/types/MeetingType';
import { TrusteeType } from 'utils/types/TrusteeType';
import { VoteIssueResult } from 'utils/types/VoteIssueResult';
import { VoteIssueFormFieldsRow } from './types';

export const getVpsVotesMustBeChecked = (
  voteIssuesData: VoteIssueFormFieldsRow[] | undefined,
  trusteeType: TrusteeType | undefined,
  meetingType: MeetingType | undefined
) => {
  if (
    voteIssuesData?.some((v) => v.result === VoteIssueResult.Approved) &&
    trusteeType === TrusteeType.NordicTrusteeNO &&
    meetingType === MeetingType.BondholderMeeting
  ) {
    return true;
  }
  return false;
};

export const convertPercentToNumber = (pst: string) => {
  const clean = pst.replace(',', '.');
  return parseFloat(clean);
};
export const convertFractionToNumbers = (fraction: string): number[] => {
  const [n, d] = fraction.split('/');
  return [convertPercentToNumber(n), parseInt(d, 10)];
};
