import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '@nordictrustee/nt-ui-library';
import { getChipList } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import { RouteParam } from 'utils/types/RouteParam';
import AdvisorsForm from './AdvisorsForm';
import * as api from './api';
import { Advisor } from './types';

const columns: Table.Column<Advisor>[] = [
  {
    title: 'Advisor Company',
    field: 'companyName',
    type: 'string',
    width: '20%',
  },
  {
    title: 'Advisor Users',
    field: 'advisorUsers',
    // TODO - add users here and in the interface for 'Advisor'
    render: ({ users }) =>
      getChipList(
        users.map(({ firstName, lastName }) => `${firstName} ${lastName}`),
        7,
        15
      ),
  },
];

interface Props {
  isAdvisorsFormOpen: boolean;
  handleCloseAdvisorsForm: () => void;
}

const Advisors = ({ isAdvisorsFormOpen, handleCloseAdvisorsForm }: Props) => {
  const { bondholdersMeetingId } = useParams<RouteParam>();

  const { advisorsData, isLoadingAdvisorsData, getAdvisorsData } =
    api.useGetAdvisorsData(bondholdersMeetingId);

  useEffect(() => {
    getAdvisorsData();
  }, [getAdvisorsData]);

  const formattedAdvisorsData = useMemo(
    () =>
      advisorsData.map(({ companyID, companyName, users }, i) => {
        const index = i + 1;
        return {
          id: index,
          index,
          companyID,
          companyName,
          users: users.map(({ id }) => id),
        };
      }),
    [advisorsData]
  );

  return (
    <>
      <Table.Root<Advisor>
        columns={columns}
        data={advisorsData}
        isLoading={isLoadingAdvisorsData}
        options={{
          toolbar: false,
          paging: false,
          minBodyHeight: '15.5rem',
          maxBodyHeight: '15.5rem',
        }}
        onRowClick={() => {}}
      />
      {isAdvisorsFormOpen && (
        <AdvisorsForm
          data={formattedAdvisorsData}
          onClose={handleCloseAdvisorsForm}
          getAdvisorsData={getAdvisorsData}
        />
      )}
    </>
  );
};

export default Advisors;
