import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import { DataLink } from '@nordictrustee/nt-ui-library';
import FormPage from 'components/FormPage';
import CheckboxInput from 'components/Inputs/CheckboxInput';
import PasswordInput from 'components/Inputs/PasswordInput';
import TextInput from 'components/Inputs/TextInput';
import { handleException } from 'utils/handleException';
import { useQuery } from 'utils/hooks/useQuery';
import * as URL from '../../../router/url';
import { verifyAccount } from '../api';

interface VerifyAccount {
  username: string;
  fullName: string;
  newPassword: string;
  confirmPassword: string;
}
const VerifyAccount = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
  } = useForm<VerifyAccount>({ mode: 'onChange' });

  const history = useHistory();
  const query = useQuery();
  const token = useMemo(() => query.get('token') || '', [query]);
  const firstName = useMemo(() => query.get('firstname') || '', [query]);
  const lastName = useMemo(() => query.get('lastname') || '', [query]);
  const userName = useMemo(() => query.get('email') || '', [query]);

  useEffect(() => {
    reset({
      username: userName,
      fullName: `${firstName} ${lastName}`,
    });
  }, [firstName, lastName, reset, userName]);

  const handleVerifyAccount = handleSubmit(async ({ newPassword }) => {
    if (token) {
      try {
        await verifyAccount(token, newPassword);
        toast.success('Account has been verified');
        history.push(URL.LOGIN);
      } catch (e) {
        handleException(e);
      }
    }
  });

  const newPassword: string = useWatch({
    control,
    name: 'newPassword',
  });

  useEffect(() => {
    trigger('confirmPassword');
  }, [newPassword, trigger]);

  return (
    <FormPage
      cardTitle="Verify Account"
      buttonText="Verify Account"
      onClick={handleVerifyAccount}
      data-testid="verify-account-page"
      isLoadingButton={false}
    >
      <Typography>
        To verify your account, fill in the password fields and click Verify
        Account.
      </Typography>
      <TextInput
        name="username"
        label="Username"
        control={control}
        errors={errors}
        disabled
      />
      <TextInput
        name="fullName"
        label="Full Name"
        control={control}
        errors={errors}
        disabled
      />
      <PasswordInput
        name="newPassword"
        label="New password"
        control={control}
        errors={errors}
        required
        showIcon
        resetPassword
        autoFocus
      />
      <PasswordInput
        name="confirmPassword"
        label="Confirm password"
        control={control}
        errors={errors}
        required
        showIcon
        passwordToMatch={newPassword}
      />
      <Box mt={2}>
        <CheckboxInput
          name="isVerified"
          label={
            <>
              By clicking “Verify Account”, you agree to our{' '}
              <DataLink
                text="Terms of Use"
                noPaddingLeft
                to={`${URL.TERMS}`}
                isExternal
              ></DataLink>{' '}
              and that you have read our{' '}
              <DataLink
                text="Privacy Statement"
                noPaddingLeft
                to="https://nordictrustee.com/about/governance/"
                isExternal
              ></DataLink>{' '}
              and our{' '}
              <DataLink
                text="Cookie
              Policy"
                noPaddingLeft
                to="https://nordictrustee.com/privacy/"
                isExternal
              ></DataLink>
              .
            </>
          }
          color="primary"
          control={control}
          errors={errors}
          required
        />
      </Box>
    </FormPage>
  );
};

export default VerifyAccount;
