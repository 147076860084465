import { useAxios } from 'utils/hooks/useAxios';
import { DictionaryOption } from 'utils/types/Dictionary.types';
import { PageableResponse } from 'utils/types/PageableResponse.types';
import { BondholdersMeeting } from './types';

export const useGetBondholdersMeeting = (params: URLSearchParams) => {
  const [
    { data: meetings, loading: isLoadingMeetings },
    getMeetings,
  ] = useAxios<PageableResponse<BondholdersMeeting[]>>(
    {
      url: `/meetings${params.toString() ? `?${params}` : ''}`,
    },
    { manual: true },
  );
  return {
    meetings,
    isLoadingMeetings,
    getMeetings,
  };
};

export const useGetMeetingStatusOptions = () => {
  const [
    { data: meetingStatusOptions, loading: isLoadingMeetingStatusOptions },
    getMeetingStatusOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: `/dictionaries/meeting-status-type-options`,
    },
    { manual: true },
  );
  return {
    meetingStatusOptions,
    isLoadingMeetingStatusOptions,
    getMeetingStatusOptions,
  };
};
