import styled from 'styled-components';
import theme from 'theme/theme';

export const FileInputWrapper = styled.div`
  position: relative;
`;

export const ErrorMessageWrapper = styled.span`
  display: block;
  margin-top: 0.25rem;
  position: absolute;
`;

export const LabelWrapper = styled.span`
  display: block;
  font-size: 13px;
  color: ${theme.palette.text.secondary};
  margin-bottom: 0.5rem;
`;
