import { Control } from 'react-hook-form';
import { SetValueConfig } from 'react-hook-form/dist/types/form';
import { Tooltip } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import FractionInput from 'components/Inputs/FractionInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import {
  FIRST_COLUMN_NO_PADDING,
  TOP_BOTTOM_OF_CELL_NO_PADDING,
} from 'components/Table/const';
import { resultOptions } from '../const';
import { VoteIssueFormFieldsRow } from '../types';
import * as S from './styles';
import { canRemoveAllIsins, isIsinRemovable } from './utils';

type FieldName = string;
type SetValueType = (
  name?: FieldName,
  value?: string,
  options?: SetValueConfig
) => void;
export const columns = ({
  control,
  errors,
  disabled,
  setValue,
  isinOptions,
  validationData,
}: {
  control: Control<any>;
  disabled?: boolean;
  errors: object;
  isinOptions: {
    value: string;
    label: string;
  }[];
  setValue: SetValueType;
  validationData: any[];
}): Table.Column<VoteIssueFormFieldsRow>[] => {
  const handleChange = (value: string, name: string) => {
    setValue(name, value);
  };
  const handlePercentageBlur = (
    value: string,
    name: string,
    initial?: string
  ) => {
    if (!value) {
      setValue(name, '', {
        shouldDirty: !!initial,
      });
      return;
    }
    const cleanValue = parseFloat(value.replace(',', '.'));
    if (isNaN(cleanValue)) {
      return;
    }
    const valueWithP = `${cleanValue}%`;
    if (valueWithP !== initial) {
      setValue(name, valueWithP, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };
  const handleFractionBlur = (value: string, name: string) => {
    const [n, d] = value.split('/');
    const nNumber = parseFloat(n);
    const dNumber = d ? parseInt(d, 10) : 100;
    if (isNaN(nNumber)) {
      return;
    }
    const formattedValue =
      dNumber === 100 ? `${nNumber}%` : `${nNumber}/${dNumber}`;
    setValue(name, formattedValue, { shouldValidate: true, shouldDirty: true });
  };
  return [
    {
      title: 'Matter to be decided*',
      width: '25%',
      disableClick: true,
      render: ({ index }) => (
        <S.MatterDiv>
          <TextInput
            name={`${index}.matter`}
            control={control}
            errors={errors}
            required
            disabled={disabled}
            onChange={(value) => {
              handleChange(value, `${index}.matter`);
            }}
          />
        </S.MatterDiv>
      ),
      cellStyle: {
        ...TOP_BOTTOM_OF_CELL_NO_PADDING.cellStyle,
        ...FIRST_COLUMN_NO_PADDING.cellStyle,
      },
      headerStyle: {
        ...TOP_BOTTOM_OF_CELL_NO_PADDING.cellStyle,
        ...FIRST_COLUMN_NO_PADDING.cellStyle,
      },
    },
    {
      title: 'ISINs*',
      width: '43%',
      disableClick: true,
      render: ({ index, id }) => {
        const canRemoveIsins = canRemoveAllIsins(validationData, id);
        return (
          <Tooltip
            title={
              !canRemoveIsins
                ? 'Some Isins needs to be unassigned from votes to be removed'
                : ''
            }
          >
            <S.IsinsDiv>
              <MultiSelect
                name={`${index}.bondIDs`}
                options={
                  isinOptions
                    ? isinOptions.map(({ label, value }) => ({
                        label,
                        value,
                        isFixed: !isIsinRemovable(validationData, label, id),
                      }))
                    : []
                }
                control={control}
                errors={errors}
                isClearable={canRemoveIsins}
                required
                disabled={disabled}
              />
            </S.IsinsDiv>
          </Tooltip>
        );
      },
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
    {
      title: 'Result',
      width: '25%',
      disableClick: true,
      render: ({ index }) => (
        <Select
          name={`${index}.result`}
          label="Result"
          options={resultOptions}
          errors={errors}
          control={control}
          isClearable
        />
      ),
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
    {
      title: 'Quorum',
      width: '5%',
      disableClick: true,
      render: ({ index, quorum }) => (
        <S.MatterDiv>
          <FractionInput
            name={`${index}.quorum`}
            control={control}
            errors={errors}
            disabled={disabled}
            onBlur={(value) => {
              handlePercentageBlur(value, `${index}.quorum`, quorum);
            }}
            maxCharactersLength={6}
            percent
          />
        </S.MatterDiv>
      ),
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
    {
      title: 'Majority',
      width: '5%',
      disableClick: true,
      render: ({ index }) => (
        <S.MatterDiv>
          <FractionInput
            name={`${index}.majority`}
            control={control}
            errors={errors}
            disabled={disabled}
            onBlur={(value) => {
              handleFractionBlur(value, `${index}.majority`);
            }}
            maxCharactersLength={6}
            percent
            fraction
          />
        </S.MatterDiv>
      ),
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
  ];
};
