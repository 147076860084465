import { Card } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyHeader = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormPageCard = styled(Card)`
  position: relative;
  height: 100%;
  max-width: 605px;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
`;
