export const tabs = {
  isins: {
    value: 'isins',
    label: 'ISINs',
  },
  advisors: {
    value: 'advisors',
    label: 'Advisors',
  },
};
