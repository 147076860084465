import { useAxios } from '../../utils/hooks/useAxios';
import { PageableResponse } from '../../utils/types/PageableResponse.types';
import { UploadedFile } from './types';

export const useGetUploadedFiles = (params: URLSearchParams) => {
  const [
    { data: uploadedFiles, loading: isLoadingUploadedFiles },
    getUploadedFiles,
  ] = useAxios<PageableResponse<UploadedFile[]>>(
    {
      url: `/votes-upload-jobs${params.toString() ? `?${params}` : ''}`,
    },
    { manual: true },
  );
  return {
    uploadedFiles,
    isLoadingUploadedFiles,
    getUploadedFiles,
  };
};
