import React, { Fragment } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { VoteDocument } from 'modules/BondholdersMeeting/screens/Meeting/types';

interface Props {
  files: (File | VoteDocument)[];
  onDelete: (file: File | VoteDocument) => void;
  onDownload: (file: File | VoteDocument) => void;
}
interface ActionIconProps {
  tooltipValue: string;
  onClick: (file: File | VoteDocument) => void;
  file: File | VoteDocument;
  icon: any;
}

const ActionIcon = ({ tooltipValue, onClick, file, icon }: ActionIconProps) => {
  const handleClick = () => {
    onClick(file);
  };
  return (
    <Tooltip title={tooltipValue}>
      <span>
        <IconButton onClick={handleClick}>
          {React.createElement(icon, {
            color: 'action',
          })}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const FilesList = ({ files, onDelete, onDownload }: Props) => {
  return (
    <Box mt={4}>
      <Grid container justifyContent="space-between" alignItems="center">
        {files.map((file) => (
          <Fragment key={uuidv4()}>
            <Grid item>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ textDecoration: 'underline' }}
              >
                {file.name}
              </Typography>
            </Grid>
            <Grid item>
              <ActionIcon
                tooltipValue="Download file"
                onClick={onDownload}
                file={file}
                icon={GetAppIcon}
              />
              <ActionIcon
                tooltipValue="Remove file"
                onClick={onDelete}
                file={file}
                icon={DeleteIcon}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default FilesList;
