import { useAxios } from 'utils/hooks/useAxios';

export const usePatchMeetingStatus = (bondholdersMeetingId: string) => {
  const [, patchMeetingStatus] = useAxios(
    {
      url: `/meetings/${bondholdersMeetingId}/status`,
      method: 'PATCH',
    },
    {
      manual: true,
    },
  );
  return { patchMeetingStatus };
};
