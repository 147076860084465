import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import Card from 'components/Card';
import DocIcon from 'components/Icons/DocIcon';
import { downloadFile } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import { exportFile } from 'utils/exportFile';
import { useToggle } from 'utils/hooks/useToggle';
import { MeetingType } from 'utils/types/MeetingType';
import { RouteParam } from 'utils/types/RouteParam';
import { TrusteeType } from 'utils/types/TrusteeType';
import * as api from './api';
import { documentsData } from './const';
import DocumentsForm from './DocumentsForm';
import * as S from './styles';
import { Document, DocumentType } from './types';

interface Props {
  numberOfIsins: number;
  meetingType?: MeetingType;
  trusteeType?: TrusteeType;
  isLoadingDetails: boolean;
}

const Documents = ({
  numberOfIsins,
  meetingType,
  trusteeType,
  isLoadingDetails,
}: Props) => {
  const [
    isDocumentsFormOpen,
    handleOpenDocumentsForm,
    handleCloseDocumentsForm,
  ] = useToggle();
  const { bondholdersMeetingId } = useParams<RouteParam>();

  const { documents, isLoadingMeetingDocuments, getMeetingDocuments } =
    api.useGetMeetingDocuments(bondholdersMeetingId);

  const { getDocument } = api.useGetMeetingDocument(bondholdersMeetingId);
  const { getGeneratedDocument } =
    api.useGetGeneratedMeetingDocument(bondholdersMeetingId);

  useEffect(() => {
    getMeetingDocuments();
  }, [getMeetingDocuments]);

  const handleDownloadFile = useCallback(
    async (id: number, name: string) => {
      const blob = await getDocument(id, name);
      downloadFile(blob as File);
    },
    [getDocument]
  );

  const handleDocxDownload = useCallback(
    async (documentType: DocumentType) => {
      const response = await getGeneratedDocument(documentType);
      exportFile(response);
      toast.success('Document has been downloaded');
    },
    [getGeneratedDocument]
  );

  const documentColumn: Table.Column<Document>[] = [
    {
      field: 'documentName',
      render: ({ name, type, id }) => (
        <Box display="flex" alignItems="center">
          {type === DocumentType.UserDocument ? (
            <Typography variant="subtitle1" color="primary">
              <Link onClick={() => handleDownloadFile(id!, name)}>{name}</Link>
            </Typography>
          ) : (
            <Tooltip title={name}>
              <S.tableCellText>{name}</S.tableCellText>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const documentListData = useMemo(() => {
    const mappedDocs = documents.map((doc) => {
      const document: Document = {
        name: doc.name,
        id: doc.id,
        type: DocumentType.UserDocument,
      };
      return document;
    });
    return [
      ...documentsData.filter(
        (d) => d.meetingType === meetingType && d.trusteeType === trusteeType
      ),
      ...mappedDocs,
    ];
  }, [documents, meetingType, trusteeType]);

  const isMoreThanOneISIN = useMemo(() => {
    return numberOfIsins > 1;
  }, [numberOfIsins]);

  return (
    <>
      <Card
        title="Documents"
        data-testid="meeting-details"
        canEdit
        onEditClick={handleOpenDocumentsForm}
        isLoading={isLoadingMeetingDocuments || isLoadingDetails}
        withoutPadding
      >
        <S.CardContent>
          <Table.Root
            columns={documentColumn}
            data={documentListData}
            onRowClick={() => {}}
            options={{
              toolbar: false,
              paging: false,
            }}
            actions={[
              (data) => ({
                icon: () => <DocIcon disabled={isMoreThanOneISIN} />,
                onClick: () => handleDocxDownload(data.type),
                tooltip: isMoreThanOneISIN
                  ? 'Can only generate documents for meetings with one ISIN'
                  : 'Download DOCX file',
                disabled: isMoreThanOneISIN,
                hidden: data.type === DocumentType.UserDocument,
              }),
            ]}
          />
        </S.CardContent>
      </Card>
      {isDocumentsFormOpen && (
        <DocumentsForm
          onClose={handleCloseDocumentsForm}
          isLoadingDocuments={isLoadingMeetingDocuments}
          documents={documents}
          getMeetingDocuments={getMeetingDocuments}
          getDocument={getDocument}
        />
      )}
    </>
  );
};

export default Documents;
