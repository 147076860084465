import { MeetingType } from 'utils/types/MeetingType';
import { TrusteeType } from '../../../../../utils/types/TrusteeType';

export default interface MeetingDocument {
  id: number;
  name: string;
}

export enum DocumentType {
  MinutesBondholdersMeeting = 'MinutesBondholdersMeeting',
  NoticeBondholdersMeeting = 'NoticeBondholdersMeeting',
  NoticeWrittenResolution = 'NoticeWrittenResolution',
  Proxy = 'Proxy',
  VotingFormWrittenResolution = 'VotingFormWrittenResolution',
  CertificateOfHoldings = 'CertificateOfHoldings',
  NoticeOfResultsAmendmentsAndWaivers = 'NoticeOfResultsAmendmentsAndWaivers',
  NoticeOfResultsQuorumRequirementNotFulfilled = 'NoticeOfResultsQuorumNotFulfilled',
  MinutesFromWrittenProcedure = 'MinutesFromWrittenProcedure',
  UserDocument = 'UserDocument',
}

export interface Document extends MeetingDocument {
  type: DocumentType;
  meetingType?: MeetingType;
  trusteeType?: TrusteeType;
}
