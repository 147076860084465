import { memo, ReactNode, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import * as S from './Dropzone.styles';

interface Props {
  disabled?: boolean;
  children: ReactNode;
  onDropCallback: (droppedFile: any) => void;
  acceptFormats: string[] | string;
  multiple?: boolean;
  maxSize?: number;
}

const Dropzone = ({
  multiple,
  disabled,
  children,
  onDropCallback,
  acceptFormats,
  maxSize,
}: Props) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (!!rejectedFiles?.length) {
        // display first error from the list:
        toast.error(rejectedFiles[0]?.errors[0]?.message);
      } else {
        multiple
          ? onDropCallback(acceptedFiles)
          : onDropCallback(acceptedFiles[0]);
      }
    },
    [multiple, onDropCallback],
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFormats,

    multiple: multiple,
    disabled: disabled,
    maxSize: maxSize && maxSize * 1000000, // in bytes
  });

  return (
    <S.DropZoneArea
      {...getRootProps()}
      data-testid="drag-and-drop"
      className={isDragActive ? 'fileInDropArea' : undefined}
    >
      {children}
    </S.DropZoneArea>
  );
};

export default memo(Dropzone);
