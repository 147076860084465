import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { InputAdornment, TextField } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { ErrorLabel } from '../styles';
import * as S from './styles';

interface Props {
  name: string;
  label: string;
  errors: DeepMap<FieldValues, FieldError>;
  currency: string;
  required?: boolean;
  control: Control<any>;
  disabled?: boolean;
  defaultValue?: string;
  setValue: (key: any, value: any) => void;
  onValueChange?: (values: NumberFormatValues) => void;
}

const CurrencyInput = (props: Props) => {
  return (
    <div>
      <Controller
        control={props.control}
        name={props.name}
        rules={{
          required: props.required ? 'This field is required' : undefined,
        }}
        defaultValue={props.defaultValue ?? ''}
        render={({ field: { value, name, onBlur } }) => (
          <NumberFormat
            name={name}
            disabled={props.disabled ?? false}
            value={value}
            onValueChange={(e) => {
              props.setValue(`${props.name}`, e.floatValue);
              props.onValueChange && props.onValueChange(e);
            }}
            label={`${props.label}${props.label && props.required ? '*' : ''}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <S.AdornmentWrapper>{props.currency}</S.AdornmentWrapper>
                </InputAdornment>
              ),
            }}
            customInput={TextField}
            thousandSeparator={' '}
            fullWidth
            margin={'dense'}
            onBlur={onBlur}
          />
        )}
      />
      <ErrorLabel data-testid="error-label">
        {props.errors && helperText(props.name, props.errors)}
      </ErrorLabel>
    </div>
  );
};

export default CurrencyInput;
