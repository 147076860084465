import { useCallback, useMemo, useState } from 'react';
import { Filter } from '@material-table/core';
import { useQuery } from 'utils/hooks/useQuery';

export const useUploadedFilesQuery = () => {
  const query = useQuery();

  const pageSizeFromQuery = useMemo(
    () => Number(query.get('pageSize')) || 25,
    [query],
  );
  const pageFromQuery = useMemo(() => Number(query.get('page')) || 0, [query]);

  const uploadedDateFromQuery = useMemo(
    () => query.get('uploadDate') || '',
    [query],
  );
  const fileNameFromQuery = useMemo(() => query.get('name') || '', [query]);
  const meetingFromQuery = useMemo(
    () => query.get('meetingName') || '',
    [query],
  );
  const voteIssueFromQuery = useMemo(
    () => query.get('voteIssueName') || '',
    [query],
  );
  const resultFromQuery = useMemo(() => query.getAll('result') || [], [query]);
  const reasonFromQuery = useMemo(() => query.get('reason') || '', [query]);

  const orderByFromQuery = useMemo(
    () => query.get('orderBy') || 'uploadDate',
    [query],
  );
  const orderDirectionFromQuery = useMemo(
    () => query.get('orderDirection') || 'desc',
    [query],
  );

  const [pageSizeQuery, setPageSizeQuery] = useState(pageSizeFromQuery);
  const [pageQuery, setPageQuery] = useState(pageFromQuery);
  const [uploadedDateQuery, setUploadedDateQuery] = useState(
    uploadedDateFromQuery,
  );
  const [fileNameQuery, setFileNameQuery] = useState(fileNameFromQuery);
  const [meetingQuery, setMeetingQuery] = useState(meetingFromQuery);
  const [voteIssueQuery, setVoteIssueQuery] = useState(voteIssueFromQuery);
  const [resultQuery, setResultQuery] = useState(resultFromQuery);
  const [reasonQuery, setReasonQuery] = useState(reasonFromQuery);
  const [orderByQuery, setOrderByQuery] = useState(orderByFromQuery);
  const [orderDirectionQuery, setOrderDirection] = useState(
    orderDirectionFromQuery,
  );

  const setSorting = useCallback(
    (orderBy: number, orderDirection: 'desc' | 'asc') => {
      if (orderBy === -1) {
        setOrderByQuery('');
        setOrderDirection('');
      } else {
        const columns = [
          'uploadDate',
          'name',
          'meetingName',
          'voteIssueName',
          'result',
          'reason',
        ];
        setOrderByQuery(columns[orderBy]);
        setOrderDirection(orderDirection);
      }
    },
    [],
  );

  const setFilter = useCallback((filters: Filter<any>[]) => {
    setUploadedDateQuery('');
    setFileNameQuery('');
    setMeetingQuery('');
    setVoteIssueQuery('');
    setResultQuery([]);
    setReasonQuery('');

    filters.forEach((f) => {
      switch (f.column.field) {
        case 'uploadDate':
          setUploadedDateQuery(f.value);
          break;
        case 'name':
          setFileNameQuery(f.value);
          break;
        case 'meetingName':
          setMeetingQuery(f.value);
          break;
        case 'voteIssueName':
          setVoteIssueQuery(f.value);
          break;
        case 'result':
          setResultQuery(f.value);
          break;
        case 'reason':
          setReasonQuery(f.value);
          break;
      }
    });
  }, []);

  const params = useMemo(
    () => ({
      page: pageQuery,
      pageSize: pageSizeQuery,
      uploadDate: uploadedDateQuery,
      name: fileNameQuery,
      meetingName: meetingQuery,
      voteIssueName: voteIssueQuery,
      result: resultQuery,
      reason: reasonQuery,
      orderBy: orderByQuery,
      orderDirection: orderDirectionQuery,
    }),
    [
      uploadedDateQuery,
      fileNameQuery,
      meetingQuery,
      voteIssueQuery,
      resultQuery,
      reasonQuery,
      orderByQuery,
      orderDirectionQuery,
      pageQuery,
      pageSizeQuery,
    ],
  );

  // skips unnecessary query params in URL
  // passing params arrays: x=1&x=2&x=3 instead of x=1,2,3
  const searchQuery = useMemo(() => {
    let searchQuery: string[][] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== '') {
        // typeof value === 'number' for page and pageSize types compatibility
        if (typeof value === 'string' || typeof value === 'number') {
          searchQuery.push([key, `${value}`]);
        } else if (value?.length > 0) {
          value.forEach((value) => {
            searchQuery.push([key, value]);
          });
        }
      }
    });
    return new URLSearchParams(searchQuery);
  }, [params]);

  const value = useMemo(
    () => ({
      pageSizeQuery,
      pageQuery,
      setPageSizeQuery,
      setPageQuery,
      uploadedDateQuery,
      fileNameQuery,
      meetingQuery,
      voteIssueQuery,
      resultQuery,
      reasonQuery,
      setFilter,
      setSorting,
      searchQuery,
      orderByQuery,
      orderDirectionQuery,
    }),
    [
      uploadedDateQuery,
      fileNameQuery,
      meetingQuery,
      voteIssueQuery,
      resultQuery,
      reasonQuery,
      orderByQuery,
      orderDirectionQuery,
      pageQuery,
      pageSizeQuery,
      searchQuery,
      setFilter,
      setSorting,
    ],
  );

  return value;
};
