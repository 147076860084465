import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormControlLabel, Radio } from '@mui/material';
import CheckboxInput from 'components/Inputs/CheckboxInput';
import GroupRadioInput from 'components/Inputs/GroupRadioInput';
import NumberInput from 'components/Inputs/NumberInput';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import Preloader from 'components/Preloader';
import { MeetingDetailsContext } from 'modules/BondholdersMeeting/screens/Meeting/MeetingProvider/MeetingDetailsProvider';
import { VoteIssueBond } from 'utils/types/VoteIssueIsin';
import * as S from './styles';

interface Props {
  selectedBond: VoteIssueBond;
  setSelectedBond: Dispatch<SetStateAction<VoteIssueBond>>;
  matterToBeDecided: string;
}

const AddVoteForm = ({
  selectedBond,
  setSelectedBond,
  matterToBeDecided,
}: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { voteIssueIsins, isLoadingVoteIssueIsins } = useContext(
    MeetingDetailsContext
  );

  const [bondsOptions, setBondsOptions] = useState<VoteIssueBond[]>();

  useEffect(() => {
    if (voteIssueIsins) {
      setBondsOptions(voteIssueIsins);
    }
  }, [voteIssueIsins]);

  const handleChangeBond = (value: string) => {
    if (bondsOptions) {
      const option = bondsOptions?.filter(({ isin }) => isin === value)[0];
      setSelectedBond(option);
    }
  };

  const isin = useWatch({
    control,
    name: 'isin',
  });

  useEffect(() => {
    if (bondsOptions?.length === 1 && isin == null) {
      setValue('isin', bondsOptions[0].isin);
      setSelectedBond(bondsOptions[0]);
    }
  }, [bondsOptions, isin, setSelectedBond, setValue]);

  return isLoadingVoteIssueIsins ? (
    <Preloader />
  ) : (
    <div>
      <Select
        data-testid="add-edit-vote-isin-select"
        name="isin"
        label="ISINs"
        control={control}
        options={(voteIssueIsins || []).map(({ isin }) => isin)}
        onChange={handleChangeBond}
        errors={errors}
        required
        autoFocus
      />
      <TextInput
        name="bondholder"
        label="Bondholder"
        control={control}
        errors={errors}
        required
        maxCharactersLength={200}
      />
      <TextInput
        name="fundManagerCompany"
        label="Fund Manager Company"
        control={control}
        errors={errors}
        maxCharactersLength={200}
      />
      <TextInput
        name="custodian"
        label="Custodian"
        control={control}
        errors={errors}
        maxCharactersLength={200}
      />
      <NumberInput
        name="amount"
        label="Amount"
        endAdornment={
          <S.CurrencyAdornment position="end">
            {selectedBond.currency}
          </S.CurrencyAdornment>
        }
        thousandSeparator={' '}
        control={control}
        errors={errors}
        required
        setValue={setValue}
      />
      <CheckboxInput
        name="isVisible"
        label="Can show our identity and amount of Bonds owned"
        color="primary"
        control={control}
        errors={errors}
      />
      <CheckboxInput
        name="isResultVisible"
        label="Can show our vote"
        color="primary"
        control={control}
        errors={errors}
      />
      <S.GroupRadioWrapper>
        <GroupRadioInput
          name="result"
          label={matterToBeDecided}
          control={control}
          errors={errors}
          required
          row
        >
          <FormControlLabel
            value="Yes"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="No"
            control={<Radio color="primary" />}
            label="No"
          />
          <FormControlLabel
            value="NoVote"
            control={<Radio color="primary" />}
            label="No Vote"
          />
        </GroupRadioInput>
      </S.GroupRadioWrapper>
    </div>
  );
};

export default AddVoteForm;
