import styled from 'styled-components';
import theme, { MARGIN } from 'theme/theme';

export const MainContainer = styled.main`
  min-height: calc(100vh - 148px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 2rem 0.5rem 1rem 0.5rem;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
  max-width: ${`${theme.breakpoints.values.xl - 2 * MARGIN}px`};
`;
