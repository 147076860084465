import styled from 'styled-components';

export const RadioButtonsWrapper = styled.div`
  padding-top: 20px;

  && .MuiFormControl-root {
    width: 50%;
  }

  && .MuiFormGroup-root {
    flex-direction: row;
    justify-content: space-between;
  }
`;
