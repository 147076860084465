import styled from 'styled-components';
import theme from 'theme/theme';

export const ParagraphContainer = styled.div`
  display: flex;
`;

export const LeftSideParagraph = styled.div`
  padding: 0.5rem 0;
  color: ${theme.palette.text.secondary};
  text-transform: capitalize;
`;

export const RightSideParagraph = styled.div`
  padding: 0.5rem 0;
  margin-left: 0.5rem;
  color: ${theme.palette.text.primary};
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 25%;
  white-space: nowrap;
`;
