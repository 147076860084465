import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { registerMaxLength } from 'utils/validators/textValidator';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';

type CustomRule = (value: string) => boolean | string;
export interface AdvancedProps {
  defaultValue?: string;
  onBlur?: (value: string) => void;
  rules?: Record<string, CustomRule>;
}

export interface Props extends AdvancedProps {
  ['data-testid']?: string;
  required?: boolean;
  name: string;
  label?: string;
  autoFocus?: boolean;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  maxCharactersLength?: number;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const TextInputController = ({ defaultValue = '', rules, ...props }: Props) => {
  const label = props.label && props.required ? `${props.label}*` : props.label;
  const blur =
    (
      baseBlur: (e: {
        target: EventTarget & (HTMLInputElement | HTMLTextAreaElement);
      }) => void,
      extraBlur?: (value: string) => void
    ) =>
    (e: { target: EventTarget & (HTMLInputElement | HTMLTextAreaElement) }) => {
      if (extraBlur) {
        extraBlur(e.target?.value || '');
      }
      baseBlur(e);
    };
  const error = props.errors && helperText(props.name, props.errors);
  return (
    <div>
      <Controller
        defaultValue={defaultValue}
        name={props.name}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <TextField
            autoFocus={props.autoFocus}
            name={name}
            label={label}
            onChange={(e) => {
              props.onChange != null
                ? props.onChange(e.target.value)
                : onChange(e.target.value);
            }}
            inputProps={{
              'data-testid': props['data-testid'],
            }}
            onBlur={blur(onBlur, props.onBlur)}
            value={value || ''}
            size="small"
            margin={'dense'}
            fullWidth
            disabled={props.disabled}
          />
        )}
        control={props.control}
        rules={{
          ...registerRequiredRule(props.required),
          ...registerMaxLength(props.maxCharactersLength ?? 100),
          validate: { ...rules },
        }}
      />
      {error && <ErrorLabel title={error}>{error}</ErrorLabel>}
    </div>
  );
};

export default TextInputController;
