import { Box, Chip, Tooltip } from '@mui/material';
import theme, { meetingResultColor } from 'theme/theme';
import { addSpaceBeforeCapitalLetters } from 'utils/formatters/textFormatter';
import { MeetingResult } from 'utils/types/MeetingResult';
import { VoteIssueResult } from 'utils/types/VoteIssueResult';

// TODO: styled-components
export const getChipList = (
  items: string[] | null | undefined,
  count = 2,
  maxLabelLength = 12
) => {
  if (items === null || items === undefined) return '';

  const visibleItems = items.length >= count ? items.slice(0, count) : items;
  const hiddenItems =
    items.length > count ? items.slice(count, items.length) : [];

  return (
    <Box display="flex" gap="5px">
      {visibleItems.map((item) => (
        <Tooltip title={item.length > maxLabelLength ? item : ''} key={item}>
          <Chip
            variant="outlined"
            label={
              item.length > maxLabelLength
                ? `${item.substring(0, maxLabelLength)}...`
                : item
            }
            size="small"
          />
        </Tooltip>
      ))}
      {hiddenItems.length ? (
        <Tooltip title={hiddenItems.join('\n')}>
          <span style={{ color: theme.palette.text.secondary }}>
            {hiddenItems.length} more...
          </span>
        </Tooltip>
      ) : null}
    </Box>
  );
};

export const getResultChip = (result: MeetingResult | VoteIssueResult) =>
  result ? (
    <Chip
      variant="outlined"
      label={addSpaceBeforeCapitalLetters(result)}
      style={{
        color: meetingResultColor[result],
        borderColor: meetingResultColor[result],
      }}
      size="small"
    />
  ) : null;

export const downloadFile = (blob: File) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = blob.name;
  a.click();
  window.URL.revokeObjectURL(url);
};
