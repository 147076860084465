import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export type UseToggle = [isOpen: boolean, handleOpen: () => void, handleClose: () => void];

export const useToggle = (): UseToggle => {
    const [open, setOpen] = useState(false);
    return [open, () => setOpen(true), () => setOpen(false)];
}
export const useSearchParamToggle = (
    searchParamName: string
): UseToggle => {
  const history = useHistory();

  const [isOpen, searchParams] = useMemo(() => {
    const searchParams = new URLSearchParams(history.location.search);
    return [searchParams.has(searchParamName), searchParams];
  }, [history.location.search, searchParamName]);

  const setParam = useCallback(
      (on: boolean) => {
        if (on) {
            searchParams.set(searchParamName, '');
        } else {
          searchParams.delete(searchParamName);
        }
        const search = searchParams.toString().replace(`${searchParamName}=`, searchParamName);
        history.replace({
          ...history.location,
          search,
        });
      },
      [history, searchParams, searchParamName]
  );

  return [isOpen, () => setParam(true), () => setParam(false)];
};