import { CompanyAdvisors } from 'utils/types/CompanyAdvisors';
import { useAxios } from '../useAxios';

export const useGetCompanyAdvisors = () => {
  const [
    { data: companyAdvisors, loading: isLoadingCompanyAdvisors },
    getAdvisors,
  ] = useAxios<CompanyAdvisors[]>(
    { method: 'GET' },
    {
      manual: true,
    },
  );

  return { companyAdvisors, isLoadingCompanyAdvisors, getAdvisors };
};
