import { useAxios } from 'utils/hooks/useAxios';

export const usePutAdvisorsData = (bondholdersMeetingId: string) => {
  const [, putAdvisorsData] = useAxios(
    {
      url: `/meetings/${bondholdersMeetingId}/advisors`,
      method: 'PUT',
    },
    {
      manual: true,
    },
  );
  return { putAdvisorsData };
};
