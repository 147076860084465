import { useAuth } from 'context/AuthProvider';
import Card from 'components/Card';
import { VoteIssue } from 'modules/BondholdersMeeting/screens/Meeting/types';
import { useSearchParamToggle as useToggle } from 'utils/hooks/useToggle';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { MeetingType } from 'utils/types/MeetingType';
import { TrusteeType } from 'utils/types/TrusteeType';
import { IndexedVoteIssue } from '../types';
import VoteIssuesForm from './EditDialog';
import { MattersList } from './MattersList';

interface Props {
  status?: MeetingStatus;
  indexedVoteIssues: IndexedVoteIssue[];
  isLoadingVoteIssues: boolean;
  getVoteIssues: AxiosPromiseGeneric<VoteIssue[]>;
  trusteeType?: TrusteeType;
  meetingType?: MeetingType;
}

export const MattersToBeDecided = ({
  status,
  indexedVoteIssues,
  isLoadingVoteIssues,
  getVoteIssues,
  trusteeType,
  meetingType,
}: Props) => {
  const { isInternalEmployeeRole } = useAuth();

  const [isFormOpen, openForm, closeForm] = useToggle('matters-form');

  return (
    <Card
      title="Matters to be decided"
      data-testid="matters-to-be-decided"
      canEdit={
        isInternalEmployeeRole &&
        !(status === 'Canceled' || status === 'Closed')
      }
      withoutPadding
      onEditClick={openForm}
    >
      <MattersList isLoading={isLoadingVoteIssues} data={indexedVoteIssues} />
      {isFormOpen && (
        <VoteIssuesForm
          status={status}
          onClose={closeForm}
          data={indexedVoteIssues}
          getVoteIssues={getVoteIssues}
          trusteeType={trusteeType}
          meetingType={meetingType}
        />
      )}
    </Card>
  );
};
