import { Table } from '@nordictrustee/nt-ui-library';
import { IndexedVoteIssue } from 'modules/BondholdersMeeting/screens/Meeting/types';
import {
  getChipList,
  getResultChip,
} from 'modules/BondholdersMeeting/screens/Meetings/utils';

const voteIssueColumn = {
  title: 'Matter to be decided',
  field: 'voteIssue',
  type: 'string',
  render: ({ index, matter }: IndexedVoteIssue) => `${index}. ${matter}`,
};

const isinColumn = (width: string) => {
  return {
    title: 'ISINs',
    field: 'isins',
    width: width,
    render: ({ isins }: IndexedVoteIssue) => getChipList(isins),
  };
};

export const internalColumns: Table.Column<IndexedVoteIssue>[] = [
  voteIssueColumn as Table.Column<IndexedVoteIssue>,
  isinColumn('45%'),
  {
    title: 'Result',
    field: 'result',
    width: '20%',
    render: ({ result }) => result && getResultChip(result),
  },
];

export const externalColumns: Table.Column<IndexedVoteIssue>[] = [
  voteIssueColumn as Table.Column<IndexedVoteIssue>,
  isinColumn('55%'),
];
