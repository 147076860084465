import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { MeetingDetailsContext } from 'modules/BondholdersMeeting/screens/Meeting/MeetingProvider/MeetingDetailsProvider';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import { useQuery } from 'utils/hooks/useQuery';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { VoteIssueBond } from 'utils/types/VoteIssueIsin';
import { VoteDetails, VoteDocument, VoteStatus } from '../../types';
import BondholdersMeetingVotesAddDocumentsForm from './components/AddDocumentsForm';
import BondholdersMeetingVotesAddVoteForm from './components/AddVoteForm';
import BondholdersMeetingVotesApproveForm from './components/BondholdersMeetingVotesApproveForm';
import * as api from './api';
import * as S from './styles';
import * as T from './types';

interface Props {
  status?: MeetingStatus;
  handleCloseForm: () => void;
  editMode: boolean;
  editedItem?: VoteDetails;
  matterToBeDecided: string;
}

type Step = 'Add Vote' | 'Add Documents' | 'Approve';

const VotesDetailsForm = ({
  handleCloseForm,
  editMode,
  editedItem,
  status,
  matterToBeDecided,
}: Props) => {
  const query = useQuery();
  const voteIssueIdFromQuery = useMemo(
    () => query.get('voteIssueId') || '',
    [query]
  );

  const { getVotesDetails, getStatisticsData } = useContext(
    MeetingDetailsContext
  );

  const [currentStepNumber, setCurrentStepNumber] = useState(0);

  const [selectedBond, setSelectedBond] = useState<VoteIssueBond>({
    id: editedItem?.meetingBondID,
    isin: editedItem?.isin || '',
    currency: editedItem?.currency || '',
  });
  const [voteDocuments, setVoteDocuments] = useState<(File | VoteDocument)[]>(
    editedItem?.documents || []
  );

  const { isLoadingPutVoteDocuments, uploadVoteDocuments } =
    api.usePutVoteDocuments();
  const { isLoadingPostVote, postVote } = api.usePostVote(voteIssueIdFromQuery);
  const { isLoadingPutVote, updateVote } = api.usePutVote(voteIssueIdFromQuery);

  const editModeAndClosedCanceledStatus =
    editMode &&
    (status === MeetingStatus.Closed || status === MeetingStatus.Canceled);

  const stepNames: Step[] = useMemo(
    () =>
      editModeAndClosedCanceledStatus
        ? ['Add Documents']
        : ['Add Vote', 'Add Documents', 'Approve'],
    [editModeAndClosedCanceledStatus]
  );

  const form = useForm<T.BondholdersMeetingVotesAddVoteFields>({
    mode: 'onBlur',
    defaultValues: {
      status: VoteStatus.NotApproved,
    },
  });
  const {
    trigger,
    getValues,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = form;

  useEffect(() => {
    if (editMode) {
      reset({
        ...editedItem,
        status:
          editedItem?.status === VoteStatus.Approved
            ? VoteStatus.NotApproved
            : editedItem?.status,
      });
    }
  }, [reset, editMode, editedItem]);

  const isLoading = useMemo(
    () => isLoadingPutVoteDocuments || isLoadingPostVote || isLoadingPutVote,
    [isLoadingPostVote, isLoadingPutVote, isLoadingPutVoteDocuments]
  );

  const onSubmit = handleSubmit(async () => {
    const valid = await trigger();
    if (valid && voteIssueIdFromQuery) {
      try {
        const details = getValues();
        const data = {
          meetingBondID: selectedBond.id,
          bondholder: details.bondholder,
          fundManagerCompany: details.fundManagerCompany,
          custodian: details.custodian,
          amount: details.amount,
          isVisible: details.isVisible,
          isResultVisible: details.isResultVisible,
          result: details.result,
          currency: selectedBond.currency,
          status: details.status,
        };
        const fileToUpload = voteDocuments.filter(
          (file: File | VoteDocument) => !file.hasOwnProperty('id')
        ) as File[];

        if (!editMode) {
          const response = await postVote({ data });
          const voteId = response.data.id;
          if (fileToUpload && voteId) {
            await handleUploadDocuments(voteId, fileToUpload);
          }
        }
        if (editMode && editedItem) {
          const voteId = editedItem.id;
          await updateVote(voteId, data);
          if (fileToUpload) {
            await handleUploadDocuments(voteId, fileToUpload);
          }
        }
        toast.success(
          editMode ? 'Vote has been updated' : 'Vote has been created'
        );
        if (getVotesDetails) await getVotesDetails();
        if (getStatisticsData) await getStatisticsData();
        handleCloseForm();
      } catch (e) {
        handleException(e);
      }
    }
  });

  const handleUploadDocuments = async (
    voteId: number,
    filesToUpload: File[]
  ) => {
    try {
      await uploadVoteDocuments(voteId, filesToUpload);
    } catch (e) {
      handleException(e);
    }
  };

  const goToNextStep = async (index: number) => {
    const isPreviousStep = index < currentStepNumber;
    if (isPreviousStep) {
      setCurrentStepNumber(index);
      return;
    }
    if (stepNames[currentStepNumber] === 'Add Vote') {
      const valid = await trigger();
      if (valid) {
        setCurrentStepNumber(index);
      }
      return;
    }
    if (stepNames[currentStepNumber] === 'Add Documents') {
      setCurrentStepNumber(index);
    }
  };

  const handleConfirmClose = () => {
    confirmClose(!!Object.keys(dirtyFields).length, handleCloseForm);
  };

  return (
    <Dialog
      data-testid="vote-dialog"
      title={editMode ? 'Edit Vote' : 'Add Vote'}
      onClose={handleConfirmClose}
      maxWidth="md"
      subheader={
        editModeAndClosedCanceledStatus ? undefined : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {stepNames.map((stepName, index) => (
              <Box display="flex" alignItems="center" key={stepName}>
                <S.StepButton
                  variant="text"
                  onClick={() =>
                    index !== currentStepNumber ? goToNextStep(index) : {}
                  }
                >
                  <S.StepBadge $isPrimary={currentStepNumber >= index}>
                    {currentStepNumber >= index + 1 ? (
                      <S.DoneStepIcon />
                    ) : (
                      index + 1
                    )}
                  </S.StepBadge>
                  {stepName}
                </S.StepButton>
              </Box>
            ))}
          </Box>
        )
      }
      dialogActions={
        !editMode ? (
          <>
            <Button
              isLoading={isLoading}
              onClick={
                currentStepNumber === stepNames.length - 1
                  ? onSubmit
                  : () => goToNextStep(currentStepNumber + 1)
              }
            >
              {currentStepNumber === stepNames.length - 1 ? 'Save' : 'Continue'}
            </Button>
            <Button variant="outlined" onClick={handleConfirmClose}>
              Cancel
            </Button>
            {currentStepNumber > 0 && (
              <>
                <Button
                  onClick={() => setCurrentStepNumber((current) => current - 1)}
                >
                  Back
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <Button isLoading={isLoading} onClick={onSubmit}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleConfirmClose}>
              Cancel
            </Button>
          </>
        )
      }
    >
      <S.ContentWrapper>
        <FormProvider {...form}>
          {stepNames[currentStepNumber] === 'Add Vote' && (
            <BondholdersMeetingVotesAddVoteForm
              selectedBond={selectedBond}
              setSelectedBond={setSelectedBond}
              matterToBeDecided={matterToBeDecided}
            />
          )}
          {stepNames[currentStepNumber] === 'Add Documents' && (
            <BondholdersMeetingVotesAddDocumentsForm
              voteDocuments={voteDocuments}
              setVoteDocuments={setVoteDocuments}
              trigger={trigger}
              editedItem={editedItem}
            />
          )}
          {stepNames[currentStepNumber] === 'Approve' && (
            <BondholdersMeetingVotesApproveForm />
          )}
        </FormProvider>
      </S.ContentWrapper>
    </Dialog>
  );
};

export default VotesDetailsForm;
