import { useMemo } from 'react';
import * as api from './api';

const useGetMeetingTypeOptions = () => {
  const {
    meetingTypeOptions,
    isLoadingMeetingTypeOptions,
    getMeetingTypeOptions,
  } = api.useGetMeetingTypeOptions();

  const value = useMemo(
    () => ({
      meetingTypeOptions,
      isLoadingMeetingTypeOptions,
      getMeetingTypeOptions,
    }),
    [getMeetingTypeOptions, isLoadingMeetingTypeOptions, meetingTypeOptions],
  );

  return value;
};

export default useGetMeetingTypeOptions;
