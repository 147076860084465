import React from 'react';
import { Link, Typography } from '@mui/material';
import { useAuth } from 'context/AuthProvider';
import * as S from './styles';

const PageFooter = () => {
  const { isAuthenticated, isInternalEmployeeRole } = useAuth();
  return (
    <S.Footer>
      {!isInternalEmployeeRole && (
        <>
          <Typography variant="body1">&copy; Nordic Trustee</Typography>
          <Typography variant="body1">
            <Link target="_blank" href="https://nordictrustee.com/contact">
              Contact
            </Link>
          </Typography>
          {isAuthenticated && (
            <>
              <Typography variant="body1">
                <Link href="/terms">Terms of Use</Link>
              </Typography>
            </>
          )}
        </>
      )}
    </S.Footer>
  );
};

export default PageFooter;
