import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  max-height: 48px;
`;
