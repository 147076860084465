import { Chip } from '@mui/material';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { ChipVariant } from './types';
import { getStylesForStatusChip } from './utils';

interface Props {
  status?: MeetingStatus | null;
  label?: string;
  variant?: ChipVariant;
}

const StatusChip = ({
  status,
  label = status!,
  variant = ChipVariant.outlined,
}: Props) =>
  status ? (
    <Chip
      variant={variant}
      label={label}
      style={getStylesForStatusChip(status, variant)}
      size={variant === ChipVariant.outlined ? 'small' : 'medium'}
    />
  ) : null;

export default StatusChip;
