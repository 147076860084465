import React from 'react';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { MeetingType } from 'utils/types/MeetingType';
import { TrusteeType } from 'utils/types/TrusteeType';
import * as S from '../styles';

type VpsCheckProps = {
  trusteeType?: TrusteeType;
  meetingType?: MeetingType;
  vpsVotesMustBeChecked: boolean;
  handleHasCheckedVpsVotesChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  hasCheckedVpsVotes: boolean;
  canDisplayError: boolean;
};
const VpsCheck: React.FC<VpsCheckProps> = ({
  trusteeType,
  meetingType,
  vpsVotesMustBeChecked,
  handleHasCheckedVpsVotesChange,
  hasCheckedVpsVotes,
  canDisplayError,
}) => {
  if (
    !(
      trusteeType === TrusteeType.NordicTrusteeNO &&
      meetingType === MeetingType.BondholderMeeting
    )
  ) {
    return null;
  }

  return (
    <S.FormControl error={vpsVotesMustBeChecked} variant="standard">
      <FormControlLabel
        control={
          <Checkbox
            name="hasCheckedVpsVotes"
            color="primary"
            onChange={handleHasCheckedVpsVotesChange}
            checked={hasCheckedVpsVotes}
            required={vpsVotesMustBeChecked}
          />
        }
        label={`VPS votes has been checked${vpsVotesMustBeChecked ? '*' : ''}`}
      />
      <FormHelperText id="component-error-text">
        {canDisplayError && vpsVotesMustBeChecked && !hasCheckedVpsVotes
          ? 'This field is required'
          : ''}
      </FormHelperText>
    </S.FormControl>
  );
};
export default VpsCheck;
