import { useAuth } from 'context/AuthProvider';
import Card from 'components/Card';
import { getFormattedDate } from 'utils/formatters/dateFormatter';
import { addSpaceBeforeCapitalLetters } from 'utils/formatters/textFormatter';
import { useToggle } from 'utils/hooks/useToggle';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { TrusteeType } from 'utils/types/TrusteeType';
import { MeetingDetails } from '../types';
import { DataRow } from './DataRow';
import DetailsForm from './DetailsForm';

interface Props {
  isLoading: boolean;
  meetingDetails?: MeetingDetails;
  getMeetingDetails: AxiosPromiseGeneric<MeetingDetails>;
  hasIsins: boolean;
}

const Details = ({
  meetingDetails,
  isLoading,
  getMeetingDetails,
  hasIsins,
}: Props) => {
  const [isDetailsFormOpen, handleOpenDetailsForm, handleCloseDetailsForm] =
    useToggle();

  const { isInternalEmployeeRole } = useAuth();

  return (
    <>
      <Card
        title="Meeting Details"
        data-testid="meeting-details"
        canEdit={isInternalEmployeeRole}
        onEditClick={handleOpenDetailsForm}
        isLoading={isLoading}
      >
        <DataRow
          label="Issuer"
          value={meetingDetails?.meetingIssuer?.companyFunction.name}
        />
        <DataRow
          label="Type"
          value={addSpaceBeforeCapitalLetters(meetingDetails?.type)}
        />
        <DataRow
          label="Voting Deadline"
          value={meetingDetails?.votingDeadline}
        />
        <DataRow
          label="Case Handler"
          value={meetingDetails?.caseHandler?.name}
        />
        {isInternalEmployeeRole && (
          <DataRow
            label="Administrator"
            value={meetingDetails?.administrator?.name}
          />
        )}
        {meetingDetails?.trustee === TrusteeType.NordicTrusteeSE && (
          <DataRow
            label="Record Date"
            value={getFormattedDate(meetingDetails?.recordDate) || ''}
          />
        )}
        <DataRow
          label="Result"
          value={addSpaceBeforeCapitalLetters(meetingDetails?.result)}
        />
        {isInternalEmployeeRole && (
          <DataRow label="Remark" value={meetingDetails?.remark} />
        )}
      </Card>
      {isDetailsFormOpen && (
        <DetailsForm
          onClose={handleCloseDetailsForm}
          meetingDetails={meetingDetails!}
          getMeetingDetails={getMeetingDetails}
          hasIsins={hasIsins}
        />
      )}
    </>
  );
};

export default Details;
