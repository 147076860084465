import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useAuth } from 'context/AuthProvider';
import { VoteIssueStatisticsData } from 'modules/BondholdersMeeting/screens/Meeting/Statistics/types';
import { VoteDetails } from 'modules/BondholdersMeeting/screens/Meeting/types';
import { handleException } from 'utils/handleException';
import { useQuery } from 'utils/hooks/useQuery';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { DictionaryOption } from 'utils/types/Dictionary.types';
import { VoteIssueBond } from 'utils/types/VoteIssueIsin';
import * as api from './api';

export type Props = {
  children: ReactNode;
};

export type ProviderValue = {
  votesDetails: VoteDetails[];
  isLoadingVotesDetails?: boolean;
  getVotesDetails?: AxiosPromiseGeneric<VoteDetails[]>;
  statisticsData: VoteIssueStatisticsData;
  isLoadingStatisticsData?: boolean;
  getStatisticsData?: AxiosPromiseGeneric<VoteIssueStatisticsData>;
  voteStatusOptions: DictionaryOption[];
  isLoadingVoteStatusOptions?: boolean;
  getVoteStatusOptions?: AxiosPromiseGeneric<DictionaryOption[]>;
  voteResultOptions: DictionaryOption[];
  isLoadingVoteResultOptions?: boolean;
  getVoteResultOptions?: AxiosPromiseGeneric<DictionaryOption[]>;
  voteIssueIsins: VoteIssueBond[];
  isLoadingVoteIssueIsins?: boolean;
  getVoteIssueIsins?: AxiosPromiseGeneric<VoteIssueBond[]>;
};

export const MeetingDetailsContext = createContext<ProviderValue>({
  votesDetails: [],
  isLoadingVotesDetails: false,
  getVotesDetails: () => new Promise(() => {}),
  statisticsData: {} as VoteIssueStatisticsData,
  isLoadingStatisticsData: false,
  getStatisticsData: () => new Promise(() => {}),
  voteStatusOptions: [],
  isLoadingVoteStatusOptions: false,
  getVoteStatusOptions: () => new Promise(() => {}),
  voteResultOptions: [],
  isLoadingVoteResultOptions: false,
  getVoteResultOptions: () => new Promise(() => {}),
  voteIssueIsins: [],
  isLoadingVoteIssueIsins: false,
  getVoteIssueIsins: () => new Promise(() => {}),
});

export const MeetingDetailsProvider = ({ children }: Props) => {
  const query = useQuery();
  const { isInternalEmployeeRole } = useAuth();

  const voteIssueIdFromQuery = useMemo(
    () => query.get('voteIssueId') || '',
    [query],
  );

  const { votesDetails, isLoadingVotesDetails, getVotesDetails } =
    api.useGetVotesDetails(voteIssueIdFromQuery);

  const {
    statisticsData = {},
    isLoadingStatisticsData,
    getStatisticsData,
  } = api.useGetStatisticsData(voteIssueIdFromQuery);

  const { voteIssueIsins, isLoadingVoteIssueIsins, getVoteIssueIsins } =
    api.useGetVoteIssueIsins(voteIssueIdFromQuery);

  const {
    voteStatusOptions,
    isLoadingVoteStatusOptions,
    getVoteStatusOptions,
  } = api.useGetVoteStatusOptions();

  const {
    voteResultOptions,
    isLoadingVoteResultOptions,
    getVoteResultOptions,
  } = api.useGetVoteResultOptions();

  const updateVotesDetails = useCallback(async () => {
    try {
      if (voteIssueIdFromQuery) {
        if (getVotesDetails) await getVotesDetails().catch(handleException);
        if (getStatisticsData) await getStatisticsData().catch(handleException);
        if (isInternalEmployeeRole) {
          if (getVoteIssueIsins)
            await getVoteIssueIsins().catch(handleException);
        }
      }
    } catch (e) {
      handleException(e);
    }
  }, [
    getStatisticsData,
    getVoteIssueIsins,
    getVotesDetails,
    isInternalEmployeeRole,
    voteIssueIdFromQuery,
  ]);
  const updateVoteStatus = useCallback(async () => {
    try {
      await getVoteStatusOptions();
    } catch (e) {
      handleException(e);
    }
  }, [getVoteStatusOptions]);
  const updateVoteResult = useCallback(async () => {
    try {
      await getVoteResultOptions();
    } catch (e) {
      handleException(e);
    }
  }, [getVoteResultOptions]);
  useEffect(() => {
    updateVoteStatus();
  }, [updateVoteStatus]);
  useEffect(() => {
    updateVoteResult();
  }, [updateVoteResult]);
  useEffect(() => {
    updateVotesDetails();
  }, [updateVotesDetails]);

  const value = useMemo(
    (): ProviderValue => ({
      votesDetails,
      isLoadingVotesDetails,
      getVotesDetails,
      statisticsData: statisticsData as VoteIssueStatisticsData,
      isLoadingStatisticsData,
      getStatisticsData,
      voteStatusOptions,
      isLoadingVoteStatusOptions,
      getVoteStatusOptions,
      voteResultOptions,
      isLoadingVoteResultOptions,
      getVoteResultOptions,
      voteIssueIsins,
      isLoadingVoteIssueIsins,
      getVoteIssueIsins,
    }),
    [
      getStatisticsData,
      getVoteIssueIsins,
      getVoteResultOptions,
      getVoteStatusOptions,
      getVotesDetails,
      isLoadingStatisticsData,
      isLoadingVoteIssueIsins,
      isLoadingVoteResultOptions,
      isLoadingVoteStatusOptions,
      isLoadingVotesDetails,
      statisticsData,
      voteIssueIsins,
      voteResultOptions,
      voteStatusOptions,
      votesDetails,
    ],
  );

  return (
    <MeetingDetailsContext.Provider value={value}>
      {children}
    </MeetingDetailsContext.Provider>
  );
};
