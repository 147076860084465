import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { Control, DeepMap, FieldError, UseFormTrigger } from 'react-hook-form';
import { Box } from '@mui/material';
import Dropzone from 'components/Dropzone';
import FileInput from 'components/Inputs/FileInput';
import { helperText } from 'utils/reactHookFormUtils';
import { fileInputCustomStyle } from '../../VotesDocumentForm.styles';
import FilesList from '../FilesList';

interface Props {
  control: Control;
  errors: DeepMap<object, FieldError>;
  setVotesFile: Dispatch<SetStateAction<File | undefined>>;
  votesFile?: File;
  trigger: UseFormTrigger<any>; // TODO - type
  setValue: any;
}

const UploadVotesFileStep = ({
  control,
  errors,
  setVotesFile,
  votesFile,
  trigger,
  setValue,
}: Props) => {
  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = (event?.currentTarget as HTMLInputElement)?.files![0];
    if (file) {
      setVotesFile(file);
    }
    trigger();
  };

  const dropAction = async (droppedFile: File) => {
    setVotesFile(droppedFile);
    trigger();
  };

  const deleteVotesFile = async () => {
    setVotesFile(undefined);
    setValue('votes', '');
    trigger();
  };

  useEffect(() => {
    if (votesFile) {
      setValue('votes', votesFile);
    }
  }, [setValue, votesFile]);

  return (
    <Dropzone
      onDropCallback={dropAction}
      acceptFormats=".xlsx, .xls"
      maxSize={25}
    >
      <Box height="200px">
        <FileInput
          control={control}
          acceptFormat=".xlsx, .xls"
          name="votes"
          data-testid="votes-file"
          onChangeCallback={handleFileInputChange}
          text="Choose file"
          style={fileInputCustomStyle}
          isRequired
          error={helperText('votes', errors)}
          label="Documents"
          disabled={!!votesFile}
          tooltipText={votesFile ? 'Delete added file to upload a new one' : ''}
        />
        {votesFile && (
          <FilesList files={[votesFile]} onDelete={deleteVotesFile} />
        )}
      </Box>
    </Dropzone>
  );
};

export default UploadVotesFileStep;
