import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cached from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Card, Tooltip, Typography } from '@mui/material';
import {
  DataLink,
  SearchableMultiSelectFilter,
  Table,
} from '@nordictrustee/nt-ui-library';
import { CENTER_COLUMN } from 'components/Table/const';
import { getLookup } from 'utils/getLookUpFromOptions';
import { useToggle } from 'utils/hooks/useToggle';
import { getCompareDates } from 'utils/sortCompares';
import * as URL from '../../router/url';
import { useGetUploadedFilesResultOptions } from '../../utils/hooks/useGetUploadFilesResultOptions/api';
import ReasonPreviewDialog from './components/ReasonPreviewDialog/ReasonPreviewDialog';
import * as api from './api';
import * as S from './styles';
import { UploadedFile } from './types';
import { useUploadedFilesQuery } from './useUploadedFilesQuery';
import { getResultChip } from './utils';

const UploadedFiles = () => {
  const { replace } = useHistory();

  const [selectedItem, setSelectedItem] = useState<UploadedFile>();

  const {
    searchQuery,
    pageQuery,
    setPageQuery,
    pageSizeQuery,
    setPageSizeQuery,
    uploadedDateQuery,
    fileNameQuery,
    meetingQuery,
    voteIssueQuery,
    resultQuery,
    reasonQuery,
    orderByQuery,
    orderDirectionQuery,
    setFilter,
    setSorting,
  } = useUploadedFilesQuery();

  const {
    getUploadedFilesResultOptions,
    uploadedFilesResultOptions,
    isLoadingUploadedFilesResultOptions,
  } = useGetUploadedFilesResultOptions();

  const { uploadedFiles, isLoadingUploadedFiles, getUploadedFiles } =
    api.useGetUploadedFiles(searchQuery);

  useEffect(() => {
    getUploadedFilesResultOptions();
  }, [getUploadedFilesResultOptions]);

  useEffect(() => {
    replace({
      pathname: URL.UPLOADED_FILES,
      search: searchQuery.toString(),
    });
    getUploadedFiles();
  }, [getUploadedFiles, replace, searchQuery]);

  const handleRefreshList = () => {
    getUploadedFiles();
  };

  const [
    isReasonPreviewOpen,
    handleOpenReasonPreview,
    handleCloseReasonPreview,
  ] = useToggle();

  const tableOptions: Table.Options<UploadedFile> = useMemo(
    () => ({
      sorting: true,
      filtering: true,
      search: false,
      minBodyHeight: '75vh',
      maxBodyHeight: '75vh',
      pageSize: pageSizeQuery,
      tableLayout: 'fixed',
      rowStyle: { height: 48 },
    }),
    [pageSizeQuery]
  );

  const isLoading = useMemo(
    () => isLoadingUploadedFiles || isLoadingUploadedFilesResultOptions,
    [isLoadingUploadedFiles, isLoadingUploadedFilesResultOptions]
  );

  const columns: Table.Column<UploadedFile>[] = useMemo(
    () =>
      (
        [
          {
            title: 'Uploaded Date',
            field: 'uploadDate',
            width: '11%',
            type: 'string',
            defaultFilter: uploadedDateQuery,
            customSort: (a: UploadedFile, b: UploadedFile) =>
              getCompareDates(a.uploadDate, b.uploadDate),
            ...CENTER_COLUMN,
          },
          {
            title: 'File Name',
            field: 'name',
            width: '19%',
            type: 'string',
            defaultFilter: fileNameQuery,
          },
          {
            title: 'Meeting',
            field: 'meetingName',
            width: '20%',
            type: 'string',
            defaultFilter: meetingQuery,
          },
          {
            title: 'Matter to be decided',
            field: 'voteIssueName',
            width: '20%',
            type: 'string',
            defaultFilter: voteIssueQuery,
          },
          {
            title: 'Result',
            field: 'result',
            width: '10%',
            render: ({ result }) => result && getResultChip(result),
            filterComponent: SearchableMultiSelectFilter,
            defaultFilter: resultQuery,
            lookup: getLookup(uploadedFilesResultOptions),
            ...CENTER_COLUMN,
          },
          {
            title: 'Reason',
            field: 'reason',
            width: '20%',
            type: 'string',
            defaultFilter: reasonQuery,
          },
        ] as Table.Column<UploadedFile>[]
      ).map((column) => ({
        ...column,
        // magic sorting fixture:
        ...(orderByQuery === column.field && {
          defaultSort: orderDirectionQuery,
        }),
      })) as Table.Column<UploadedFile>[],
    [
      uploadedDateQuery,
      fileNameQuery,
      meetingQuery,
      voteIssueQuery,
      reasonQuery,
      resultQuery,
      uploadedFilesResultOptions,
      orderByQuery,
      orderDirectionQuery,
    ]
  );

  const onPreviewClick = (selectedItem: UploadedFile) => {
    setSelectedItem(selectedItem);
    handleOpenReasonPreview();
  };

  const Toolbar = () => (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title="Refresh the list">
        <S.RefreshButton
          onClick={handleRefreshList}
          aria-label="refresh-uploads-list"
        >
          <Cached color="action" />
        </S.RefreshButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ pb: '24px' }}>
        <Typography variant="h2">Uploaded Files</Typography>
      </Box>
      <Card data-testid="uploded-files">
        <Table.Root
          actions={[
            ({ meetingID }: UploadedFile) => ({
              icon: () => (
                <DataLink
                  isArrowIcon
                  to={`${URL.BONDHOLDERS_MEETINGS}/${meetingID}`}
                />
              ),
              tooltip: 'Go to Meeting Meeting',
              onClick: () => {},
            }),
            (rowData: UploadedFile) => ({
              icon: () => <SearchIcon color="action" />,
              tooltip: 'View reason',
              onClick: () => onPreviewClick(rowData),
            }),
          ]}
          columns={columns}
          data={uploadedFiles?.results || []}
          options={tableOptions}
          isLoading={isLoading}
          onOrderChange={setSorting}
          onFilterChange={setFilter}
          onPageChange={setPageQuery}
          onRowsPerPageChange={setPageSizeQuery}
          page={pageQuery}
          totalCount={uploadedFiles?.objectsCount ?? 0}
          onRowClick={() => {}}
          components={{
            Toolbar,
          }}
        />
      </Card>
      {isReasonPreviewOpen && (
        <ReasonPreviewDialog
          reason={selectedItem?.reason || ''}
          handleClose={handleCloseReasonPreview}
        />
      )}
    </>
  );
};

export default UploadedFiles;
