import { ReactNode } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';

interface Props {
  name: string;
  label: string;
  errors: DeepMap<FieldValues, FieldError>;
  required?: boolean;
  control: Control<any>;
  endAdornment?: ReactNode;
  disabled?: boolean;
  thousandSeparator?: boolean | string;
  onValueChange?: (value: string) => void;
  defaultValue?: string;
  setValue: (key: any, value: any) => void;
}

const NumberInput = ({
  name,
  label,
  errors,
  required,
  control,
  onValueChange,
  disabled,
  endAdornment,
  thousandSeparator,
  defaultValue,
  setValue,
}: Props) => {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={registerRequiredRule(required)}
        defaultValue={defaultValue ?? ''}
        render={({ field: { value, name: fieldName, onBlur } }) => (
          <NumberFormat
            name={fieldName}
            value={value}
            onValueChange={({ value }) => {
              setValue(`${name}`, value);
              onValueChange && onValueChange(value);
            }}
            label={`${label}${label && required ? '*' : ''}`}
            customInput={TextField}
            InputProps={{
              endAdornment: endAdornment ? endAdornment : null,
            }}
            fullWidth
            thousandSeparator={thousandSeparator}
            allowedDecimalSeparators={[',', '.']}
            margin={'dense'}
            onBlur={onBlur}
            disabled={disabled}
          />
        )}
      />
      <ErrorLabel data-testid="error-label">
        {errors && helperText(name, errors)}
      </ErrorLabel>
    </div>
  );
};

export default NumberInput;
