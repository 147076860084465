/* eslint-disable max-len */
import React from 'react';
import { Container, Link, Typography } from '@mui/material';
import Card from 'components/Card';
import TextParagraph from 'components/TextParagraph';
import * as S from './styles';

export const Terms = () => (
  <Container maxWidth="xl">
    <Card
      data-testid="Terms"
      customHeader={
        <S.TermHeader>
          <Typography variant="h3">
            CONDITIONS FOR USE OF THE VOTING PORTAL
          </Typography>
        </S.TermHeader>
      }
    >
      <S.TermBody>
        <TextParagraph header="1. Scope">
          <Typography variant="body1">
            Nordic Trustee provides a service that gives Advisors access to the
            Voting Portal through their Users. These Conditions governs access
            and use of the Voting Portal.
          </Typography>
          <br />
          <Typography variant="body1">
            By accessing the Voting Portal, the User agrees to be bound by the
            Conditions.
          </Typography>
        </TextParagraph>
        <TextParagraph header="2. Definitions">
          <Typography>
            <strong>“Advisor” </strong>
            means a professional advisor firm, engaged by an Issuer in a process
            which includes the relevant Bondholders’ Meeting.
          </Typography>
          <br />
          <Typography>
            <strong>“Bondholder” </strong>
            means an investor in a bond.
          </Typography>
          <br />
          <Typography>
            <strong>“Bondholders’ Meeting” </strong>
            means a bondholders’ meeting, or a written resolution, summoned by
            Nordic Trustee according to the relevant bond terms.
          </Typography>
          <br />
          <Typography>
            <strong>“Issuer” </strong>
            means a company which has issued one or more bonds.
          </Typography>
          <br />

          <Typography>
            <strong>“Nordic Trustee” </strong>
            means, as applicable, Nordic Trustee AS (Norway), Nordic Trustee &
            Agency AB (publ) (Sweden), Nordic Trustee Oy (Finland), Nordic
            Trustee A/S (Denmark), or any of its affiliates.
          </Typography>
          <br />
          <Typography>
            <strong>“Voting Portal” </strong>
            means the digital, web-based service platform designed and developed
            (and exclusively owned) by Nordic Trustee to support the Advisors
            with their monitoring of the voting process of Bondholders’ Meetings
            and is accessible at{' '}
            <Link href="https://votingportal.nordictrustee.com" target="_blank">
              https://votingportal.nordictrustee.com
            </Link>
            .
          </Typography>
          <br />
          <Typography>
            <strong>“User” </strong>
            means a natural person at an Advisor with access to the Voting
            Portal.
          </Typography>
          <br />
          <Typography>
            <strong>“User Account” </strong>
            Means the Users’ individual account for accessing the Voting Portal
          </Typography>
        </TextParagraph>
        <TextParagraph header="3. Right to access the Voting Portal ">
          <Typography variant="body1">
            An Advisor may appoint one or several Users with access to the
            Voting Portal on behalf of the Advisor, for use in situations where
            the Advisor is appointed by an Issuer regarding an actual
            Bondholders’ Meeting. A User may be appointed on a case by case
            basis, or on continuous basis.
          </Typography>
          <br />
          <Typography variant="body1">
            A User may only access Bondholders’ Meetings for which the Advisor
            is appointed by an Issuer, an only when the User is appointed for
            such Bondholders’ Meeting.
          </Typography>
          <br />
          <Typography variant="body1">
            Each User shall be authorized by Nordic Trustee.
          </Typography>
        </TextParagraph>
        <TextParagraph header="4.	User access and safety">
          <Typography variant="body1">
            A User is identified by its unique e-mail address. A User shall
            create a User Account and provide the access data requested by
            Nordic Trustee.
          </Typography>
          <br />
          <Typography variant="body1">
            The User is responsible for the information on a User Account. A
            User Account is personal, and the User shall not give any other
            person access to a User Account without Nordic Trustee’s prior
            written approval. The User shall promptly notify Nordic Trustee if
            unpermitted accessed is suspected or discovered.
          </Typography>
        </TextParagraph>
        <TextParagraph header="5.	Restricted information on the Voting Portal">
          <Typography variant="body1">
            A User may access restricted information at the Voting Portal, both
            in relation to personal data (GDPR), insider information and other
            confidentiality conditions. Such information shall be handled with
            the necessary care and in accordance with relevant legislation and
            policy at the Advisor.
          </Typography>
          <br />
          <Typography variant="body1">
            For personal data, the User is, through the Advisor, given access to
            data by consent from the relevant Bondholder(s). This information
            shall only be used according to its purpose and be kept
            confidential.
          </Typography>
        </TextParagraph>
        <TextParagraph header="6.	Information on the Voting Portal">
          <Typography variant="body1">
            Information on the Voting Portal is provided by Nordic Trustee on a
            best effort basis for the Advisor and User’s convenience and does
            not affect any obligations, agreements or any other arrangements
            between the Issuer, the Advisor, Nordic Trustee, any Bondholder or
            their agents.
          </Typography>
          <br />
          <Typography variant="body1">
            If any inconsistencies exist between the information in the Voting
            Portal and the actual tabulation of votes in a Bondholders’ Meeting,
            the latter will prevail.
          </Typography>
          <br />
          <Typography variant="body1">
            Nordic Trustee shall have no responsibility for the information on
            the Voting Portal. Nordic Trustee does not guarantee that the Voting
            Portal is up-to-date, complete, free of errors or available at all
            times, and does not take any responsibility for the accuracy and
            completeness of any information or documentation provided by Nordic
            Trustee in the Voting Portal. The User shall promptly inform Nordic
            Trustee of any discovered incorrections on the Voting Portal.
          </Typography>
          <br />
          <Typography variant="body1">
            Nordic Trustee reserves the right to modify, add or remove, in part
            or in whole, material, content and features presented on the Voting
            Portal at any time. Nordic Trustee may also change these Conditions
            at any time and will notify you of any material changes. By
            continuing to use the Voting Portal after the date of revision, the
            User accepts to be bound by the changes.
          </Typography>
        </TextParagraph>
        <TextParagraph header="7.	Intellectual Property rights">
          <Typography variant="body1">
            Nordic Trustee has the right to any information and other material
            (including texts, picture material, graphical interfaces and
            databases) on the Voting Portal. Users are not permitted to make any
            copies or redistribution of any information or other material
            without the prior written consent from Nordic Trustee. Users of the
            Voting Portal are not permitted to make any amendments of any
            information of the Voting Portal, or otherwise take any actions
            which commercially exploit the Voting Portal.
          </Typography>
        </TextParagraph>
        <TextParagraph header="8.	Limitation of liability ">
          <Typography variant="body1">
            Nordic Trustee shall never be liable towards the User or the Advisor
            for any damage or loss of any kind arising from or relating to the
            Voting Portal. Nordic Trustee will use its best efforts to maintain
            adequate security to prevent unlawful access by third parties to
            Nordic Trustee’s infrastructures.
          </Typography>
          <br />
          <Typography variant="body1">
            No content and data available on the Voting Portal shall be
            construed as Nordic Trustee providing financial, investment, legal,
            tax or other advice of any kind.
          </Typography>
        </TextParagraph>
        <TextParagraph header="9.	User data">
          <Typography variant="body1">
            The Users data collected through the Voting Portal is only for
            internal use and will not be handed over to any external parties.
            Nordic Trustee only collects information that is required to fulfill
            the purpose of the actual service, however Nordic Trustee may use
            external technology providers (such as Hotjar and Google Analytics)
            in order to use data for better understanding of Nordic Trustee’s
            customers’ needs and to optimize the Voting Portal. Such external
            providers may use cookies and other technologies to collect data on
            Users’ behavior and their devices. This data does not contain any
            personally identifiable information and will never be used to
            identify individual Users. More information about Nordic Trustee’s
            use of cookies can also be found at{' '}
            <Link href="https://www.nordictrustee.com/privacy" target="_blank">
              https://www.nordictrustee.com/privacy/
            </Link>
            .
          </Typography>
          <br />
          <Typography variant="body1">
            All personal data provided by a User will be handled in accordance
            with relevant data protection regulations (GDPR), please see current
            privacy statement at our group website (
            <Link
              href="https://nordictrustee.com/about/governance"
              target="_blank"
            >
              https://nordictrustee.com/about/governance/
            </Link>
            ).
          </Typography>
        </TextParagraph>
        <TextParagraph header="10.	Termination of a User's access to the Voting Portal">
          <Typography variant="body1">
            Nordic Trustee may, by written notice to the User, suspend or
            terminate a User’s access to the Voting Portal in case of (i) any
            breach or misuse by a the User or these Conditions, (ii) unpaid fees
            in respect of the Voting Portal (to the extent applicable) or (iii)
            if Nordic Trustee for any reason is unable to provide Users access
            to the Voting Portal.
          </Typography>
        </TextParagraph>
        <TextParagraph header="11.	Fees">
          <Typography variant="body1">
            The Voting Portal is a part of Nordic Trustee's service offering as
            bond trustee. Nordic Trustee may introduce a fee for the access to
            the Voting Portal with 1 month notice, and otherwise for any
            additional services or features subject in each case to the user's
            consent
          </Typography>
        </TextParagraph>
        <TextParagraph header="12.	Notices">
          <Typography variant="body1">
            Nordic Trustee may change these General Terms and Conditions at any
            time and will notify the User of any changes by sending an email to
            the address(es) associated with the User. By continuing to use the
            Voting Portal after the date of revision, the User agrees to be
            bound by the changes
          </Typography>
        </TextParagraph>
        <TextParagraph header="13.	Governing Law and Jurisdiction">
          <Typography variant="body1">
            These Conditions shall be exclusively governed by the laws of
            Norway. All disputes arising out of, or in connection with these
            Conditions shall be exclusively resolved by the courts of Norway,
            with the District Court of Oslo as first legal venue.
          </Typography>
        </TextParagraph>
      </S.TermBody>
    </Card>
  </Container>
);
