export const addSpaceBeforeCapitalLetters = (text?: string) =>
  text?.replace(/([A-Z])/g, ' $1')?.trim() ?? '';

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getAcronym = (text: string) => {
  return text !== '' ? text.toUpperCase().match(/\b\w/g)!.join('') : '';
}
