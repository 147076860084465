import { useMemo } from 'react';
import * as api from './api';

const useGetCurrencyOptions = () => {
  const {
    currencyTypeOptions,
    isLoadingCurrencyTypeOptions,
    getCurrencyTypeOptions,
  } = api.useGetCurrencyTypeOptions();

  const value = useMemo(
    () => ({
      currencyTypeOptions,
      isLoadingCurrencyTypeOptions,
      getCurrencyTypeOptions,
    }),
    [currencyTypeOptions, getCurrencyTypeOptions, isLoadingCurrencyTypeOptions],
  );

  return value;
};

export default useGetCurrencyOptions;
