import BigNumber from 'bignumber.js';
import { statisticsVotesReceivedColor as graphColor } from 'theme/theme';
import { StatisticsStatusData } from '../../types';
import { fractionToPercent as fToP } from '../../utils';

export const generateData = (data: StatisticsStatusData) => {
  const { NoVote, VotesApproved, VotesNotApproved } = data;
  const noVoteValue = new BigNumber(NoVote);
  const votesApprovedValue = new BigNumber(VotesApproved);
  const votesNotApprovedValue = new BigNumber(VotesNotApproved);

  const votesSum = noVoteValue
    .plus(votesApprovedValue)
    .plus(votesNotApprovedValue);

  const values = [
    {
      name: 'Votes Approved',
      value: VotesApproved,
      label: fToP(votesApprovedValue, votesSum),
      color: graphColor.votesApproved,
    },
    {
      name: 'Votes Not Approved',
      value: VotesNotApproved,
      label: fToP(votesNotApprovedValue, votesSum),
      color: graphColor.votesNotApproved,
    },
    {
      name: 'Not Voted',
      value: NoVote,
      label: fToP(noVoteValue, votesSum),
      color: graphColor.notVoted,
    },
  ];
  return {
    chartLabel: 'Quorum Status',
    values,
  };
};
