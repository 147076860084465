import { useFormContext } from 'react-hook-form';
import { FormControlLabel, Radio } from '@mui/material';
import GroupRadioInput from 'components/Inputs/GroupRadioInput';
import * as S from './styles';

const ApproveForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const voteStatusOptions = [
    { value: 'NotApproved', label: 'Not Approved' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Deleted', label: 'Deleted' },
  ];

  return (
    <S.RadioButtonsWrapper>
      <GroupRadioInput
        name="status"
        control={control}
        errors={errors}
        children={voteStatusOptions.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            label={option.label}
            control={<Radio color="primary" />}
          />
        ))}
      ></GroupRadioInput>
    </S.RadioButtonsWrapper>
  );
};

export default ApproveForm;
