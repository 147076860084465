import { useState } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import {
  DesktopDateTimePicker,
  LocalizationProvider,
  PickersLocaleText,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import { dateAndTimeDisplayFormat } from 'utils/constants';
import { helperText } from 'utils/reactHookFormUtils';
import { dateTimeValidator } from 'utils/validators';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  disabled?: boolean;
  localeText?: Partial<PickersLocaleText<unknown>>;
}

const DateTimeInput = (props: Props) => {
  // internalError is used as a 'onChange' validation - before React Hook Form launch its own 'onBlur' validation
  // we want to see (or not) an error right after user put last character in the Date Input (still having focus on that field)
  const [internalError, setInternalError] = useState<string>();

  const dataValidation = (value: Moment | null) => {
    const basicDateValidationError = dateTimeValidator(value);
    setInternalError(basicDateValidationError);
    return basicDateValidationError;
  };

  return (
    <div>
      <Controller
        rules={{
          ...registerRequiredRule(props.required),
          validate: dataValidation,
        }}
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value, name, onBlur, ref } }) => (
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            localeText={{ clearButtonLabel: 'CLEAR', ...props.localeText }}
          >
            <DesktopDateTimePicker
              renderInput={({
                inputProps,
                ...params
              }: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField
                  {...params}
                  sx={{
                    'input::placeholder': {
                      textTransform: 'upperCase',
                    },
                  }}
                  error={false}
                  autoFocus={props.autoFocus}
                  inputProps={{
                    ...inputProps,
                    name: name,
                    style: {
                      color: '', // TODO: add red for error
                    },
                    placeholder: dateAndTimeDisplayFormat,
                  }}
                  onBlur={(_: any) => {
                    onBlur();
                  }}
                  margin="dense"
                  fullWidth
                  autoComplete="off"
                  style={{
                    textTransform: 'capitalize',
                    width: '100%',
                  }}
                  name={props.name}
                />
              )}
              autoFocus={props.autoFocus}
              label={`${props.label} (${dateAndTimeDisplayFormat})${
                props.required ? '*' : ''
              }`}
              value={value}
              inputFormat={dateAndTimeDisplayFormat}
              onChange={(date: any) => {
                onChange(date);
                // to check if all the fields are filled in:
                if (!date?.parsingFlags()?.charsLeftOver) {
                  dataValidation(date);
                  onBlur();
                }
              }}
              // replaced with custom validation (dateTimeValidator)
              disabled={props.disabled}
              inputRef={ref}
            />
          </LocalizationProvider>
        )}
        defaultValue={null}
      />
      <ErrorLabel data-testid="error-label">
        {(props.errors || internalError) &&
          helperText(props.name, props.errors)}
      </ErrorLabel>
    </div>
  );
};

export default DateTimeInput;
