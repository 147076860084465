import { Link as RLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(RLink)<{
  nopadding?: string;
  display?: string;
}>`
  color: inherit;
  display: ${({ display }) => (display ? display : 'grid')};
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: ${({ nopadding }) => (nopadding === 'true' ? '0' : '0.5rem')};  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
