import { useCallback, useState } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import BigNumber from 'bignumber.js';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import NumberInput from 'components/Inputs/NumberInput';
import {
  FIRST_COLUMN_NO_PADDING,
  TOP_BOTTOM_OF_CELL_NO_PADDING,
} from 'components/Table/const';
import { currencyFormat } from 'utils/constants';
import { IsinFormFields, IsinFormType } from '../types';
import * as S from './styles';

interface Props {
  data: IsinFormFields[];
  control: Control<IsinFormType>;
  errors: DeepMap<object, FieldError>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  selectedCurrency: string;
  onRemoveClick: (index: number, bondID: string) => void;
  isinsAssignedToVoteIssues: string[];
}

const IsinsFormTable = ({
  data,
  control,
  errors,
  setValue,
  getValues,
  selectedCurrency,
  onRemoveClick,
  isinsAssignedToVoteIssues,
}: Props) => {
  // TODO -
  // 1. remove ts ignore - update interfaces - add calculated values
  // 2. move to utils
  // 3. try to find reusable parts for converted value calculation
  // 4. values from defaultValue are changed to default after adding a new record - fix

  const [editRows, setEditRows] = useState<IsinFormFields[]>([]);
  const disabledRow = (index: number) => {
    return !editRows.find((el) => el.index === index);
  };

  const onEditClick = useCallback(
    (rowData) => {
      const isAlreadyInList = editRows.find(
        (item) => item.index === rowData.index
      );
      if (isAlreadyInList) {
        const filteredArray = editRows.filter(
          (item) => item.index !== rowData.index
        );
        setEditRows(filteredArray);
      } else {
        setEditRows([...editRows, rowData]);
      }
    },
    [editRows]
  );

  const handleOutstandingAmountChange = useCallback(
    (outstandingAmount: NumberFormatValues, index: number) => {
      const conversion = getValues(`${index}.conversionRate`);
      const calculatedOutstandingAmount = new BigNumber(
        outstandingAmount.floatValue ?? ''
      )
        ?.multipliedBy(conversion ? conversion : 0)
        ?.toFormat(currencyFormat);

      setValue(
        `${index}.convertedOutstandingAmount`,
        calculatedOutstandingAmount
      );
    },
    [getValues, setValue]
  );

  const handleIssuerBondAmountChange = useCallback(
    (issuerBondAmount: NumberFormatValues, index: number) => {
      const conversion = getValues(`${index}.conversionRate`);
      const calculatedIssuerBondAmount = new BigNumber(
        issuerBondAmount.floatValue ?? ''
      )
        ?.multipliedBy(conversion ? conversion : 0)
        ?.toFormat(currencyFormat);

      setValue(
        `${index}.convertedIssuerBondAmount`,
        calculatedIssuerBondAmount
      );
    },
    [getValues, setValue]
  );

  const handleConversionChange = useCallback(
    (conversion: string, index: number) => {
      const outstandingAmount = getValues(`${index}.outstandingAmount`);
      const issuerBondAmount = getValues(`${index}.issuerBondAmount`);

      const calculatedOutstandingAmount = new BigNumber(
        outstandingAmount ? outstandingAmount : 0
      )
        ?.multipliedBy(conversion)
        ?.toFormat(currencyFormat);
      const calculatedIssuerBondAmount = new BigNumber(
        issuerBondAmount ? issuerBondAmount : 0
      )
        ?.multipliedBy(conversion)
        ?.toFormat(currencyFormat);

      setValue(
        `${index}.convertedIssuerBondAmount`,
        calculatedIssuerBondAmount
      );
      setValue(
        `${index}.convertedOutstandingAmount`,
        calculatedOutstandingAmount
      );
    },
    [getValues, setValue]
  );

  const canDeleteIsin = (isin: string | null) => {
    if (isin === null) return true;
    return (
      isinsAssignedToVoteIssues.filter((assignedIsin) => assignedIsin === isin)
        .length <= 0
    );
  };

  const columns: Table.Column<IsinFormFields>[] = [
    {
      title: 'ISIN',
      field: 'isin',
      width: '15%',
      disableClick: true,
      ...FIRST_COLUMN_NO_PADDING,
    },
    {
      title: 'Outstanding Amount* / Converted',
      width: '35%',
      disableClick: true,
      render: ({ bondCurrency, outstandingAmount, conversionRate, index }) => {
        return (
          <S.ColumnGrid container alignItems="flex-end" spacing={2}>
            <Grid item xs={6}>
              <CurrencyInput
                control={control}
                name={`${index}.outstandingAmount`}
                errors={errors}
                currency={bondCurrency || ''}
                label=""
                required
                disabled={disabledRow(index)}
                setValue={setValue}
                onValueChange={(outstandingAmount) =>
                  handleOutstandingAmountChange(outstandingAmount, index)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                control={control}
                name={`${index}.convertedOutstandingAmount`}
                errors={errors}
                currency={selectedCurrency}
                label=""
                disabled={disabledRow(index)}
                defaultValue={new BigNumber(outstandingAmount)
                  ?.multipliedBy(conversionRate)
                  ?.toFormat(currencyFormat)}
                setValue={setValue}
              />
            </Grid>
          </S.ColumnGrid>
        );
      },
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
    {
      title: 'Issuers Bond Amount / Converted',
      width: '30%',
      disableClick: true,
      render: ({ bondCurrency, issuerBondAmount, conversionRate, index }) => {
        return (
          <S.ColumnGrid container alignItems="flex-end" spacing={2}>
            <Grid item xs={6}>
              <CurrencyInput
                control={control}
                name={`${index}.issuerBondAmount`}
                errors={errors}
                currency={bondCurrency || ''}
                label=""
                disabled={disabledRow(index)}
                setValue={setValue}
                onValueChange={(outstandingAmount) =>
                  handleIssuerBondAmountChange(outstandingAmount, index)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                control={control}
                name={`${index}.convertedIssuerBondAmount`}
                errors={errors}
                currency={selectedCurrency}
                label=""
                disabled={disabledRow(index)}
                defaultValue={new BigNumber(issuerBondAmount)
                  ?.multipliedBy(conversionRate)
                  ?.toFormat(currencyFormat)}
                setValue={setValue}
              />
            </Grid>
          </S.ColumnGrid>
        );
      },
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
    {
      title: 'Conversion*',
      width: '10%',
      disableClick: true,
      render: ({ index }) => {
        return (
          <S.Conversion>
            <NumberInput
              label=""
              control={control}
              errors={errors}
              name={`${index}.conversionRate`}
              disabled={disabledRow(index)}
              required
              onValueChange={(conversion: string) =>
                handleConversionChange(conversion, index)
              }
              setValue={setValue}
            />
          </S.Conversion>
        );
      },
      ...TOP_BOTTOM_OF_CELL_NO_PADDING,
    },
  ];

  return (
    <Table.Root<IsinFormFields>
      actions={[
        (rowData) => ({
          icon: () => (
            <EditIcon data-testid="edit-vote-icon" color={'action'} />
          ),
          tooltip: 'Edit',
          onClick: () => onEditClick(rowData),
        }),
        (rowData) => {
          const isIsinRemovable = canDeleteIsin(rowData.isin);
          return {
            icon: () => (
              <DeleteIcon color={isIsinRemovable ? 'action' : 'disabled'} />
            ),
            tooltip: isIsinRemovable
              ? ''
              : 'Unassign this ISIN from the Matters to be decided to remove it',
            onClick: () => {
              onRemoveClick(rowData.index, rowData.bondID);
            },
            disabled: !isIsinRemovable,
          };
        },
      ]}
      data={data}
      columns={columns}
      options={{
        toolbar: false,
        paging: false,
        maxBodyHeight: '20rem',
      }}
    />
  );
};

export default IsinsFormTable;
