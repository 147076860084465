import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const ErrorHeader = styled(Typography)`
  &.MuiTypography-h2 {
    text-align: center;
    color: #0000008a;
  }
`;

export const ErrorContainer = styled(Box)`
  display: flex;
  text-align: center;
  height: calc(100vh - 8rem);
  flex-direction: column;
  justify-content: center;
`;
