import Button from 'components/Button';
import Dialog from 'components/Dialog';

interface Props {
  title: string;
  message: string;
  isLoading?: boolean;
  submitText?: string;
  cancelText?: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const ConfirmDialog = ({
  title,
  message,
  isLoading,
  submitText = 'OK',
  cancelText = 'Cancel',
  onSubmit,
  onCancel,
}: Props) => {
  return (
    <Dialog
      title={title}
      onClose={onCancel}
      maxWidth="xs"
      data-testid="confirm-delete-user-dialog"
      dialogActions={
        <>
          <Button
            variant="contained"
            onClick={onSubmit}
            data-testid="submit-button"
            isLoading={isLoading}
          >
            {submitText}
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            data-testid="cancel-button"
          >
            {cancelText}
          </Button>
        </>
      }
    >
      {message}
    </Dialog>
  );
};

export default ConfirmDialog;
