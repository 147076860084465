import { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Dropzone from 'components/Dropzone';
import FileInput from 'components/Inputs/FileInput';
import { downloadFile } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import { handleException } from 'utils/handleException';
import { confirmClose } from 'utils/hooks/confirmClose';
import { helperText } from 'utils/reactHookFormUtils';
import { AxiosPromiseGeneric } from 'utils/types/AxiosPromiseGeneric';
import { RouteParam } from 'utils/types/RouteParam';
import * as api from '../api';
import FilesList from '../FilesList';
import MeetingDocument from '../types';

interface Props {
  onClose: () => void;
  isLoadingDocuments: boolean;
  documents: MeetingDocument[];
  getMeetingDocuments: AxiosPromiseGeneric<MeetingDocument[]>;
  getDocument: (id: number, name: string) => Promise<File>;
}

const DocumentsForm = ({
  onClose,
  isLoadingDocuments,
  documents,
  getMeetingDocuments,
  getDocument,
}: Props) => {
  const form = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    trigger,
  } = form;

  const { bondholdersMeetingId } = useParams<RouteParam>();

  const [meetingDocuments, setMeetingDocuments] =
    useState<(File | MeetingDocument)[]>(documents);

  const [filesToDelete, setFilesToDelete] = useState<MeetingDocument[]>([]);

  const handleFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      var files = (event?.currentTarget as HTMLInputElement)?.files;
      if (files) {
        const filesAsArray = Array.from(files);
        setMeetingDocuments((prevState) =>
          prevState ? [...prevState, ...filesAsArray] : filesAsArray
        );
        trigger();
      }
    },
    [trigger]
  );

  const { uploadVoteDocuments, isLoadingPutMeetingDocuments } =
    api.usePutMeetingDocuments(bondholdersMeetingId);

  const { deleteMeetingDocument, isLoadingDeleteDocument } =
    api.useDeleteMeetingDocument(bondholdersMeetingId);

  const dropAction = async (droppedFile: File[]) => {
    setMeetingDocuments((prevState) =>
      prevState ? [...prevState, ...droppedFile] : droppedFile
    );
    trigger();
  };

  const handleDelete = useCallback(async (file: File | MeetingDocument) => {
    setMeetingDocuments((prevState) => prevState.filter((el) => el !== file));
    if (file.hasOwnProperty('id')) {
      const fileToDelete = file as MeetingDocument;
      setFilesToDelete((prevState) => [...prevState, ...[fileToDelete]]);
    }
  }, []);

  const handleDownloadFile = useCallback(
    async (file: File | MeetingDocument) => {
      let blob;
      if (file.hasOwnProperty('id')) {
        try {
          blob = await getDocument((file as MeetingDocument).id, file.name);
        } catch (e) {
          handleException(e);
        }
      } else {
        blob = file;
      }
      if (blob) {
        downloadFile(blob as File);
      }
    },
    [getDocument]
  );

  const onSubmit = handleSubmit(async () => {
    const valid = await trigger();
    if (valid) {
      try {
        const fileToUpload = meetingDocuments.filter(
          (file: File | MeetingDocument) => !file.hasOwnProperty('id')
        ) as File[];

        if (fileToUpload) {
          await uploadVoteDocuments(fileToUpload);
        }

        if (filesToDelete.length) {
          for (let i = 0; i < filesToDelete.length; i++) {
            await deleteMeetingDocument(filesToDelete[i]);
          }
          toast.success(
            `Deleted files: ${filesToDelete.map(({ name }) => `\n${name}`)}`
          );
        }

        toast.success('Meeting documents has been updated');
        getMeetingDocuments();
        onClose();
      } catch (e) {
        handleException(e);
      }
    }
  });

  const handleConfirmClose = () => {
    confirmClose(isDirty, onClose);
  };

  return (
    <Dialog
      title="Meeting documents"
      data-testid="meeting-documents"
      onClose={handleConfirmClose}
      loading={isLoadingDocuments}
      dialogActions={
        <>
          <Button
            data-testid="meeting-documents-save-button"
            onClick={onSubmit}
            isLoading={isLoadingDeleteDocument || isLoadingPutMeetingDocuments}
          >
            Save
          </Button>
          <Button
            data-testid="meeting-documents-cancel-button"
            variant="outlined"
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
        </>
      }
    >
      <Dropzone
        multiple
        onDropCallback={dropAction}
        acceptFormats=""
        maxSize={25}
      >
        <Box height="400px">
          <FileInput
            control={control}
            acceptFormat=""
            name="meetingDocuments"
            onChangeCallback={handleFileInputChange}
            data-testid="meeting-documents"
            text="Choose file"
            multiple
            error={helperText('meetingDocuments', errors)}
            label="Documents"
          />
          {meetingDocuments && (
            <FilesList
              files={meetingDocuments}
              onDelete={handleDelete}
              onDownload={handleDownloadFile}
            />
          )}
        </Box>
      </Dropzone>
    </Dialog>
  );
};

export default DocumentsForm;
