import { meetingStatusColor } from 'theme/theme';
import { MeetingStatus } from 'utils/types/MeetingStatus';
import { ChipVariant } from './types';

export const getStylesForStatusChip = (
  status: MeetingStatus,
  variant: ChipVariant,
) => {
  switch (variant) {
    case ChipVariant.outlined: {
      return {
        color: meetingStatusColor[status],
        borderColor: meetingStatusColor[status],
      };
    }
    case ChipVariant.default: {
      return {
        backgroundColor: meetingStatusColor[status],
        borderColor: meetingStatusColor[status],
        color: '#ffffff',
      };
    }
  }
};
