import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MenuButton } from '@nordictrustee/nt-ui-library';
import { GetLoginRequest } from 'authConfig';
import { useAuth } from 'context/AuthProvider';
import { AuthMethod } from 'context/AuthProvider/types';
import * as URL from 'router/url';
import { Environment } from 'utils/types/Environment.types';
import EnvironmentChip from './EnvironmentChip';
import Logo from './Logo';
import * as S from './styles';

const Header = () => {
  const { instance } = useMsal();
  const { isAuthenticated, user, setUser, isInternalEmployeeRole } = useAuth();

  const { ENVIRONMENT } = window._env_;
  const { push } = useHistory();

  const menuLinks = useMemo(() => {
    return [
      { label: 'Bondholders Meeting', link: URL.BONDHOLDERS_MEETINGS },
      ...(isInternalEmployeeRole
        ? [{ label: 'Uploaded Files', link: URL.UPLOADED_FILES }]
        : []),
      ...(isInternalEmployeeRole
        ? [{ label: 'User Management', link: URL.USER_MANAGEMENT }]
        : []),
    ];
  }, [isInternalEmployeeRole]);

  const handleLogOut = () => {
    localStorage.removeItem('state');
    if (user?.authMethod === AuthMethod.Microsoft) {
      instance.logoutRedirect();
    } else if (user?.authMethod === AuthMethod.IdentityService) {
      setUser(undefined);
      push('/');
    }
  };

  const loginRequest = GetLoginRequest();

  const handleLogIn = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <S.Header>
      <S.HeaderToolbar>
        <S.LeftToolbar>
          <S.MainMenuBox>
            <S.LogoLink to={URL.BONDHOLDERS_MEETINGS}>
              <Logo />
            </S.LogoLink>
          </S.MainMenuBox>
          {ENVIRONMENT && ENVIRONMENT !== Environment.prod && (
            <EnvironmentChip environment={ENVIRONMENT} />
          )}
        </S.LeftToolbar>
        <S.RightToolbar data-testid="header-menu-items">
          {isAuthenticated ? (
            <>
              {menuLinks.map(({ link, label }) => (
                <S.MainMenuBox key={link}>
                  <S.MainMenuLink to={link}>{label}</S.MainMenuLink>
                </S.MainMenuBox>
              ))}
            </>
          ) : (
            <>
              <S.MainMenuBox>
                <S.MainMenuLink to="">Login</S.MainMenuLink>
              </S.MainMenuBox>
            </>
          )}
          <MenuButton transformX={-10} icon={<AccountCircleIcon />}>
            {isAuthenticated ? (
              <S.MenuItem
                data-testid="logout-button"
                onClick={handleLogOut}
                variant="text"
              >
                Logout
              </S.MenuItem>
            ) : (
              <S.MenuItem
                data-testid="login-button"
                onClick={handleLogIn}
                variant="text"
              >
                NT Login
              </S.MenuItem>
            )}
          </MenuButton>
        </S.RightToolbar>
      </S.HeaderToolbar>
    </S.Header>
  );
};

export default Header;
