import { useAxios } from 'utils/hooks/useAxios';

export const usePostBondholdersMeeting = () => {
  const [{ loading: isPostBondholdersMeetingLoading }, postBondholdersMeeting] =
    useAxios(
      { url: `/meetings`, method: 'POST' },
      {
        manual: true,
      },
    );
  return { isPostBondholdersMeetingLoading, postBondholdersMeeting };
};
