import { memo } from 'react';
import { Box, Card, Typography } from '@mui/material';

interface Props {
  text: string;
  customHeight?: string;
  'data-testid': string;
}

const PlaceholderBox = ({
  text,
  customHeight,
  'data-testid': dataTestId,
}: Props) => (
  <Card style={{ height: customHeight ?? '100%' }} data-testid={dataTestId}>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography variant="h5">{text}</Typography>
    </Box>
  </Card>
);

export default memo(PlaceholderBox);
