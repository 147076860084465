import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import Button from 'components/Button';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import NumberInput from 'components/Inputs/NumberInput';
import Select from 'components/Inputs/Select';
import Preloader from 'components/Preloader';
import { CENTER_COLUMN, FIRST_COLUMN_NO_PADDING } from 'components/Table/const';
import { calculateAmountWithConversion } from 'utils/calculateAmountWithConversion';
import useGetCurrencyOptions from 'utils/hooks/useGetCurrencyOptions';
import { IsinAddFormFields } from '../types';
import * as api from './api';
import * as S from './styles';
import { IsinSelectOption } from './types';

interface Props {
  tableData: IsinAddFormFields[];
  setTableData: Dispatch<SetStateAction<IsinAddFormFields[]>>;
}

const BondholdersMeetingAddISINsForm = ({ tableData, setTableData }: Props) => {
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useFormContext();

  const [availableIsinOptions, setAvailableIsinOptions] = useState<
    IsinSelectOption[]
  >([]);

  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedBond, setSelectedBond] = useState<
    IsinSelectOption | undefined
  >();
  const [isConversionDisabled, setIsConversionDisabled] = useState(false);

  const issuerId = useWatch({
    control,
    name: 'issuerID',
  });

  const {
    currencyTypeOptions,
    isLoadingCurrencyTypeOptions,
    getCurrencyTypeOptions,
  } = useGetCurrencyOptions();

  const {
    bondsForIssuer,
    isLoadingBondsForIssuer,
    getBondsForIssuer,
    bondsForIssuerResponse,
  } = api.useGetBondsForIssuer(issuerId);

  useEffect(() => {
    getCurrencyTypeOptions();
    getBondsForIssuer();
  }, [getBondsForIssuer, getCurrencyTypeOptions]);

  const isinOptions = useMemo(
    () =>
      bondsForIssuer.map(({ bondID, isin, currency, outstandingAmount }) => ({
        label: isin,
        value: bondID,
        data: { currency, outstandingAmount },
      })),
    [bondsForIssuer]
  );

  // TODO - do not fetch again options - fix it
  useEffect(() => {
    if (bondsForIssuerResponse) {
      setAvailableIsinOptions(isinOptions);
    }
  }, [bondsForIssuerResponse, isinOptions]);

  const tableColumns: Table.Column<IsinAddFormFields>[] = useMemo(
    () =>
      [
        {
          title: 'ISIN',
          field: 'isin',
          type: 'string',
          width: '27%',
          ...FIRST_COLUMN_NO_PADDING,
        },
        {
          title: 'Outstanding Amount/Converted',
          field: 'outstandingAmount',
          type: 'string',
          width: '28%',
          render: ({
            outstandingAmount,
            conversionRate,
            currency,
            conversionCurrency,
          }) =>
            calculateAmountWithConversion(
              outstandingAmount,
              currency,
              conversionCurrency,
              conversionRate
            ),
        },
        {
          title: 'Issuers Bond Amount/Converted',
          field: 'issuerBondAmount',
          type: 'string',
          width: '28%',
          render: ({
            issuerBondAmount,
            conversionRate,
            currency,
            conversionCurrency,
          }) =>
            calculateAmountWithConversion(
              issuerBondAmount,
              currency,
              conversionCurrency,
              conversionRate
            ),
        },
        {
          title: 'Conversion',
          field: 'conversionRate',
          type: 'string',
          ...CENTER_COLUMN,
        },
        {
          title: 'Currency',
          field: 'conversionCurrency',
          hidden: true,
        },
      ] as Table.Column<IsinAddFormFields>[],
    []
  );

  const tableOptions: Table.Options<IsinAddFormFields> = useMemo(
    () => ({ paging: false, maxBodyHeight: '20vh', toolbar: false }),
    []
  );

  useEffect(() => {
    const conversionRate = tableData.filter(
      (el) => el.currency === selectedBond?.data?.currency
    )[0]?.conversionRate;

    if (conversionRate) {
      setValue('conversionRate', conversionRate);
      setIsConversionDisabled(true);
    } else {
      setValue('conversionRate', '');
      setIsConversionDisabled(false);
    }
  }, [selectedBond, setValue, tableData]);

  const onDeleteClick = useCallback(
    ({ id, isin, outstandingAmount, currency, bondID }: IsinAddFormFields) => {
      // restore the isin to the list
      setAvailableIsinOptions((prev) => [
        ...prev,
        {
          value: bondID,
          label: isin,
          data: { outstandingAmount, currency },
        },
      ]);
      // update the table
      setTableData((prev) => prev.filter((row) => row.id !== id));
      setSelectedCurrency('');
    },
    [setTableData]
  );

  const tableActions = useMemo(
    () => [
      (rowData: IsinAddFormFields) => ({
        icon: () => <DeleteIcon color="action" />,
        tooltip: 'Delete',
        onClick: () => onDeleteClick(rowData),
      }),
    ],
    [onDeleteClick]
  );

  const onAddClick = useCallback(async () => {
    const valid = await trigger();
    if (valid) {
      let {
        bondID,
        outstandingAmount,
        issuerBondAmount,
        conversion,
        conversionCurrency,
        conversionRate,
      } = getValues();

      if (issuerBondAmount === '') {
        issuerBondAmount = '0';
      }

      // remove selected isin from the list
      setAvailableIsinOptions((prev) =>
        prev.filter(({ value }) => value !== bondID)
      );

      // update the table
      setTableData((prev) => [
        ...prev,
        {
          id: Math.random(),
          isin: isinOptions.find(({ value }) => value === bondID)?.label,
          bondID,
          outstandingAmount,
          issuerBondAmount,
          conversion,
          currency: selectedCurrency,
          conversionCurrency,
          conversionRate,
        } as IsinAddFormFields,
      ]);
      // reset the form
      // using setValue() instead of reset() because of the issue:
      // https://github.com/react-hook-form/react-hook-form/discussions/5224#discussioncomment-1134559
      setValue('bondID', null);
      setValue('outstandingAmount', '');
      setValue('issuerBondAmount', '0');
      setValue('conversionRate', '');
      setSelectedCurrency('');
      setIsConversionDisabled(false);
    }
  }, [
    getValues,
    isinOptions,
    selectedCurrency,
    setTableData,
    setValue,
    trigger,
  ]);

  const onCurrencyChange = useCallback(
    (value) => {
      if (tableData.length) {
        setTableData((prev) =>
          prev.map((data) => ({ ...data, currency: value }))
        );
      }
    },
    [setTableData, tableData]
  );

  const handleIsinChange = (bondID: string) => {
    const selectedBond = isinOptions.find(({ value }) => value === bondID);
    setSelectedBond(selectedBond);
    setValue('outstandingAmount', selectedBond?.data?.outstandingAmount);
    setSelectedCurrency(selectedBond?.data?.currency ?? '');
  };

  const conversionCurrency: string = useWatch({
    control,
    name: 'conversionCurrency',
  });

  useEffect(() => {
    if (conversionCurrency != null && conversionCurrency === selectedCurrency) {
      setValue('conversionRate', 1);
      setIsConversionDisabled(true);
    }

    if (
      conversionCurrency != null &&
      conversionCurrency !== selectedCurrency &&
      tableData.length === 0
    ) {
      setValue('conversionRate', '');
      setIsConversionDisabled(false);
    }

    if (selectedCurrency && conversionCurrency == null) {
      setValue('conversionCurrency', selectedCurrency);
    }

    if (isinOptions.length > 0 && conversionCurrency == null) {
      const hasMoreThanOneCurrency =
        isinOptions.filter(
          (i) => i.data.currency !== isinOptions[0].data.currency
        ).length > 0;

      if (!hasMoreThanOneCurrency) {
        setValue('conversionCurrency', isinOptions[0].data.currency);
      }
    }
  }, [
    conversionCurrency,
    isinOptions,
    selectedCurrency,
    setValue,
    tableData.length,
  ]);

  return isLoadingCurrencyTypeOptions || isLoadingBondsForIssuer ? (
    <Preloader />
  ) : (
    <div data-testid="isins-fields">
      <S.CurrencyWrapper>
        <Select
          name="conversionCurrency"
          label="Common currency"
          options={currencyTypeOptions}
          required
          errors={errors}
          control={control}
          disabled={!!tableData.length}
          onChange={onCurrencyChange}
          autoFocus
        />
      </S.CurrencyWrapper>
      <S.AddSectionWrapper>
        <Select
          name="bondID"
          label="ISIN"
          options={availableIsinOptions}
          required
          errors={errors}
          control={control}
          onChange={handleIsinChange}
        />
        <CurrencyInput
          control={control}
          name="outstandingAmount"
          errors={errors}
          currency={selectedCurrency}
          label="Outstanding Amount"
          required
          setValue={setValue}
        />
        <CurrencyInput
          control={control}
          name="issuerBondAmount"
          errors={errors}
          currency={selectedCurrency}
          label="Issuers Bond Amount"
          defaultValue="0"
          setValue={setValue}
        />
        <Tooltip
          title={
            isConversionDisabled
              ? 'Conversion rate is already set for this currency'
              : ''
          }
        >
          <span>
            <NumberInput
              label="Conversion"
              control={control}
              errors={errors}
              disabled={isConversionDisabled}
              name="conversionRate"
              required
              setValue={setValue}
            />
          </span>
        </Tooltip>
        <Button onClick={onAddClick} variant="outlined">
          Add
        </Button>
      </S.AddSectionWrapper>
      <Table.Root
        columns={tableColumns}
        data={tableData}
        options={tableOptions}
        actions={tableActions}
      />
    </div>
  );
};

export default BondholdersMeetingAddISINsForm;
