import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SelectOption } from 'react-select-material-ui';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table } from '@nordictrustee/nt-ui-library';
import Button from 'components/Button';
import MultiSelect from 'components/Inputs/MultiSelect';
import TextInput from 'components/Inputs/TextInput';
import { FIRST_COLUMN_NO_PADDING } from 'components/Table/const';
import { getChipList } from 'modules/BondholdersMeeting/screens/Meetings/utils';
import { VoteIssueAddFormFields } from '../types';
import * as S from './styles';
import { getOneMoreThanTheHighestNumberOrIndex } from './getOneMoreThanTheHighestNumberOrIndex';

interface Props {
  tableData: VoteIssueAddFormFields[];
  setTableData: Dispatch<SetStateAction<VoteIssueAddFormFields[]>>;
  isinOptions: SelectOption[];
}

const MattersToBeDecidedStep = ({
  tableData,
  setTableData,
  isinOptions,
}: Props) => {
  const tableColumns: Table.Column<VoteIssueAddFormFields>[] = useMemo(
    () => [
      {
        title: 'Matter to be decided',
        field: 'matter',
        type: 'string',
        width: '33%',
        ...FIRST_COLUMN_NO_PADDING,
      },
      {
        title: 'ISINs',
        field: 'bondIsins',
        render: ({ bondIsins }) => getChipList(bondIsins, 3),
      },
    ],
    []
  );

  const tableOptions: Table.Options<VoteIssueAddFormFields> = useMemo(
    () => ({
      paging: false,
      minBodyHeight: '20vh',
      maxBodyHeight: '20vh',
      toolbar: false,
    }),
    []
  );

  const onDeleteClick = useCallback(
    ({ id }: VoteIssueAddFormFields) => {
      setTableData((prev) => prev.filter((row) => row.id !== id));
    },
    [setTableData]
  );

  const tableActions = useMemo(
    () => [
      (rowData: VoteIssueAddFormFields) => ({
        icon: () => <DeleteIcon color="action" />,
        tooltip: 'Delete',
        onClick: () => onDeleteClick(rowData),
      }),
    ],
    [onDeleteClick]
  );
  const newMatterForm = useForm();
  const onAddClick = useCallback(async () => {
    const valid = await newMatterForm.trigger();
    if (valid) {
      const { matter, bondIDs } = newMatterForm.getValues();
      setTableData((prev) => [
        ...prev,
        {
          id: getOneMoreThanTheHighestNumberOrIndex(prev), // This should be removed before submitting
          matter,
          bondIDs,
          bondIsins: isinOptions
            .filter(({ value }) => bondIDs.includes(value))
            .map(({ label }) => label),
        },
      ]);
      newMatterForm.setValue('matter', null);
      newMatterForm.setValue('bondIDs', []);
    }
  }, [isinOptions, newMatterForm, setTableData]);

  return (
    <div data-testid="matters-to-be-decided-fields">
      <S.AddVoteIssuesSectionWrapper>
        <TextInput
          name="matter"
          label="Matter to be decided"
          control={newMatterForm.control}
          errors={newMatterForm.formState.errors}
          required
          autoFocus
        />
        <MultiSelect
          name="bondIDs"
          label="ISIN"
          options={isinOptions}
          control={newMatterForm.control}
          errors={newMatterForm.formState.errors}
          isClearable
          required
        />
        <Button onClick={onAddClick} variant="outlined">
          Add
        </Button>
      </S.AddVoteIssuesSectionWrapper>
      <Table.Root
        columns={tableColumns}
        data={tableData}
        options={tableOptions}
        actions={tableActions}
      />
    </div>
  );
};

export default MattersToBeDecidedStep;
