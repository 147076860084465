import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { registerRequiredRule } from '../rules';
import { ErrorLabel } from '../styles';

interface Props {
  required?: boolean;
  name: string;
  label?: string;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  children: JSX.Element | JSX.Element[];
  row?: boolean;
}

const GroupRadioInput = (props: Props) => {
  return (
    <div>
      <FormControl required={props.required}>
        <FormLabel>{props.label}</FormLabel>
        <ErrorLabel data-testid="error-label">
          {props.errors && helperText(props.name, props.errors)}
        </ErrorLabel>
        <Controller
          defaultValue=""
          name={props.name}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              value={value}
              row={props.row}
              onChange={(val) => {
                onChange(val);
              }}
            >
              {props.children}
            </RadioGroup>
          )}
          control={props.control}
          rules={registerRequiredRule(props.required)}
        />
      </FormControl>
    </div>
  );
};

export default GroupRadioInput;
