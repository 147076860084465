import { DictionaryOption } from 'utils/types/Dictionary.types';
import { useAxios } from '../useAxios';

export const useGetMeetingResultOptions = () => {
  const [
    { data: meetingResultOptions, loading: isLoadingMeetingResultOptions },
    getMeetingResultOptions,
  ] = useAxios<DictionaryOption[]>(
    {
      url: `/dictionaries/meeting-result-type-options`,
    },
    { manual: true },
  );
  return {
    meetingResultOptions,
    isLoadingMeetingResultOptions,
    getMeetingResultOptions,
  };
};
