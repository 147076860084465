import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SetValueConfig } from 'react-hook-form/dist/types/form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table } from '@nordictrustee/nt-ui-library';
import { Context } from '../../Context';
import { VoteIssueFormFieldMap, VoteIssueFormFieldsRow } from '../types';
import * as api from './api';
import { columns } from './columns';
import { canDeleteVoteIssue } from './utils';

interface Props {
  disabled?: boolean;
  voteIssuesData?: VoteIssueFormFieldsRow[];
  setVoteIssuesData: Dispatch<
    SetStateAction<VoteIssueFormFieldsRow[] | undefined>
  >;
  getCurrentFormValues: () => VoteIssueFormFieldsRow[];
  form: UseFormReturn<VoteIssueFormFieldMap>;
}

export const EditMattersForm = ({
  disabled,
  voteIssuesData,
  setVoteIssuesData,
  getCurrentFormValues,
  form,
}: Props) => {
  const { isinOptions, isLoadingMeetingIsins, bondholdersMeetingId } =
    useContext(Context);

  const {
    control,
    formState: { errors },
    setValue: setFormValue,
  } = form;

  const { validationData, isLoadingValidationData, postVoteIssueValidation } =
    api.usePostVoteIssueValidations(bondholdersMeetingId);

  useEffect(() => {
    postVoteIssueValidation();
  }, [postVoteIssueValidation]);

  const onDeleteClick = useCallback(
    ({ index }: VoteIssueFormFieldsRow) => {
      setVoteIssuesData(() =>
        getCurrentFormValues().filter((row) => row.index !== index)
      );
    },
    [getCurrentFormValues, setVoteIssuesData]
  );

  const tableActions = useMemo(
    () => [
      (rowData: VoteIssueFormFieldsRow) => {
        const canDelete = canDeleteVoteIssue(validationData, rowData.id);
        const isDisabled = disabled || !canDelete;
        return {
          icon: () => <DeleteIcon color={isDisabled ? 'disabled' : 'action'} />,
          tooltip: canDelete
            ? ''
            : 'Unassign this Matter to be decided from the Vote to remove it',
          onClick: () => onDeleteClick(rowData),
          disabled: isDisabled,
        };
      },
    ],
    [disabled, onDeleteClick, validationData]
  );

  if (isLoadingMeetingIsins) {
    return null;
  }
  const setValue = (
    name?: string,
    value?: string,
    options?: SetValueConfig
  ) => {
    // TODO: resolve types in he args of setFormValue
    setFormValue(name as never, value as never, options);
  };
  const tableColumns = columns({
    control,
    errors,
    disabled,
    setValue,
    isinOptions,
    validationData,
  });
  return (
    <Table.Root
      data={(voteIssuesData || []) as any[]}
      columns={tableColumns}
      actions={tableActions}
      options={{
        paging: false,
        tableLayout: 'fixed',
        toolbar: false,
      }}
      isLoading={isLoadingValidationData}
    />
  );
};
