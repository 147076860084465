import { MeetingStatus } from 'utils/types/MeetingStatus';
import { MeetingType } from 'utils/types/MeetingType';
import { VoteIssueResult } from 'utils/types/VoteIssueResult';
import { VoteIssue } from '../types';

export const checkIfEveryVoteIssueHasValidResult = (data: VoteIssue[]) => {
  return data
    .filter(({ result }) => result !== VoteIssueResult.NotApproved) //not approved are not calculated
    .every((d) => d.result !== null);
};

export const checkIfSomeVoteIssuesHaveNotApprovedResult = (data: VoteIssue[]) =>
  data?.some(({ result }) => result === VoteIssueResult.NotApproved);

export const generateCloseButtonTooltipMessage = (
  status: MeetingStatus | undefined,
  votingDeadline: string | null | undefined,
  voteIssues: VoteIssue[],
  meetingType: MeetingType | undefined
) => {
  if (!votingDeadline) {
    return 'Voting deadline is missing';
  }
  if (
    status !== MeetingStatus.Ended &&
    status !== MeetingStatus.Closed &&
    meetingType === MeetingType.BondholderMeeting
  ) {
    return 'The meeting must first end before it can be closed';
  }

  const allVotesHasValidResult =
    voteIssues && checkIfEveryVoteIssueHasValidResult(voteIssues);
  if (!allVotesHasValidResult) {
    return 'All the Matters to be decided under this meeting have to have the results defined';
  }

  return '';
};

export const getStatusChipText = (
  status?: MeetingStatus,
  votingDeadline?: string,
  statusChangedAt?: string,
  statusChangedBy?: string,
  isInternalEmployeeRole?: boolean
) => {
  switch (status) {
    case MeetingStatus.Active: {
      return status;
    }
    case MeetingStatus.Ended: {
      return `${status} ${votingDeadline}`;
    }
    case MeetingStatus.Closed: {
      if (isInternalEmployeeRole) {
        return `${status} ${statusChangedAt} by ${
          statusChangedBy ? statusChangedBy : '?'
        }`;
      } else {
        return `${status} ${statusChangedAt}`;
      }
    }
    default: {
      return `${status} ${statusChangedAt}`;
    }
  }
};
