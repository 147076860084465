import { useAxios } from 'utils/hooks/useAxios';
import { BondForIssuer } from './types';

export const useGetBondsForIssuer = (issuerId: string) => {
  const [
    {
      data: bondsForIssuer = [],
      loading: isLoadingBondsForIssuer,
      response: bondsForIssuerResponse,
    },
    getBondsForIssuer,
  ] = useAxios<BondForIssuer[]>(
    {
      url: `/issuers/${issuerId}/bonds`,
    },
    { manual: true },
  );
  return {
    bondsForIssuer,
    isLoadingBondsForIssuer,
    bondsForIssuerResponse,
    getBondsForIssuer,
  };
};
