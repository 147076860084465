import BigNumber from 'bignumber.js';
import { currencyFormat } from 'utils/constants';
import { VoteDetails, VoteOption, VoteStatus } from '../types';

export const fractionToPercent = (value: BigNumber, sum: BigNumber) => {
  const percentage = value.div(sum).multipliedBy(100).toNumber().toFixed(2);
  return percentage !== 'NaN' ? percentage : '-';
};

export const getFormattedNumberWithCurrency = (
  value: number,
  currency: string,
) => {
  return `${new BigNumber(value.toFixed(2)).toFormat(
    currencyFormat,
  )} ${currency}`;
};

export const getTotalOfVotes = (
  votesDetails: VoteDetails[],
  filterApproved?: boolean,
  filterInFavour?: boolean,
) => {
  return votesDetails
    .filter((v) => {
      const isApproved = v.approvedBy != null && v.approvedBy !== '';
      const isInFavour = v.result === VoteOption.Yes;
      const isDeleted = v.status === VoteStatus.Deleted;
      const testInFavour = () => (filterInFavour ? isInFavour : !isDeleted);
      const testApproved = () => (filterApproved ? isApproved : testInFavour());
      const testApprovedAndInFavour = () => isApproved && isInFavour;
      return filterApproved && filterInFavour
        ? testApprovedAndInFavour()
        : testApproved();
    })
    .reduce((sum, current) => sum + current.weightedAmount, 0);
};

export const getNumberUpToAMaxNumber = (number: number, max: number) => {
  return number > max ? max : number;
};
